import React, {useEffect, useReducer, useCallback, useRef} from 'react';
import {useAppSelector} from '../../utils/hooks';
import {toggleModal} from '../../store/modal/actions';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../../store/spinner/types';
import {fetchReports} from '../../store/asset/actions';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import constant from '../../config/constant';
import {fetchSiteTokens} from '../../store/site/actions';
import {
  getFormMap, 
  initialFieldsGenerator,
  FormMapT, 
  RowFields, 
  FilteringStateColumn,
  SortingStateColumn,
  siteColumns,
  subPages,
} from './ReportsFields';
import {businessProfileObject} from '@aglive/frontend-core';
import { BusinessMisc } from '@aglive/data-model';
import {sex, payloadType as reportPayloadType} from './ReportsFields';
import { MultipleSelectOptionsType } from '../../presentation/MultupleSelect';
import { Column, Sorting, Table, TableColumnVisibility} from '@devexpress/dx-react-grid';
import { OuterFilter } from '@aglive/data-model/dist/misc/report';

const INITIAL_DATA_STATE = {
  rows: [] as Array<RowFields['ceres'] | RowFields['management']>,
  totalData: 0,
  report: undefined as undefined | number, //index of select
  sites: [] as Array<string>,
  currentPage: 0,
  pageSize: 10,
  subPage: null as SubPageType,
  tableGenerated: false, // keep table showing if search result has 0 row
  updateOptions: false, // decide if update multiselct options for inner filter
  sorting: [] as Sorting[], // e.g.: [{ columnName: 'rfid', direction: 'asc' }]
  fieldConditions: {} as {[key: string]: any},
  outerFilter: {} as {[key: string]: any},
  multiSelectOptions: {} as {[key: string]: Array<{label: string, value: string}>},
  selectedFilters: {} as {[key: string]: Array<MultipleSelectOptionsType>},
  columns: [] as Array<Column> | Array<Column & {type: string}>,
  columnExtensions: [] as Table.ColumnExtension[],
  columnOrder: [] as string[],
  hiddenColumns: [] as string[],
  columnFilter: [] as Array<FilteringStateColumn>, //filteringStateColumnExtensions
  columnSort: [] as Array<SortingStateColumn>, //sortingStateColumnExtensions
  columnVisibility: [] as TableColumnVisibility.ColumnExtension[], // disable toggling month and average daily gain
  errorState: {
    dates: {
      status: true,
      message: '',
    },
  } as {[key: string]: {status: boolean, message: string}},
};
type PageType = 'other' | 'ceres' | 'management';
export type SubPageType = 'management' | 'weightHistory' | 'weightGain' | 'averageDailyGain' | 'movement' | 'deadSoldExported' | 'treatment' | 'conception';
type InitialSelectedFilters = typeof INITIAL_DATA_STATE['selectedFilters']
type Action =
  | {type: 'get/data', rows: Array<RowFields['ceres'] | RowFields['management']>}
  | {type: 'change/page', currentPage: number}
  | {type: 'change/pageSize', pageSize: number}
  | {type: 'change/totalData', totalData: number}
  | {type: 'change/hiddenColumns', hiddenColumns: Array<string>}
  | {type: 'set/error', errorState: typeof INITIAL_DATA_STATE['errorState']}
  | {type: 'update/form', report?: number}
  | {type: 'update/fieldConditions', fieldConditions: {[key:string]: any}}
  | {type: 'update/multiSelectOptions', multiSelectOptions: {[key: string]: Array<{label: string, value: string}>}}
  | {type: 'change/selectedFilters', selectedFilters: InitialSelectedFilters}
  | {type: 'change/subPage', subPage: SubPageType}
  | {type: 'change/tableGenerated', tableGenerated: boolean}
  | {type: 'change/updateOptions', updateOptions: boolean}
  | {type: 'change/sorting', sorting: Sorting[]}
  | {type: 'update/sites', sites: Array<string>}
  | {type: 'update/columns', columns: Array<Column> | Array<Column & {type: string}>}
  | {type: 'update/columnExtensions', columnExtensions: Table.ColumnExtension[]}
  | {type: 'change/columnOrder', columnOrder: Array<string>}
  | {type: 'update/columnFilter', columnFilter: Array<FilteringStateColumn>}
  | {type: 'update/columnSort', columnSort: Array<SortingStateColumn>}
  | {type: 'update/columnVisibility', columnVisibility: TableColumnVisibility.ColumnExtension[]}
  | {type: 'update/outerFilter', outerFilter: {[key:string]: any}}

const reducer = (
  prevState: typeof INITIAL_DATA_STATE,
  action: Action,
): typeof INITIAL_DATA_STATE => {
  const {type, ...actionData} = action;
  switch (action.type) {
    default:
      return {...prevState, ...actionData};
  }
};
export const useReport = (page: PageType, subType?: SubPageType) => {
  const dispatch = useDispatch();
  const sexList = sex.map((sex) => ({label: sex, value: sex}))
  const businessProfile = businessProfileObject(useAppSelector(
    (state) => state.user.businessProfileData,
  ) as BusinessMisc.AnimalBusinessInterface | BusinessMisc.PlantBusinessInterface);
  const businessReports = useAppSelector((state) => state.user.reports);
  const localizedPIC = businessProfile.isAustralia()
      ? constant.LOCALISATION_PIC.AU_LOCATION_PIC
      : constant.LOCALISATION_PIC.CA_LOCATION_PID;
  const formMap = useRef<FormMapT[]>(getFormMap(page, businessProfile.isPlants(), businessReports, businessProfile.isAustralia()));      
  const currentTimer = useRef<undefined | ReturnType<typeof setTimeout>>();
  const getFormMapIndex = React.useMemo(() => {
    let givenIdx: undefined | number = undefined;
    formMap?.current.forEach((fm, idx) => {
      if (fm.key.toLowerCase() === subType?.toLowerCase().replace(/\s/g, '_')) {
        givenIdx = idx;
      }
    });
    return givenIdx;
  }, [formMap, subType]);
  let additionalOverride = {};
  if (subType) {
    additionalOverride['subPage'] = subType;
    additionalOverride['report'] = getFormMapIndex;
  }
  const [state, localDispatch] = useReducer(reducer, {...INITIAL_DATA_STATE, ...additionalOverride});
  const locationSet = useAppSelector((state) => state.location.location);
  const siteMaps = useAppSelector((state) => state.site)?.sort((token, token2) => {
    return token.details.siteName > token2.details.siteName ? 1 : -1
  });
  const locationPicAddr = locationSet.map((loc) => ({
    label: `${loc.locationNickname} (${loc.PICAddress})`,
    value: loc.PICAddress,
  }));
  const {
    initialFieldConditions, 
    initialOuterFilter, 
    initialColumnExtensions, 
    initialColumns, 
    initialSelectedFilters, 
    defaultRowFields,
    disabledFilterColumnExtensions,
    disabledSortingColumnExtensions,
    payloadType
  } = React.useMemo(() => 
  initialFieldsGenerator(businessReports, businessProfile.isAustralia()), [businessReports]);

  const subPagesList = React.useMemo(() => {
    let tmpList = [...subPages];
    if (businessReports?.length) {
      const newSubPage = [];
      businessReports.forEach((rp) => {
        newSubPage.push(rp.type);
      });
      tmpList = businessProfile.isAustralia() ? [...tmpList, ...newSubPage] : newSubPage;
    }
    return tmpList;
  }, [businessReports, businessProfile]);

  const allowGenerate = React.useMemo(() => {
    let allow = true;
    const currRep = formMap?.current[state.report ?? 0]?.outerFilterFields ?? [];
    currRep.forEach((outerFilter) => {
      if (outerFilter.required) {
        const val = state.outerFilter[outerFilter.key];
        if (!val || (Array.isArray(val) && val.length === 0)) {
          allow = false;
        }
      }
    });
    return allow;
  }, [state.outerFilter, state.report]);

  const updateForm = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let updateVal: {[key: string]: number | string} = {[e.target.name]: e.target.value};
    localDispatch({type: 'update/form', ...updateVal});
  }, [state.report]);
  const changePage = useCallback((pageNum: number) => {
    localDispatch({type: 'change/page', currentPage: pageNum})
  }, []);
  const changePageSize = useCallback((size: number) => {
    localDispatch({type: 'change/pageSize', pageSize: size})
  }, []);
  const changeColumnOrder = useCallback((newOrder: Array<string>) => {
    localDispatch({type: 'change/columnOrder', columnOrder: newOrder})
  }, [state.columnOrder]);
  const changeHiddenColumn = useCallback((hidden: Array<string>) => {
    localDispatch({type: 'change/hiddenColumns', hiddenColumns: hidden})
  }, []);
  const resetFieldConditionsAndFilters = useCallback(() => {
    localDispatch({type: 'update/fieldConditions', 
      fieldConditions: state.subPage ? initialFieldConditions[page][state.subPage] ?? {} : initialFieldConditions[page] ?? {}
    })
    if (state.subPage && state.subPage === 'movement') {
      // do not reset selectedFilters in movement page
      // to ensure outer and inner site_moved_to can change synchronously
      localDispatch({
        type: 'change/selectedFilters', 
        selectedFilters: {...initialSelectedFilters[page][state.subPage], site_moved_to: state.selectedFilters.site_moved_to}
      })
    } else {
      localDispatch({
        type: 'change/selectedFilters', 
        selectedFilters: state.subPage ? initialSelectedFilters[page][state.subPage] ?? {} : initialSelectedFilters[page] ?? {}
      })
    }
    localDispatch({type: 'change/page', currentPage: 0})
  }, [state.fieldConditions, state.selectedFilters, state.subPage]);
  const updateFieldConditions = useCallback((item: string, value?: number | string | MultipleSelectOptionsType[], type?: string) => {
    localDispatch({type: 'update/fieldConditions', fieldConditions: {...state.fieldConditions, [item]:{...state.fieldConditions[item], filterValue: value, type: type}}})
    localDispatch({type: 'change/page', currentPage: 0})
  }, [state.fieldConditions]);
  const updateOuterFilter = useCallback((item: string, payload: Date | number | string | MultipleSelectOptionsType[]) => {
    localDispatch({type: 'update/outerFilter', outerFilter: {...state.outerFilter, [item]:payload}})
  }, [state.outerFilter]);
  const changeSelectedFilters = useCallback((selectedOptions: MultipleSelectOptionsType[], columnName: string) => {
    localDispatch({type: 'change/selectedFilters', selectedFilters: {...state.selectedFilters, [columnName]: selectedOptions}}); 
  }, [state.selectedFilters]);
  const updateOuterInnerFilters = useCallback((item: string, payload: MultipleSelectOptionsType[]) => {
    updateOuterFilter(item, payload)
    changeSelectedFilters(payload, item)
  }, [state.outerFilter, state.selectedFilters]);
  const changeSubPage = useCallback((subPage: SubPageType) => {
    localDispatch({type: 'change/subPage', subPage: subPage}); 
  }, [state.subPage]);
  const changeTableGenerated = useCallback((state: boolean) => {
    localDispatch({type: 'change/tableGenerated', tableGenerated: state}); 
  }, []);
  const changeSorting = useCallback((sorting: Sorting[]) => {
    localDispatch({type: 'change/sorting', sorting: sorting}); 
  }, [state.sorting]);
  const setInnerFilterDateError = useCallback((errorStatus: boolean, errorMessage: string, fieldName: string) => {
    localDispatch({type: 'set/error', errorState: 
      {
        ...state.errorState,
        [fieldName]: {
          status: errorStatus,
          message: errorMessage,
        }
      }
    }); 
  }, [state.errorState]);
  const changeColumnStates = useCallback((
    initiate: boolean, 
    columns?: Column[], 
    columnOrder?: string[], 
    columnExtensions?: Table.ColumnExtension[], 
    columnFilter?: Array<FilteringStateColumn>, 
    columnSort?: Array<SortingStateColumn>,
    columnVisibility?: TableColumnVisibility.ColumnExtension[]) => {
      if (initiate) {
        localDispatch({
          type: 'update/columns', 
          columns: state.subPage ? initialColumns[page][state.subPage] ?? [] : initialColumns[page] ?? []
        })
        localDispatch({
          type: 'change/columnOrder', 
          columnOrder: Object.keys(state.subPage ? defaultRowFields[page][state.subPage] ?? [] : defaultRowFields[page] ?? [])
        })
        localDispatch({
          type: 'update/columnExtensions', 
          columnExtensions: state.subPage ? initialColumnExtensions[page][state.subPage] ?? [] : initialColumnExtensions[page] ?? []
        })
        localDispatch({
          type: 'update/columnFilter', 
          columnFilter: state.subPage ? disabledFilterColumnExtensions[page][state.subPage] ?? [] : disabledFilterColumnExtensions[page] ?? []
        })
        localDispatch({
          type: 'update/columnSort', 
          columnSort: state.subPage ? disabledSortingColumnExtensions[page][state.subPage] ?? [] : disabledSortingColumnExtensions[page] ?? []
        })
        localDispatch({type: 'update/columnVisibility', columnVisibility: []})
      } else {
        localDispatch({type: 'update/columns', columns: columns})
        localDispatch({type: 'change/columnOrder', columnOrder: columnOrder})
        localDispatch({type: 'update/columnExtensions', columnExtensions: columnExtensions})
        localDispatch({type: 'update/columnSort', columnSort: columnSort})
        localDispatch({type: 'update/columnFilter', columnFilter: columnFilter})
        localDispatch({type: 'update/columnVisibility', columnVisibility: columnVisibility})
      }
  }, [state.subPage, state.columns, state.columnOrder, state.columnExtensions, state.columnSort, state.columnFilter, state.columnVisibility])

  const updateMultiSelectOptions = useCallback((optionsList: {[key: string]: Array<string>}) => {
    // update options for inner multiselect filters
    // based on `options` from response or `site` from redux 
    let newMultiSelectOptions = {}
    state.columns?.forEach((col) => {
      if (col.type === 'multiSelect') {
      const options = siteColumns.includes(col.name) ? state.sites : optionsList[col.name]
      newMultiSelectOptions[col.name] = options?.map((data) => (data.toString().length ? {label: data, value: data} : {label: 'Null/Empty', value: data})) ?? []
      }
    })
    localDispatch({type: 'update/multiSelectOptions', multiSelectOptions: newMultiSelectOptions})
  }, [state.columns])
  
  const handleSubPageChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    changeSubPage(subPagesList[e.target.value])
    updateForm(e)
    changeTableGenerated(false)
  }, [state.report]);

  const handleMultiSelectChange = useCallback((columnName: string, selectedData: MultipleSelectOptionsType[]) => {
    changeSelectedFilters(selectedData, columnName)
    if (!selectedData?.length || (selectedData?.length > 1 && selectedData?.length === state.multiSelectOptions[columnName]?.length)) {
      // default to all if unselect all or select all (if only one option, select all default to this one option)
      updateFieldConditions(columnName)
    } else {
      updateFieldConditions(columnName, selectedData?.map((data) => typeof data === 'string' ? data : data.value))
    }
  }, [state.selectedFilters, state.fieldConditions]);

  const handleTextfieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, column: Column & {type: string}) => {
    clearTimeout(currentTimer?.current)
    currentTimer.current = setTimeout(() => {
      if(!e.target.value) {
        // if clear filter value should show all records
        updateFieldConditions(column.name) 
      } else {
        switch (column.type) {
          case 'text':
            updateFieldConditions(column.name, e.target.value, 'partial')
            break;
          case 'number': 
            updateFieldConditions(column.name, Number(e.target.value)) 
            break
        }
      }
    }, 2000)
  }, [state.fieldConditions])

  const handleDateRangeChange = useCallback((columnName: string, date: Array<Date>) => {
    if (date === null || !moment(date[0]).isValid() || !moment(date[1]).isValid()) {
      // remove filtering if date is cleared or invalid
      updateFieldConditions(columnName)
      if (date !== null && (!moment(date[0]).isValid() || !moment(date[1]).isValid())) {
        setInnerFilterDateError(false, 'Invalid date', columnName)
      }
    } else {
      updateFieldConditions(columnName, [moment(date[0]).format('yyyy-MM-DD'), moment(date[1]).format('yyyy-MM-DD')])
      setInnerFilterDateError(true, '', columnName)
    }
  }, [state.fieldConditions])

  const handleClickGenerate = useCallback(() => {
    changeTableGenerated(true)
    localDispatch({type: 'change/updateOptions', updateOptions: true})
    resetFieldConditionsAndFilters()
    localDispatch({type: 'change/hiddenColumns', hiddenColumns: []})
  }, [state.fieldConditions, state.selectedFilters, state.columnOrder, state.columnVisibility])

  const handleHiddenColumnsChange = useCallback(() => {
    // set dispaly false/true when hidden/show columns
    // dispaly for 'sex' should always be false
    let newConditions = JSON.parse(JSON.stringify(state.fieldConditions))
    for (let [key, value] of Object.entries(newConditions))
      if(state.hiddenColumns.includes(key)){
        value['display'] = false
      } else if (!state.hiddenColumns.includes(key) && key !== 'sex') { 
        value['display'] = true
      }
    localDispatch({type: 'update/fieldConditions', fieldConditions: newConditions})
  }, [state.hiddenColumns])

  const resetSubPageStates = useCallback(() => {
    localDispatch({
      type: 'update/outerFilter', 
      outerFilter: state.subPage ? initialOuterFilter[page][state.subPage] ?? {}: initialOuterFilter[page] ?? {}
    }) 
    localDispatch({
      type: 'update/fieldConditions', 
      fieldConditions: state.subPage ? initialFieldConditions[page][state.subPage] ?? {}: initialFieldConditions[page] ?? {}
    })
    localDispatch({
      type: 'change/selectedFilters', 
      selectedFilters: state.subPage ? initialSelectedFilters[page][state.subPage] ?? {}: initialSelectedFilters[page] ?? {}
    })
    localDispatch({type: 'change/page', currentPage: 0})
    localDispatch({type: 'change/sorting', sorting: INITIAL_DATA_STATE['sorting']})
  }, [state.subPage, state.outerFilter, state.fieldConditions, state.selectedFilters, state.currentPage, state.sorting])

  const formatRows = (res, columnOrder: Array<string>, hiddenColumns: Array<string>, isArray?: boolean, hideCols?: boolean) => {
    const array = [];
    const obj = {};
    //lowercase all keys
    res = Object.keys(res)
    .reduce((destination, key) => {
      destination[key.toLowerCase().replace(/\W+/g, '_')] = res[key];
      return destination;
    }, {});
    columnOrder.forEach(row => {
      if (hideCols && hiddenColumns.includes(row)) return;
      let rowData = res[row];
      // if (page === 'management') {
      //   rowData = res[row];
      // } else {
        if (row === 'temperature') {
          rowData += '°C';
        } else if (row === 'date' || row.includes('time')) {
          rowData = moment(rowData).format(isArray ? 'yyyy-MM-DD hh:mm:ss' : 'MMMM Do yyyy, h:mm:ss a');
        } else if (row === 'rfid' && !rowData) {
          rowData = res.externalIds?.find(eid => eid.rfid);
          rowData = rowData ? rowData['rfid'] : '';
        }
      //}

      if (isArray) {
        array.push(rowData);
      } else {
        obj[row] = rowData;
      }
    });
    return isArray ? array : obj;
  };

  const downloadCSV = (response: {headers: Array<string>; body: Array<Array<string>> | string}, reportName: string) => {
    let responseData = '';
    if (typeof response.body !== 'string') {
      // for Ceres Tag Report, body is JSON format
      responseData = response['headers'].join(',') + '\n' +
                     response['body'].map((row) => row.join(',')).join('\n');
    } else {
      responseData = response['body'];
    }
    let csvData = new Blob([responseData], {
      type: 'text/csv;charset=utf-8;',
    });

      //In FF link must be added to DOM to be clicked
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', reportName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

  }

  const fetchReport = (opt: {download?: boolean, requestFirstPage? : boolean, sorting?: boolean } = {download: false, requestFirstPage: false, sorting: false}) => async () => {
    dispatch({type: SPINNER_TOGGLE_ON});

    let data = {};
    let params = {};
    let fileName = '';
    let postMethod = 'POST' as 'POST' | 'GET';
    const currentReport = formMap?.current[state.report ?? 0];

    switch (page) {
      case "ceres":
        /*data = {
          start_date: moment(state.outerFilter.start_date).format('YYYY-MM-DD'),
          end_date: moment(state.outerFilter.end_date).format('YYYY-MM-DD'),
          pic: state.outerFilter.locations?.map((loc) => loc.value)
        };*/
        data = {
          // get data for first page when opt.requestFirstPage is true
          pages: opt.download ? null : opt.requestFirstPage ? 1 : state.currentPage + 1, 
          itemPerPage: opt.download ? null : state.pageSize,
          getFilters: true,  //  getFilters should always be true, but decide if update options by `state.updateOptions`
          fieldCondition: Object.values({
            ...state.fieldConditions, 
            time: {...state.fieldConditions['time'], filterValue: [moment(state.outerFilter.start_date).format('YYYY-MM-DD'), moment(state.outerFilter.end_date).format('YYYY-MM-DD')]},
            pic: {...state.fieldConditions['pic'], filterValue: state.outerFilter.locations?.map((loc) => loc.value)}
          }),
          output: opt.download ? 'csv' : 'grid',
          type: "addMovement"
        };
        fileName = state.outerFilter.locations.length > 3 ? `Ceres Tag Report ${state.outerFilter.start_date} to ${state.outerFilter.end_date} (${state.outerFilter.locations.length.toString()} locations)` :
         `Ceres Tag Report ${state.outerFilter.start_date} to ${state.outerFilter.end_date} (${state.outerFilter.locations.map((obj: {label :string, value: string}) => obj.value).join(', ')})`;
        break;
      case "management":
        let fieldConditions = {} as {[key: string]: any}

        switch (state.subPage) {
          case "management": 
            // add outer filter 'Sex' to inner filter fieldCondition in payload
            // use initial fieldConditions if sex changed and click `Generate`
            // if state.updateOptions is true, need to initiate fieldConditions, add sex
            fieldConditions = state.updateOptions ? {...initialFieldConditions[page][state.subPage], sex: {...initialFieldConditions[page][state.subPage].sex, filterValue: state.outerFilter.selectedSex?.map((obj: {label :string, value: string}) => obj.value)}}
                                                : {...state.fieldConditions, sex: {...state.fieldConditions.sex, filterValue: state.outerFilter.selectedSex?.map((obj: {label :string, value: string}) => obj.value)}};
            break;
          case "movement": 
            // add outer filter 'Site Moved To' to inner filter fieldCondition in payload
            // if state.updateOptions is true, need to initiate fieldConditions columns, but except site_moved_to to keep inner sync to outer site_moved_to
            fieldConditions = state.selectedFilters.site_moved_to.length 
            ? state.updateOptions 
              ? {...initialFieldConditions[page][state.subPage], site_moved_to: {...state.fieldConditions.site_moved_to, filterValue: state.selectedFilters.site_moved_to.map((obj: {label :string, value: string}) => obj.value)}}
              : {...state.fieldConditions, site_moved_to: {...state.fieldConditions.site_moved_to, filterValue: state.selectedFilters.site_moved_to.map((obj: {label :string, value: string}) => obj.value)}}
            : state.fieldConditions;
            break;
          case "treatment":
            // add outer filter treatment date to inner filter
            const start_date = moment(state.outerFilter.start_date).format('YYYY-MM-DD');
            const end_date = moment(state.outerFilter.end_date).format('YYYY-MM-DD');
            fieldConditions = state.updateOptions
              ? {
                  ...initialFieldConditions[page][state.subPage],
                  treatment_date: {
                    ...initialFieldConditions[page][state.subPage].treatment_date,
                    filterValue: [
                      start_date,
                      end_date,
                    ]
                  },
                }
              : {
                  ...state.fieldConditions,
                  treatment_date: {
                    ...state.fieldConditions.treatment_date,
                    filterValue: [
                      start_date,
                      end_date,
                    ]
                  },
                };
            break;
          default:
            fieldConditions = state.fieldConditions;
            if (businessReports?.length) {
              const currentPluginReport = businessReports.filter((br) => br.type === state.subPage)[0];
              if (currentPluginReport && currentPluginReport.outerFilters?.length) {
                const outerFilterValues: {[key: string]: OuterFilter & {value: string | Array<string>}} = {};
                //rearrange to object
                currentPluginReport.outerFilters.forEach((otf) => {
                  outerFilterValues[otf.key] = {...otf, value: state.outerFilter[otf.key]};
                });
                for (const [key, val] of Object.entries(fieldConditions)) {
                  if (val['outerFilter']) {
                    if (Array.isArray(val['outerFilter'])) {
                      val['filterValue'] = val['outerFilter'].map((fcof, idx) => {
                        const newDate = new Date(moment(outerFilterValues[fcof].value).format('YYYY-MM-DD')).toISOString().split('T')[0];
                        return val.dateField && idx === 1 ? `${moment(newDate).format('YYYY-MM-DD')}T23:59:59` : newDate;
                      });
                    } else if (val['outerFilter'] === 'locations') {
                      val['filterValue'] = state.outerFilter.locations.map((loc) => {
                        return val.field === 'pic_name' ? loc.label.replace(new RegExp(` \\(${loc.value}\\)`), '') : loc.value;
                      });
                    } else {
                      val['filterValue'] = val.dateField ? `${moment(outerFilterValues[val['outerFilter']].value).format('YYYY-MM-DD')}T23:59:59` : outerFilterValues[val['outerFilter']].value;
                    }
                    delete val['outerFilter'];
                    fieldConditions[key] = val;
                  }
                }
              }
            }
            break;
        }
        
        const outerFilters = {
          management: {
            date: moment(state.outerFilter.projectedWeightDate).format('YYYY-MM-DD'),
            averageGain: state.outerFilter.averageDailyGain,
          },
          weightHistory: {
            months: Number(state.outerFilter.numberOfMonths),
          },
          weightGain: {},
          averageDailyGain: {
            dateRange: {
              start: moment(state.outerFilter.start_date).format('YYYY-MM-DD'),
              end: moment(state.outerFilter.end_date).format('YYYY-MM-DD'),
            }
          },
          movement: {},
          deadSoldExported: {},
          treatment: {},
        }

        // payload for grid table, retrive current page
        // payload for download csv, not passing `itemPerPage` and `pages` params
        data = {
          // get data for first page when opt.requestFirstPage is true
          pages: opt.download ? null : opt.requestFirstPage ? 1 : state.currentPage + 1, 
          itemPerPage: opt.download ? null : state.pageSize,
          getFilters: true,  //  getFilters should always be true, but decide if update options by `state.updateOptions`
          fieldCondition: Object.values(fieldConditions), // for inner filters
          output: opt.download ? 'csv' : 'grid',
          filters: outerFilters[state.subPage], // for outer filters
          sort: opt.sorting && state.sorting.length 
                        ? initialColumns['management'][state.subPage].find(item => item.name === state.sorting[0].columnName)?.title 
                        : null,
          sortOrder: opt.sorting && state.sorting.length ? state.sorting[0].direction : null,
          type: payloadType[state.subPage],
          includeAssetWithStatus: payloadType[state.subPage] === reportPayloadType.deadSoldExported
        };

        fileName = `${currentReport.label}`
        break;
    }

    await fetchReports(
      {
        url: currentReport.value,
        method: postMethod,
        data: data,
        params: params
      }
    ).then((resp: any) => {
      const rowsToSet = [];
      const headers = [] as string[];
      let newColumnOrder, newColumns, newColumnExtensions, newColumnVisibility, newColumnSort, newColumnFilter= []

      if (resp.extraColumns?.length > 0) {
        // show daynamic `month` and `average daily gain` columns by number of month
        newColumnOrder = JSON.parse(JSON.stringify(state.columnOrder))
        newColumns = JSON.parse(JSON.stringify(state.columns))
        newColumnExtensions = JSON.parse(JSON.stringify(state.columnExtensions))
        newColumnVisibility = JSON.parse(JSON.stringify(state.columnVisibility))
        newColumnSort = JSON.parse(JSON.stringify(state.columnSort))
        newColumnFilter = JSON.parse(JSON.stringify(state.columnFilter))
        resp.extraColumns.forEach((extraCol) => {
          if (state.columnOrder.includes(extraCol)) return
          newColumnOrder.push(extraCol)
          newColumns.push({name: extraCol, title: extraCol, customFilter: false})
          newColumnExtensions.push({columnName: extraCol, width: '200', align: 'center', wordWrapEnabled: true})
          newColumnVisibility.push({columnName: extraCol, togglingEnabled: false})
          newColumnSort.push({columnName: extraCol, sortingEnabled: false})
          newColumnFilter.push({columnName: extraCol, filteringEnabled: false})
        })
        changeColumnStates(false, newColumns, newColumnOrder, newColumnExtensions, newColumnFilter, newColumnSort, newColumnVisibility)
      }

      const currColumnOrder = resp.extraColumns?.length > 0 ? newColumnOrder : state.columnOrder
      if (Array.isArray(resp)) {
        // responses for Ceres Tag Report
        resp?.forEach((res) => {
          rowsToSet.push(formatRows(res, currColumnOrder, state.hiddenColumns, opt.download, opt.download && state.hiddenColumns.length > 0))
        });
      } else if (typeof resp === 'object') {
        // responses for Management Report
        resp.filteredResult?.forEach((res) => {
          rowsToSet.push(formatRows(res, currColumnOrder, state.hiddenColumns, opt.download, opt.download && state.hiddenColumns.length > 0))
        });
        localDispatch({type: 'change/totalData', totalData: resp.totalRow});
        if (resp.options) {
          formMap.current = getFormMap(page, businessProfile.isPlants(), businessReports, businessProfile.isAustralia());

          //management pages -> only update inner multiselect filters' options when state.updateOptions is true
          if (state.updateOptions) {
            updateMultiSelectOptions(resp.options)
            localDispatch({type: 'change/updateOptions', updateOptions: false})
          } 
        }
      }
      if (opt.download) {
        if (page === 'ceres') {
          state.columnOrder.forEach((col) => {
            if (!state.hiddenColumns.includes(col)) {
              headers.push(initialColumns[page].find((header) => header.name === col)['title']);
            }
          });
        }
        downloadCSV({
          headers: headers,
          body: rowsToSet.length > 0 ? rowsToSet : resp,
        }, fileName);
      } else {
        localDispatch({type: 'get/data', rows: rowsToSet})
      }
    }).catch((error) => {
      console.error(error)
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    });
    dispatch({type: SPINNER_TOGGLE_OFF});
  }

  useEffect(() => {
    changeSubPage(subPagesList[getFormMapIndex] as SubPageType)
    localDispatch({type: 'update/form', report: getFormMapIndex});
    changeTableGenerated(false)
  }, [getFormMapIndex]);
  // for outer filter DatePicker
  useEffect(() => {
    const isDatesValid = moment(state.outerFilter.end_date) >= moment(state.outerFilter.start_date);

    localDispatch({type: 'set/error', errorState: {
      ...state.errorState,
      dates: {
        status: isDatesValid,
        message: isDatesValid ? '' : 'Please select valid dates.',
      }
    }})
  }, [state.outerFilter.start_date, state.outerFilter.end_date]);

  useEffect(() => {
    // prevent fetchReport when initiate fieldConditions
    if (page === 'management' && 
    (
      !state.subPage || 
      (!state.fieldConditions || Object.keys(state.fieldConditions).length === 0) || 
      state.fieldConditions === initialFieldConditions[page][state.subPage])) return
    if (state.outerFilter?.locations?.length > 0 || state.subPage) {
      // if currentPage is 0, need to requestFirstPage to prevent requesting page > totalPage
      // if currentPage > 0, only update currentPage to 0
      if (state.currentPage === 0) {
        fetchReport({download: false, requestFirstPage: true, sorting: true})(); 
      } else {
        localDispatch({type: 'change/page', currentPage: 0});
      }
    }
  }, [state.fieldConditions])

  useEffect(() => {
    if (state.outerFilter?.locations?.length > 0 || state.subPage) {
      if (!state.tableGenerated) return;
      // prevent fetchReport twice when re-click generate 
      // by resetFieldConditionsAndFilters() change currentPage to 0
      if (state.updateOptions) return; 
      fetchReport({download: false, sorting: true})();
    }
  }, [state.currentPage]);

  useEffect(() => { 
    if (!state.tableGenerated) return;
    if (state.currentPage === 0) {
      fetchReport({download: false, sorting: true})();
    } else {
      localDispatch({type: 'change/page', currentPage: 0});
    } 
  }, [state.sorting, state.pageSize]);

  useEffect(() => {
    localDispatch({type: 'change/tableGenerated', tableGenerated: false})
    changeColumnStates(true)
    resetSubPageStates()
  }, [state.subPage])

  useEffect(() => {
    // only fetch sites when no sites in state
    if (!siteMaps.length) {
      dispatch(fetchSiteTokens());
    } 
  }, []);

  useEffect(() => {
    const siteList = [...new Set(siteMaps.map((site) => site.details.siteName))];
    localDispatch({type: 'update/sites', sites: siteList})
  }, [siteMaps]);

  useEffect(() => {
    if (!state.tableGenerated) return
    handleHiddenColumnsChange()
  }, [state.hiddenColumns])

  useEffect(() => {
    if (!state.subPage || state.subPage !== 'weightHistory') return
    // reset states if change numberOfMonths
    changeColumnStates(true)
  }, [state.outerFilter.numberOfMonths])

  useEffect(() => {
    if (!state.subPage || state.subPage !== 'movement') return
    // keep outer filter site_moved_to sync to inner filter site_moved_to
    updateOuterFilter('site_moved_to', state.selectedFilters.site_moved_to)
  }, [state.selectedFilters.site_moved_to])

  return {
    state,
    localizedPIC,
    locationPicAddr,
    allowGenerate,
    updateForm,
    changePage,
    changePageSize,
    changeSubPage,
    changeColumnOrder,
    changeHiddenColumn,
    fetchReport,
    initialColumns,
    defaultRowFields,
    updateFieldConditions,
    changeSelectedFilters,
    changeTableGenerated,
    changeSorting,
    setInnerFilterDateError,
    updateOuterFilter,
    updateOuterInnerFilters,
    handleSubPageChange,
    handleMultiSelectChange,
    handleTextfieldChange,
    handleDateRangeChange,
    resetFieldConditionsAndFilters,
    handleClickGenerate,
    sexList,
    businessProfile,
    ...{formMap: formMap?.current},
    ...{columns: state.columns},
    ...{columnExtensions: state.columnExtensions},
  }
}