import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Select,
} from '@material-ui/core';
import {MultipleSelect} from '../../presentation/MultupleSelect';
import DatePicker from '../../presentation/DatePicker';
import {OuterFilterField} from './ReportsFields';

const useStyle = makeStyles((theme) => ({
  inputRangeShorten: {
    maxWidth: '75%',
  },
}));

export const OuterFilterFields: React.FC<{
  field: OuterFilterField;
  state: any;
  optionalState?: any;
  setState: any;
  errorState?: any;
  customItem?: any;
}> = ({field, state, setState, errorState, optionalState, customItem}) => {
  const classes = useStyle();

  const generateOuterFilterField = () => {
    switch (field.type) {
      case 'number':
        return (
          <TextField
            type={field.type}
            name={field.key}
            InputLabelProps={{shrink: true}}
            variant={field.variant ? 'standard' : 'outlined'}
            fullWidth
            value={state[field.key] || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setState(field.key, parseFloat(e.target.value))}
            InputProps={{inputProps: {min: field.inputProps.min, step: field.inputProps.step}}}
            error={state[field.key] < field.inputProps.min}
            helperText={state[field.key] < field.inputProps.min ? `Please enter number larger than ${field.inputProps.min-1}`  : ''}
          />
        );
      case 'text':
        return (
          <TextField
            type={field.type}
            name={field.key}
            InputLabelProps={{shrink: true}}
            variant={field.variant ? 'standard' : 'outlined'}
            fullWidth
            value={state[field.key] || ''}
            onChange={setState}
            //   className={field.sublabel ? classes.inputRangeShorten : ''}
          />
        );
      case 'multiselect':
        return (
          <MultipleSelect
            dataSet={field?.options ?? optionalState[field.key2] ?? []}
            selectedData={state[field.key] || []}
            setSelectedData={(payload) => setState(field.key, payload)}
          />
        );
      case 'date':
        return (
          <DatePicker
            dateValue={state[field.key]}
            handleChange={(date) => {
              setState(field.key, date);
            }}
            errorStatus={errorState[field.key2]?.status ?? true}
            errorMessage={errorState[field.key2]?.message}
            disablePast={field.diablePast ?? false}
          />
        );
    }
  };
  return (
    <Grid container item sm={6} md={4}>
      <Typography variant="h6" role="label" style={{fontWeight: 600}}>
        {field.customOther ? customItem : field.label}
      </Typography>
      <Grid container direction="column">
        {generateOuterFilterField()}
      </Grid>
    </Grid>
  );
};
