import API from '../../config/api';
import CONSTANT from '../../config/constant';

import {NetworkError} from '../../utils/error';
import {callAPI} from '../../utils/network';
import { TokenService } from '@aglive/data-model';

export async function fileUploader(userId: string, file: File, category?: string) {
  try {
    const data = new FormData();

    data.append('category', category ?? 'product');
    data.append('userId', userId);
    data.append('file', file);
    const response = await callAPI({
      url: API.POST.uploadTos3,
      method: 'POST',
      data,
    });

    return response;
  } catch (e) {
    throw new NetworkError(e);
  }
}

export async function addProductToBrand(
  userId: string,
  brandId: string,
  productId: string,
) {
  try {
    if(brandId){
      const brandRes = await callAPI<TokenService.BrandToken>({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          externalIds: [{agliveToken: brandId}],
        },
      });
      let productList = brandRes[0]?.details?.hasOwnProperty('products')
        ? brandRes[0].details.products
        : [];
      productList.push({agliveToken: productId});
  
      const response = await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BRAND,
              externalIds: {agliveToken: brandId},
              activities: [
                {
                  type: 'UP_details',
                  details: {products: productList},
                },
              ],
            },
          ],
        },
      });
      return response;
    }
  } catch (e) {
    throw e;
  }
}
