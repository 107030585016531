import React from "react";
import {
  FormControl,
  makeStyles,
} from "@material-ui/core";
import "../container/reports/styles.css";
import { Picky} from "react-picky";
import "react-picky/dist/picky.css";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: 56,
  },
}));

export type MultipleSelectOptionsType = string | { label: string, value: string };

export const MultipleSelect: React.FC<{
  dataSet: Array<MultipleSelectOptionsType>;
  selectedData: Array<MultipleSelectOptionsType>;
  setSelectedData: (arg0: any) => void;
  value?: Array<MultipleSelectOptionsType>;
  variant?: "outlined" | "standard" | "filled";
  placeholder?: string;
  dropdownHeight?: number;
  numberDisplayed?: number;
}> = ({ dataSet, selectedData, setSelectedData, variant, value, placeholder, dropdownHeight, numberDisplayed = 3 }) => {
  const classes = useStyles();
  const options = dataSet?.map((data) => ({ 
    label: typeof data === 'string' ? data : data.label,
    value: typeof data === 'string' ? data : data.value,
  }));

  const handleChange = (selectedData: Array<MultipleSelectOptionsType>) => { setSelectedData(selectedData);}
  return (
    <FormControl variant={variant ?? "outlined"} className={classes.formControl}>
      <Picky
        id="picky"
        labelKey="label"
        valueKey="value"
        options={options}
        value={value ?? selectedData}
        multiple={true}
        includeSelectAll={true}
        includeFilter={true}
        //@ts-ignore
        onChange={handleChange}
        dropdownHeight={dropdownHeight || 600}
        buttonProps={{
          style: {
            height: 58,
            border: variant === "standard" ? "0px" : "1px solid #D9D9D9",
            fontFamily: "Open Sans",
            fontSize: "16px",
            borderRadius: "5px",
          },
        }}
        placeholder={placeholder ?? "None selected"}
        numberDisplayed={numberDisplayed}
      />
    </FormControl>
  );
}

