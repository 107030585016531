import React from 'react';
import * as PapaParse from 'papaparse';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import COLOR from '../styled/colors';
import Button from '@material-ui/core/Button';

interface IFileInfo {
  name: string;
  size: number;
  type: string;
}
interface CSVReaderProps {
  accept?: string;
  cssClass?: string;
  cssInputClass?: string;
  cssLabelClass?: string;
  fileEncoding?: string;
  inputId?: string;
  inputStyle?: object;
  label?: string | React.ReactNode;
  onError?: (error: Error) => void;
  onFileLoaded: (data: Array<any>, fileInfo: IFileInfo) => any;
  onCSVParsed?: (data: FileList) => any;
  parserOptions?: PapaParse.ParseConfig;
  disabled?: boolean;
}

const CSVReader: React.FC<CSVReaderProps> = ({
  accept = '.csv, text/csv',
  cssClass = 'csv-reader-input',
  cssInputClass = 'csv-input',
  cssLabelClass = 'csv-label',
  fileEncoding = 'UTF-8',
  inputId = 'react-csv-reader-input',
  inputStyle = {},
  label,
  onError,
  onFileLoaded,
  onCSVParsed = () => {},
  parserOptions = {} as PapaParse.ParseConfig,
  disabled = false,
}) => {
  // const classes = useStyles();
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader: FileReader = new FileReader();
    const files: FileList = e.target.files!;
    if (files.length > 0) {
      const fileInfo: IFileInfo = {
        name: files[0].name,
        size: files[0].size,
        type: files[0].type,
      };

      reader.onload = (_event: Event) => {
        const csvData = PapaParse.parse(
          String(reader.result).replace(/\r/g, '') as string,
          Object.assign(parserOptions, {
            error: onError,
            encoding: fileEncoding,
          }),
        );
        onFileLoaded(csvData?.data ?? [], fileInfo);
        onCSVParsed(files);
      };

      reader.readAsText(files[0], fileEncoding);
    }
  };

  return (
    <div className={cssClass}>
      {label && (
        <label className={cssLabelClass} htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        className={cssInputClass}
        type="file"
        id={inputId}
        style={inputStyle}
        accept={accept}
        onChange={(e) => handleChangeFile(e)}
        disabled={disabled}
      />
    </div>
  );
};

export const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(6),
  },
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 600, // TODO
  },
  fileName: {
    height: 48,
    width: '100%',
    maxWidth: window.innerWidth / 2,
    borderColor: COLOR.GRAY_BORDER,
    border: '1px solid',
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  uploadButton: {
    width: 192,
    height: 48,
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: 1.5,
    padding: '6px 12px',
    marginLeft: theme.spacing(4),
    border: '1px solid',
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.info.main,
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginLeft: 0,
    },
  },
}));
type CSVUploaderProps = {
  file: File;
  setFile: (arg) => void;
  setCSVData: (arg) => void;
  useHeader?: boolean;
  fileName?: string;
};

const CSVUploader = (props: CSVUploaderProps) => {
  const classes = useStyles();
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
  };
  const needHeader = props.useHeader === false ? false : true;
  return (
    <Grid className={classes.grid}>
      {needHeader && <Typography variant="h3">{'Upload CSV File'}</Typography>}

      <Typography variant="h6" className={classes.subTitle} role="label">
        {'CSV File'}
      </Typography>

      <Grid container direction="row">
        <Grid item className={classes.fileName} xs={12}>
          <Typography noWrap={true}>
            {props.fileName ? props.fileName : props.file?.name}
          </Typography>
        </Grid>
        <CSVReader
          inputId="csvReader"
          cssInputClass="csvReader"
          onFileLoaded={(data, fileInfo) => {
            props.setCSVData(data);
          }}
          parserOptions={papaparseOptions}
          onCSVParsed={(data) => props.setFile(data[0])}
        />
        <Grid item>
          <label htmlFor="csvReader">
            <Button
              variant="outlined"
              color="primary"
              component="span"
              className={classes.uploadButton}>
              Upload CSV
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CSVUploader;
