import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {toggleModal} from '../../store/modal/actions';
import {getProfiles} from '../../store/profile/actions';
import {useHistory, useLocation} from 'react-router-dom';
import withHeader from '../../presentation/withHeader';
import Dashboard from './Dashboard';

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {state} = useLocation<{fromAuth?: boolean}>();

  const userid = useAppSelector((state) => state.auth.wallet);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );

  // if isAngus, route to Angus Dashboard
  useEffect(() => {
    dispatch(getProfiles(userid));
  }, []);

  useEffect(() => {
    if (state?.fromAuth && businessProfile.companyName) {
      if (
        businessProfile.industryType === 'ANIMALS' &&
        businessProfile.businessType === 'Angus'
      ) {
        history.push('/private/verified');
      } else {
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Welcome',
            subtitle: [''],
            button: 'Continue',
            CTAHandler: () => {
              if (
                businessProfile.industryType === 'ANIMALS' &&
                businessProfile.businessType === 'Angus'
              ) {
                history.push('/private/verified');
              } else {
                //history.push('/private/register/asset');
              }
            },
          }),
        );
      }
    }
  }, [businessProfile?.companyName]);

  return <Dashboard />;
};

export default withHeader(
  {
    title: 'Dashboard',
    margin: 40,
  },
  Home,
);
