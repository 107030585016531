import API from '../../config/api';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {AppThunk} from '../../store/types';
import {
  POST_USER_PROFILE_DATA,
  POST_BUSINESS_PROFILE_DATA,
  POST_PLUGIN_DATA,
  POST_REPORT_DATA,
  angusProfileType,
  PolygonPaths,
  businessProfileTypes,
  locationTypes,
} from './types';
import axios from 'axios';
import {WebError, WebErrorType} from '../../utils/error';
import {toggleModal} from '../modal/actions';
import {callAPI} from '../../utils/network';
import CONSTANT from '../../config/constant';
import {IMPORT_LOCATION, locationType} from '../location/types';
import {BusinessMisc,TokenModel, TokenService} from '@aglive/data-model';

type editUserProfileType = {
  name: string;
  mobileNumber: string;
  emailAddress: string;
  // country: string;
};

export function getUserProfile(userId: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['user'],
          activityType: [],
        },
      });

      const userProfile = response[0]?.details;
      dispatch({
        type: POST_USER_PROFILE_DATA,
        payload: userProfile,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getUserCeresTags(
  useOptiweigh: boolean,
  dayLimit?: number,
): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const plugins = getState().user.plugins ?? [];
      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['asset', 'angusCalve'],
          activityType: useOptiweigh
            ? ['addMovement', 'updateWeight', 'updateOptiWeigh']
            : ['addMovement', 'updateWeight'],
          latestActivityDateRange: dayLimit ?? 1,
          pluginType: plugins.filter((plg) => plg.status === 'activated').map((plg) => plg.name)
        },
      });

      return Promise.resolve(response);
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return Promise.reject(error);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getBusinessProfile(): AppThunk<Promise<any>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const businessId = getState().user.userProfileData.businessId;
      const businessResponse: Array<TokenService.BusinessToken> = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          activityType: [],
          externalIds: [{agliveToken: businessId}],
        },
      });

      dispatch({
        type: POST_BUSINESS_PROFILE_DATA,
        payload: businessResponse[0].details,
      });

      dispatch({
        type: POST_PLUGIN_DATA,
        payload: businessResponse[0].plugins ?? [],
      });

      dispatch({
        type: POST_REPORT_DATA,
        payload: businessResponse[0].reports ?? [],
      });
      dispatch({type: SPINNER_TOGGLE_OFF});
      return Promise.resolve(businessResponse[0]);
    } catch (e) {
      console.error(e.message);
      return Promise.reject(e);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getProfiles(userId: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      // user profile
      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['user'],
          activityType: [],
        },
      });

      const userProfile = response[0]?.details;

      dispatch({
        type: POST_USER_PROFILE_DATA,
        payload: userProfile,
      });

      // business profile
      const businessResponse = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          activityType: [],
          externalIds: [{agliveToken: response[0]?.details.businessId}],
        },
      });

      dispatch({
        type: POST_BUSINESS_PROFILE_DATA,
        payload: businessResponse[0].details,
      });
      dispatch({
        type: POST_PLUGIN_DATA,
        payload: businessResponse[0].plugins ?? [],
      });

      dispatch({
        type: POST_REPORT_DATA,
        payload: businessResponse[0].reports ?? [],
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateUserProfile(
  updatedUserData: editUserProfileType,
  userId: string,
  businessCountry:string
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.USER,
              externalIds: {userId},
              activities: [
                {
                  type: 'UP_details',
                  details: updatedUserData,
                },
              ],
            },
          ],
        },
      });
     
      dispatch(getProfiles(userId))
      dispatch(
        toggleModal({
          status: 'success',
          title: businessCountry==='Argentina'?'Actualizado':'Updated',
          button:businessCountry==='Argentina'?'Cerrar':'Close',
          CTAHandler: () => window.history.back(),
        }),
      );

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateBusinessProfile(
  isLivestock: boolean,
  updatedBusinessData: TokenService.BusinessToken['details'],
  deleteLocation: boolean,
  initialBusinessData: TokenService.BusinessToken['details'],
  deletePermit?: boolean,
  optiweigh?: BusinessMisc.AnimalBusinessInterface["optiweigh"] & {delete?: boolean},
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {
        auth: {wallet},
        user: {userProfileData},
      } = getState();
      // TODO: get user profile from state *
      // const agliveToken_business = user.details.businessId *
      // externalId: {agliveToken: agliveToken_business} *

      // Temp solution for the industries that don't have PICAddress,
      // use locationNickName as PICAddress for now
      if (!isLivestock) attachPICAddress(updatedBusinessData.location);

      /*const agliveBusinessProfile = {
        businessScope: updatedBusinessData.businessScope,
        businessType: updatedBusinessData.businessType,
        companyName: updatedBusinessData.companyName,
        companyNumber: updatedBusinessData.companyNumber,
        licenseNumber: updatedBusinessData.licenseNumber,
        licenseExpiryDate: updatedBusinessData.licenseExpiryDate,
        brandDescription: updatedBusinessData.brandDescription,
        imageUri: updatedBusinessData.imageUri,
        location: updatedBusinessData.location??[],
        permits:updatedBusinessData.permits??[],
      };*/

      const {users, industryType, ...agliveBusinessProfile} =
        updatedBusinessData;

      const agliveToken_business = userProfileData.businessId;
      if (deleteLocation) {
        await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.BUSINESS,
                externalIds: {agliveToken: agliveToken_business},
                activities: [
                  {
                    type: 'DEL_details',
                    details: {
                      location: initialBusinessData.location,
                    },
                  },
                ],
              },
            ],
          },
        });
      }
      if (deletePermit && initialBusinessData.industryType === 'PLANTS') {
        await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.BUSINESS,
                externalIds: {agliveToken: agliveToken_business},
                activities: [
                  {
                    type: 'DEL_details',
                    details: {
                      permits: initialBusinessData.permits,
                    },
                  },
                ],
              },
            ],
          },
        });
      }

      const activities = [];

      if (optiweigh && optiweigh.delete && updatedBusinessData.industryType === 'ANIMALS') {
        activities.push({
          type: 'DEL_details',
          details: {optiweigh: updatedBusinessData.optiweigh},
        });
        delete agliveBusinessProfile['optiweigh'];
      }

      activities.push({
        type: 'UP_details',
        details: agliveBusinessProfile,
      });

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {agliveToken: agliveToken_business},
              activities: activities,
            },
          ],
        },
      });

      dispatch({
        type: IMPORT_LOCATION,
        payload: agliveBusinessProfile.location,
      });

      if (optiweigh && optiweigh.apiKey) {
        await callAPI({
          url: API.POST.optiweigh,
          method: 'POST',
          data: {
            businessId: agliveToken_business,
            optiweighClientId: optiweigh.clientId,
            optiweighApiKey: optiweigh.apiKey,
            agliveApiKey: optiweigh.agliveKey,
            type: optiweigh.delete ? 'optiweighRemove' : 'optiweighRecords',
            scheduleRepetition: Number(optiweigh.interval),
          },
        });
      }

      dispatch(
        toggleModal({
          status: 'success',
          title: updatedBusinessData.businessCountry==='Argentina'?CONSTANT.ES_LABELS.updated:CONSTANT.EN_LABELS.updated,
          button:updatedBusinessData.businessCountry==='Argentina'?CONSTANT.ES_LABELS.close:CONSTANT.EN_LABELS.close,
          CTAHandler: () => window.history.back(),
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

function attachPICAddress(locationData: Array<locationType>) {
  locationData.forEach((location) => {
    location.PICAddress = location.locationNickname;
  });
}

export async function validateCredential(
  validateLocation: Array<locationTypes>,
  dispatch,
) {
  const locationError = [];
  const locationStatus = [];
  dispatch({type: SPINNER_TOGGLE_ON});
  for (let i = 0; i < validateLocation.length; i++) {
    const credential: {lpa?: object; nlis?: object; pic: string} = {pic: ''};
    credential.pic = validateLocation[i].PICAddress;

    if (validateLocation[i].LPAUserID) {
      credential.lpa = {
        scope: 'lpa_scope',
        username: `${credential.pic}-${validateLocation[i].LPAUserID}`, // "QCZZ8888-1201067"
        password: validateLocation[i].LPAPassword, // "1201067"
      };
    }

    if (validateLocation[i].NLISUserID) {
      credential.nlis = {
        scope: 'nlis_scope',
        username: validateLocation[i].NLISUserID, // "2PRODAL2"
        password: validateLocation[i].NLISPassword, // "2PRODAL2"
      };
    }
    // Only proceed to validate the credentials and PIC if all the infos are provided
    if (credential.lpa && credential.nlis && credential.pic) {
      try {
        const response = await axios.post(API.POST.validate, credential);
        locationStatus.push(response?.statusText);
      } catch (error) {
        let errorObject = {
          details: error?.response?.data?.source?.data,
          index: i,
        };
        locationError.push(errorObject);
      }
    }
  }
  if (locationError.length >= 1) {
    throw locationError;
  } else {
    dispatch({type: SPINNER_TOGGLE_OFF});
    return locationStatus;
  }
}

export function createGeofence(
  polygonPaths: PolygonPaths,
  locationIndex: number,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      if (!polygonPaths) {
        console.log('INVALID_INPUT');
        throw new WebError('INVALID_INPUT');
      }

      const businessId = getState().user.userProfileData.businessId;
      const businessProfile = getState().user.businessProfileData;
      let location = JSON.parse(
        JSON.stringify(getState().user.businessProfileData.location),
      );
      const newLocationArr = getLocationArray(
        location[locationIndex],
        locationIndex,
        polygonPaths,
      );
      let activities = [];
      dispatch({type: SPINNER_TOGGLE_ON});

      if (location[locationIndex].geofence?.length > polygonPaths.length) {
        //delete first if previous array is larger
        let delLocation = [];
        delLocation[locationIndex] = {
          geofence: location[locationIndex].geofence,
        };
        activities.push({
          type: 'DEL_details',
          details: {
            location: delLocation,
          },
        });
      }

      if (polygonPaths.length > 0) {
        location[locationIndex].geofence = polygonPaths;
        activities.push({
          type: 'UP_details',
          details: {
            location: newLocationArr,
          },
        });
      }

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {
                agliveToken: businessId,
              },
              activities: activities,
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Saved',
        }),
      );
      dispatch({
        type: POST_BUSINESS_PROFILE_DATA,
        payload: {...businessProfile, location: location},
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

function getLocationArray(
  location: locationTypes,
  locationIndex: number,
  polygonPaths: PolygonPaths,
) {
  if (locationIndex === 0) {
    return [{...location, geofence: polygonPaths}];
  } else {
    const locArr = [];
    let i = locationIndex;
    while (i > 0) {
      locArr.push({});
      i--;
    }
    locArr.push({...location, geofence: polygonPaths});
    return locArr;
  }
}

export function sendAlertSetting(
  businessId: string,
  pic: string,
  alertSettings: { 
  noActivity: boolean,
  lowActivity: boolean,
  mediumActivity: boolean,
  highActivity: boolean,
  extremelyHighActivity: boolean,
  location: boolean,
  inactive: boolean }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const locationList: Array<locationTypes>=getState().user.businessProfileData.location;
      const alertSetting = {
        activityLevel: {
          no: alertSettings.noActivity,
          low: alertSettings.lowActivity,
          medium: alertSettings.mediumActivity,
          high: alertSettings.highActivity,
          extreme: alertSettings.extremelyHighActivity,
        },
        location: alertSettings.location,
        inactive: alertSettings.inactive
      };
      const updateLocation= locationList.map(location=>{
        if(location.PICAddress===pic){
          return {...location, tagAlertStandard:alertSetting}
        }
        else{
          return location
        }
      })
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {agliveToken: businessId},
              activities: [
                {
                  type: 'UP_details',
                  details: {
                    location:updateLocation
                  },
                },
              ],
            },
          ],
        },
      });
      dispatch(getBusinessProfile()); //update to latest
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getPlugins(uuid?: string): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.GET.plugins + (uuid ? `?pluginId=${uuid}` : ''),
        method: 'GET'
      });
      return Promise.resolve(response);
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return Promise.reject(error);
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function activatePlugin(
  activityType: 'activatePlugin' | 'deactivatePlugin' | 'editPlugin',
  details: {
    uuid: string,
    credentials?: any,
    schedule?: string,
    status?: TokenService.BusinessPlugin['status']
  }, 
  successCallback?: () => void, 
  failCallback?: () => void
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BUSINESS,
              externalIds: {agliveToken: getState().user.userProfileData.businessId},
              activities: [
                {
                  type: activityType,
                  details: details,
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: activityType === 'editPlugin' 
                ? 'Updated' 
                : activityType === 'activatePlugin' 
                  ? 'Activated' 
                  : 'Deactivated',
          CTAHandler: successCallback,
        }),
      );

      dispatch({type: SPINNER_TOGGLE_OFF});
    } catch (error) {
      console.log('error', error, failCallback);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          CTAHandler: failCallback
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}