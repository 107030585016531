import {DashboardConfigType, DashboardWidgetType} from '../types';
import {data} from '../utils';
import API from '../../../../config/api';

const dashboardConfig: DashboardConfigType = {
  rows: [
    {
      widgets: [
        {
          type: DashboardWidgetType.DROPDOWN,
          payload: {
            title: 'PIC No.',
            key: 'picAddress',
            source: 'location',
            transformData: (dataStore) =>
              (dataStore?.picAddress || []).map((loc) => ({
                value: loc.PICAddress,
                attribute: {
                  label: `${loc.locationNickname} (${loc.PICAddress})`,
                  key: loc.PICAddress,
                },
              })),
          },
        },
        {
          type: DashboardWidgetType.DROPDOWN,
          payload: {
            title: 'Mob Name',
            key: 'mobName',
            dependentKey: ['picAddress'],
            source: {
              url: API.POST.getTokenbyAddr,
              method: 'POST',
              data: {
                latestDetails: true,
                status: ['exist'],
                type: ['angusMob'],
                activityType: [],
              },
            },
            transformData: (dataStore, dependencies) =>
              (dataStore?.mobName || [])
                .filter(
                  (asset) =>
                    asset.details.pic === dependencies.picAddress.value,
                )
                .map(({details}, index) => ({
                  value: index,
                  attribute: {
                    label: details.mob_name,
                    key: details.mob_name,
                  },
                })),
          },
        },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.CARD,
          payload: {
            title: 'Herd',
            subtitle: 'Total Number Inducted',
            icon: 'Cow',
            dependentKey: ['picAddress', 'mobName'],
            key: 'assets',
            source: {
              url: API.POST.getTokenbyAddr,
              method: 'POST',
              data: {
                latestDetails: true,
                status: ['exist'],
                type: ['angusMob'],
                activityType: [],
              },
            },
            transformData: (dataStore, dependencies) => {
              const targetedAssets = (dataStore?.assets || [])
                .map((asset) => ({
                  name: asset.details.mob_name,
                  pic: asset.details.pic,
                  list: asset.details.calves.add.map((calf) => ({
                    identifier: calf.nlis_id,
                    species: 'Cattle',
                    breed: 'Angus',
                    sex: calf.sex === 'Steer' ? 'Male' : calf.sex,
                    weight: calf.weight,
                    age: new Date().getFullYear() - asset.details.birth_year,
                  })),
                }))
                .filter(
                  (asset, index) =>
                    (dependencies.picAddress?.value &&
                      dependencies.picAddress.value === asset.pic) ||
                    (dependencies.mobName?.value !== undefined &&
                      String(dependencies.mobName.value) !== String(index)),
                );

              return Object.entries(
                targetedAssets
                  .map((asset) => asset.list)
                  .flat()
                  .reduce((accum, asset) => {
                    const sex =
                      (asset.sex &&
                        asset.sex?.slice(0, 1).toUpperCase() +
                          asset.sex?.slice(1)) ??
                      'Unknown';

                    if (!accum[sex]) {
                      accum[sex] = 1;
                    } else {
                      accum[sex]++;
                    }

                    return accum;
                  }, {}),
              ).map(([sex, quantity]) => [`${sex}s`, quantity]);
            },
          },
        },
        {
          type: DashboardWidgetType.CARD,
          payload: {
            title: 'Weight',
            subtitle: 'Herd Average',
            icon: 'Scale',
            dependentKey: ['picAddress', 'mobName'],
            key: 'assets',
            source: {
              url: API.POST.getTokenbyAddr,
              method: 'POST',
              data: {
                latestDetails: true,
                status: ['exist'],
                type: ['angusMob'],
                activityType: [],
              },
            },
            transformData: (dataStore, dependencies) => {
              const targetedAssets = (dataStore?.assets || [])
                .map((asset) => ({
                  name: asset.details.mob_name,
                  pic: asset.details.pic,
                  list: asset.details.calves.add.map((calf) => ({
                    identifier: calf.nlis_id,
                    species: 'Cattle',
                    breed: 'Angus',
                    sex: calf.sex === 'Steer' ? 'Male' : calf.sex,
                    weight: calf.weight,
                    age: new Date().getFullYear() - asset.details.birth_year,
                  })),
                }))
                .filter(
                  (asset, index) =>
                    (dependencies.picAddress?.value &&
                      dependencies.picAddress.value === asset.pic) ||
                    (dependencies.mobName?.value !== undefined &&
                      String(dependencies.mobName.value) !== String(index)),
                ).list;

              let stats: Record<string, Record<string, number>> = targetedAssets
                .map((asset) => asset.list)
                .flat()
                .reduce((accum, asset) => {
                  const sex =
                    (asset.sex &&
                      asset.sex?.slice(0, 1).toUpperCase() +
                        asset.sex?.slice(1)) ??
                    'Unknown';

                  if (!accum[sex]) {
                    if (asset.weight > 0) {
                      accum[sex] = {
                        quantity: 1,
                        weight: asset.weight,
                      };
                    }
                  } else {
                    if (asset.weight > 0) {
                      accum[sex].quantity++;
                      accum[sex].weight += asset.weight;
                    }
                  }

                  return accum;
                }, {});

              return Object.entries(stats).map(([sex, {weight, quantity}]) => [
                `Average ${sex}`,
                `${Math.floor(weight / Math.max(quantity, 1))} KG`,
              ]);
            },
          },
        },
        // {
        //   type: DashboardWidgetType.CARD,
        //   payload: {
        //     title: 'Herd Value',
        //     subtitle: 'Total Value',
        //     icon: 'Dollar',
        //     cardContent: Object.keys(
        //       fetchedAssets.reduce((accum, asset) => {
        //         const sex =
        //           (asset.sex &&
        //             asset.sex?.slice(0, 1).toUpperCase() + asset.sex?.slice(1)) ??
        //           'Unknown';

        //         if (!accum[sex]) {
        //           accum[sex] = true;
        //         }

        //         return accum;
        //       }, {}),
        //     ).map((sex) => [
        //       <HerdContainer
        //         title={`${sex}s: Latest Price`}
        //         price={hasAngus ? (sex === 'Steer' ? '685.33' : '665.18') : ''}
        //       />,
        //       `$ `,
        //     ]),
        //     sizing: [8, 4]
        //   }
        // },
      ],
    },
    {
      widgets: [
        {
          type: DashboardWidgetType.TABLE,
          payload: {
            columns: data('NLIS'),
            dependentKey: ['picAddress', 'mobName'],
            key: 'assets',
            source: {
              url: API.POST.getTokenbyAddr,
              method: 'POST',
              data: {
                latestDetails: true,
                status: ['exist'],
                type: ['angusMob'],
                activityType: [],
              },
            },
            transformData: (dataStore, dependencies) =>
              (dataStore?.assets || [])
                .map((asset) => ({
                  name: asset.details.mob_name,
                  pic: asset.details.pic,
                  list: asset.details.calves.add.map((calf) => ({
                    identifier: calf.nlis_id,
                    species: 'Cattle',
                    breed: 'Angus',
                    sex: calf.sex === 'Steer' ? 'Male' : calf.sex,
                    weight: calf.weight,
                    age: new Date().getFullYear() - asset.details.birth_year,
                  })),
                }))
                [Number(dependencies.mobName.value)].list.map(
                  ({identifier, sex, weight, age}, index) => {
                    return {
                      idx: index + 1,
                      identifier: identifier,
                      link: {
                        pathname: `${dataStore.path}/verified/animalProfile/view-animal`,
                        state: {nlis: identifier},
                      },
                      species: 'Cattle',
                      breed: 'Angus',
                      age: age,
                      sex: sex,
                      weight: weight ? weight : 0,
                    };
                  },
                ),
          },
        },
      ],
    },
  ],
};

export default dashboardConfig;
