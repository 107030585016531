import React, {useEffect} from 'react';
import {CASL} from '@aglive/frontend-core';
import CONSTANT from './config/constant';
import {useAppSelector, useAppDispatch} from './utils/hooks';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import EditUserProfile from './container/profile/EditUserProfile';
import BusinessProfile from './container/profile/BusinessProfile';
import EditBusinessProfile from './container/profile/EditBusinessProfile';
import BrandProfile from './container/brand/BrandLibrary/BrandLibrary';
import CreateBrand from './container/brand/BrandProfile/CreateBrand';
import AssetInduction from './container/asset/assetInduction';
import AssetInductionLibrary from './container/asset/AssetInductionLibrary';
import ManageAsset from './container/asset/ManageAsset';
import ManageAssetByCSV from './container/asset/ManageAssetByCSV';
import AVPreSalesReport from './container/verified/AVPreSalesReport';
import MyModal, {Message} from './presentation/Modal';
import CodeLibrary from './container/code/CodeLibrary';
import SecurityCodeLibrary from './container/code/SecurityCode/SecurityCodeLibrary/SecurityCodeLibrary';
import UsedCodes from './container/code/UsedCodes';
// Asset Profile
import AssetProfileLibrary from './container/assetProfile/AssetProfileLibrary';
import CreateAssetProfile from './container/assetProfile/CreateAssetProfile';
import ViewAssetProfile from './container/assetProfile/ViewAssetProfile';
import ArchivedAssetProfiles from './container/assetProfile/ArchivedAssetProfiles';
import GenerateAssets from './container/code/GenerateAssets';
import Spinner from './presentation/Spinner';
import ViewBrand from './container/brand/BrandProfile/ViewBrand';
import UserLibrary from '../src/container/add/UserLibrary';
import MoveActivity from '../src/container/asset/MoveActivity';
import CreateMove from '../src/container/asset/CreateMove';
import AddEditUser from './container/add/AddEditUser';

import SiteLibrary from './container/site/SiteLibrary';
import CreateSite from './container/site/CreateSite';
import EditSite from './container/site/EditSite';
import ViewSite from './container/site/ViewSite';

import Sidebar from './presentation/Sidebar';
import Auth from './container/auth';
import VerifiedActions from './container/verified/VerifiedActions';
import AiJoining from './container/verified/AiJoining';
import ViewAnimalProfile from './container/verified/ViewAnimalProfile';
import ViewSireProfile from './container/verified/ViewSireProfile';
import EditSire from './container/verified/EditSire';
import AnimalProfile from './container/verified/AnimalProfile';
import Treatment from './container/verified/Treatment';
import Pregnancy from './container/verified/Pregnancy';
import AngusVerifiedDetails from './container/verified/AngusVerifiedDetails';
import OrderTags from './container/verified/OrderTags';
import RecordAnimals from './container/verified/RecordAnimals';
import MobDetails from './container/verified/MobDetails';
import EditAnimal from './container/verified/EditAnimal';
import RecordSires from './container/verified/RecordSires';
import SireGroups from './container/verified/SireGroups';
import SireGroupDetails, {
  EditGroupName,
} from './container/verified/SireGroupDetails';
import AddSireToGroup from './container/verified/AddSireToGroup';
import AddAnimalToMob from './container/verified/AddAnimalToMob';
import Home from './container/home';
import UserProfile from './container/profile/UserProfile';
import {
  PluginActive,
  PluginInactive,
  ViewPlugin,
  EditPlugin,
} from './container/profile/Plugins';
import PluginsLibrary from './container/profile/PluginsLibrary';
import CeresTagReport from './container/reports/CeresTagReport';
import TrackAnimal from './container/map/TrackAnimal';
import {toggleModal} from './store/modal/actions';
import {appThunkLogout, initWalletConnect} from './store/auth/actions';

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {MODAL_TOGGLE_OFF} from './store/modal/types';
import {WebErrorType} from './utils/error';
import COLOR from './styled/colors';
import ManagementReport from './container/reports/ManagementReport';
import OtherReports from './container/reports/OtherReports';
import ToolBar from './presentation/ToolBar';
import HealthSupport from './container/HealthSupport';
import MainLanding from './container/promotion/MainLanding';
import PromotionLibrary from './container/promotion/PromotionLibrary';
import CreateAssetPromotion from './container/promotion/CreateAssetPromotion';
import AssetLanding from './container/promotion/AssetLanding';
import ArchivedPromotionLibrary from './container/promotion/ArchivedPromotionLibrary';
import ViewAssetPromotion from './container/promotion/ViewAssetPromotion';
import CreateProductProfile from './container/promotion/ProductProfile/CreateProductProfile';
import CreateProductPromotion from './container/promotion/CreateProductPromotion';
import ViewProductPromotion from './container/promotion/ViewProductPromotion';
import ProductProfileLibrary from './container/promotion/ProductProfileLibrary';
import ArchivedProductProfileLibrary from './container/promotion/ArchivedProductProfileLibrary';
import CodeMainLanding from './container/code/MainLanding/index';
import EditProductProfile from './container/promotion/ProductProfile/EditProductProfile';
import ViewProductProfile from './container/promotion/ProductProfile/ViewProductProfile';
import EditBrand from './container/brand/BrandProfile/EditBrand';
import GenerateSecurityCode from './container/code/SecurityCode/GenerateCodes';
import UsedSecurityCodes from './container/code/SecurityCode/UsedSecurityCodes';
import ArchivedBrandLibrary from './container/brand/ArchivedBrandLibrary/ArchivedBrandLibrary';
import EditCode from './container/code/SecurityCode/EditCodes/EditCode';
import ActivitiesReport from './container/reports/ActivitiesReport';
import ViewUser from './container/add/ViewUser';
import ConsigneeLibrary from './container/add/ConsigneeLibrary';
import AddConsignee from './container/add/AddConsignee';
import ViewConsignee from './container/add/ViewConsignee';
import {
  CreateForm,
  DuplicateForm,
  EditForm,
  ViewForm,
} from './container/asset/FormTemplate';
import FormLibrary from './container/asset/FormLibrary';
import ConsignmentLibrary from './container/consignment/consignmentLibrary';
import {
  CreateConsignment,
  EditConsignment,
  DuplicateConsignment,
} from './container/consignment/consignment';
import ViewConsignment from './container/consignment/ViewConsignment';
import AddCeresTag from './container/add/AddCeresTag';

export default function RootRoute() {
  const dispatch = useAppDispatch();
  const {isLoggedIn} = useAppSelector((state) => state.auth);
  const {isOpen: isSpinnerOpen} = useAppSelector((state) => state.spinner);
  const {isOpen: isModalOpen, ...modalState} = useAppSelector(
    (state) => state.modal,
  );
  const modalConfig: Message = modalState; // TS could not infer the correct type

  return (
    <Router>
      {/*
        A <Switch> looks through all its children <Route>
        elements and renders the first one whose path
        matches the current URL. Use a <Switch> any time
        you have multiple routes, but you want only one
        of them to render at a time
      */}
      <Switch>
        <Route exact path="/">
          <Auth />
        </Route>
        <PrivateRoute isLoggedIn={isLoggedIn} path="/private">
          <ProtectedPages />
        </PrivateRoute>
      </Switch>
      <MyModal
        isOpen={isModalOpen}
        closeHandler={() => dispatch({type: MODAL_TOGGLE_OFF})}
        message={modalConfig}
      />
      <Spinner isOpen={isSpinnerOpen} />
    </Router>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  angusRoot: {
    background: COLOR.AA_BLACK,
  },
  sidebar: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: '200px',
  },
  content: {
    backgroundColor: theme.palette.secondary.main,
    margin: '5px 32px 0 32px',
    padding: '48px',
    borderRadius: '20px 20px 0 0',
    minHeight: '100vh',
    width: 'calc(100% - 64px)',
    flex: 1,
  },
  browserAlert: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: '15px 30px',
    width: '100%',
  },
}));

function ProtectedPages() {
  const businessProfileData = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isPlants = businessProfileData.industryType === 'PLANTS';
  const isAnimals = businessProfileData.industryType === 'ANIMALS';
  const isProducts = businessProfileData.industryType === 'PRODUCTS';
  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );
  const classes = useStyles();
  const {path} = useRouteMatch();
  const currentURL = useLocation();

  // For managing user permissions
  const userRole = useAppSelector((state) => state.user.userProfileData.role);
  const ability = CASL.useAbility(CASL.AbilityContext);

  useEffect(() => {
    // Update permissions based on user role
    if (userRole) {
      CASL.updateAbility(ability, userRole);
    }
  }, [userRole, ability]);

  return (
    <Grid
      container
      wrap="nowrap"
      className={
        isAngus && currentURL.pathname.includes('/verified')
          ? classes.angusRoot
          : classes.root
      }>
      {/* sidebar on the left*/}
      <Sidebar />

      {/* main content */}
      <Grid item container xs={10} direction="column">
        <ToolBar />
        <Grid item container className={classes.content}>
          <Switch>
            <Route exact path={`${path}/verified`}>
              <VerifiedActions />
            </Route>
            <Route exact path={`${path}/verified/recordSires`}>
              <RecordSires />
            </Route>
            <Route exact path={`${path}/verified/recordSires/edit-sire`}>
              <EditSire />
            </Route>
            <Route exact path={`${path}/verified/aiJoining`}>
              <AiJoining />
            </Route>
            <Route exact path={`${path}/verified/sireGroups`}>
              <SireGroups />
            </Route>
            <Route exact path={`${path}/verified/sireGroups/details`}>
              <SireGroupDetails />
            </Route>
            <Route
              exact
              path={`${path}/verified/sireGroups/details/editGroupName`}>
              <EditGroupName />
            </Route>
            <Route exact path={`${path}/verified/sireGroups/details/add-sire`}>
              <AddSireToGroup />
            </Route>
            <Route exact path={`${path}/verified/recordAnimals`}>
              <RecordAnimals />
            </Route>
            <Route exact path={`${path}/verified/recordAnimals/mob-details`}>
              <MobDetails />
            </Route>
            <Route
              exact
              path={`${path}/verified/recordAnimals/mob-details/add-animal`}>
              <AddAnimalToMob />
            </Route>
            <Route exact path={`${path}/verified/recordAnimals/edit-animal`}>
              <EditAnimal />
            </Route>
            <Route exact path={`${path}/verified/orderTags`}>
              <OrderTags />
            </Route>
            <Route exact path={`${path}/verified/animalProfile`}>
              <AnimalProfile />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/treatment`}>
              <Treatment />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/pregnancy`}>
              <Pregnancy />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/angus-verified`}>
              <AngusVerifiedDetails />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/view-animal`}>
              <ViewAnimalProfile />
            </Route>
            <Route exact path={`${path}/verified/animalProfile/view-sire`}>
              <ViewSireProfile />
            </Route>
            <Route exact path={`${path}/verified/avpresale`}>
              <AVPreSalesReport />
            </Route>
            <Route exact path={path}>
              <Home />
            </Route>
            <Route exact path={`${path}/profile/users`}>
              <UserProfile />
            </Route>
            <Route exact path={`${path}/profile/users/edit`}>
              <EditUserProfile />
            </Route>
            <Route exact path={`${path}/profile/businessProfile`}>
              <BusinessProfile />
            </Route>
            {ability.can('update', 'business') && (
              <Route exact path={`${path}/profile/businessProfile/edit`}>
                <EditBusinessProfile />
              </Route>
            )}
            <Route exact path={`${path}/profile/brand`}>
              <BrandProfile />
            </Route>
            <Route exact path={`${path}/profile/brand/archived`}>
              <ArchivedBrandLibrary />
            </Route>
            {ability.can('create', 'brand') && (
              <Route exact path={`${path}/profile/brand/new`}>
                <CreateBrand />
              </Route>
            )}
            {ability.can('update', 'brand') && (
              <Route exact path={`${path}/profile/brand/edit/:id`}>
                <EditBrand />
              </Route>
            )}
            <Route exact path={`${path}/profile/brand/view/:id`}>
              <ViewBrand />
            </Route>
            {CONSTANT.ENABLE_PLUGIN && (
              <Route exact path={`${path}/profile/plugins`}>
                <PluginActive />
              </Route>
            )}
            <Route exact path={`${path}/profile/plugins/inactive`}>
              <PluginInactive />
            </Route>
            <Route exact path={`${path}/profile/plugins/add`}>
              <PluginsLibrary />
            </Route>
            <Route exact path={`${path}/profile/plugins/view/:name`}>
              <ViewPlugin />
            </Route>
            <Route exact path={`${path}/profile/plugins/edit/:name`}>
              <EditPlugin />
            </Route>
            {ability.can('update', 'asset') && (
              <Route exact path={`${path}/activity/manage`}>
                <ManageAsset />
              </Route>
            )}
            <Route exact path={`${path}/activity/move`}>
              <MoveActivity />
            </Route>
            <Route exact path={`${path}/activity/move/create`}>
              <CreateMove />
            </Route>
            {ability.can('update', 'asset') && (
              <Route exact path={`${path}/activity/csv`}>
                <ManageAssetByCSV />
              </Route>
            )}
            <Route exact path={`${path}/activity/consignment`}>
              <ConsignmentLibrary />
            </Route>
            {ability.can('create', 'consignment') && (
              <Route exact path={`${path}/activity/consignment/new`}>
                <CreateConsignment />
              </Route>
            )}
            {ability.can('update', 'consignment') && (
              <Route exact path={`${path}/activity/consignment/edit/:docId`}>
                <EditConsignment />
              </Route>
            )}
            {ability.can('create', 'consignment') && (
              <Route
                exact
                path={`${path}/activity/consignment/duplicate/:docId`}>
                <DuplicateConsignment />
              </Route>
            )}
            <Route exact path={`${path}/activity/consignment/view/:id`}>
              <ViewConsignment />
            </Route>

            <Route exact path={`${path}/register/asset`}>
              {!isPlants ? <AssetInductionLibrary /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/register/asset/new`}>
              {!isPlants ? <AssetInduction /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/register/site`}>
              <SiteLibrary />
            </Route>
            {ability.can('create', 'site') && (
              <Route exact path={`${path}/register/site/new`}>
                <CreateSite />
              </Route>
            )}
            <Route exact path={`${path}/register/site/:agliveToken`}>
              <ViewSite />
            </Route>
            {ability.can('update', 'site') && (
              <Route exact path={`${path}/register/site/edit/:agliveToken`}>
                <EditSite />
              </Route>
            )}

            <Route exact path={`${path}/codes`}>
              <CodeMainLanding />
            </Route>
            <Route exact path={`${path}/codes/securityCodes`}>
              <SecurityCodeLibrary />
            </Route>
            <Route exact path={`${path}/codes/securityCodes/new`}>
              <GenerateSecurityCode />
            </Route>
            <Route exact path={`${path}/codes/securityCodes/used`}>
              <UsedSecurityCodes />
            </Route>
            <Route exact path={`${path}/codes/securityCodes/edit/:entryId`}>
              <EditCode />
            </Route>
            <Route exact path={`${path}/codes/generate`}>
              <CodeLibrary />
            </Route>
            <Route exact path={`${path}/codes/generate/new`}>
              <GenerateAssets />
            </Route>
            <Route exact path={`${path}/codes/generate/used`}>
              <UsedCodes />
            </Route>
            <Route exact path={`${path}/codes/promotionCodes`}>
              {isProducts ? <PromotionLibrary /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/new`}>
              {isProducts ? <CreateProductPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/edit/:id`}>
              {isProducts ? <CreateProductPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/view/:id`}>
              {isProducts ? <ViewProductPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/codes/promotionCodes/archived`}>
              {isProducts ? (
                <ArchivedPromotionLibrary />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route exact path={`${path}/products`}>
              <MainLanding actionButton />
            </Route>
            <Route exact path={`${path}/products/productProfile`}>
              {isProducts ? <ProductProfileLibrary /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/products/productProfile/new`}>
              {isProducts ? <CreateProductProfile /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/products/productProfile/view/:id`}>
              {isProducts ? <ViewProductProfile /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/products/productProfile/edit/:id`}>
              {isProducts ? <EditProductProfile /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/products/productProfile/archived`}>
              {isProducts ? (
                <ArchivedProductProfileLibrary />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route exact path={`${path}/assets/assetProfile`}>
              <AssetProfileLibrary />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/new`}>
              <CreateAssetProfile />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/view/:id`}>
              <ViewAssetProfile />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/edit/:id`}>
              <CreateAssetProfile />
            </Route>
            <Route exact path={`${path}/assets/assetProfile/archived`}>
              <ArchivedAssetProfiles />
            </Route>
            <Route exact path={`${path}/add/users`}>
              <UserLibrary />
            </Route>
            {ability.can('create', 'site') && (
              <Route exact path={`${path}/add/users/:id`}>
                <AddEditUser />
              </Route>
            )}
            <Route exact path={`${path}/add/users/view/:id`}>
              <ViewUser />
            </Route>
            <Route exact path={`${path}/add/formTemplate`}>
              <FormLibrary />
            </Route>
            {ability.can('create', 'form') && (
              <Route exact path={`${path}/add/formTemplate/new`}>
                <CreateForm />
              </Route>
            )}
            {ability.can('update', 'form') && (
              <Route exact path={`${path}/add/formTemplate/edit/:docId`}>
                <EditForm />
              </Route>
            )}
            <Route exact path={`${path}/add/formTemplate/view/:docId`}>
              <ViewForm />
            </Route>
            {ability.can('create', 'form') && (
              <Route exact path={`${path}/add/formTemplate/duplicate/:docId`}>
                <DuplicateForm />
              </Route>
            )}
            <Route exact path={`${path}/add/consignees`}>
              <ConsigneeLibrary />
            </Route>
            {ability.can('create', 'consignee') && (
              <Route exact path={`${path}/add/consignees/new`}>
                <AddConsignee />
              </Route>
            )}
            {ability.can('update', 'consignee') && (
              <Route exact path={`${path}/add/consignees/:id`}>
                <AddConsignee />
              </Route>
            )}
            <Route exact path={`${path}/add/consignees/view/:id`}>
              <ViewConsignee />
            </Route>
            <Route exact path={`${path}/profile/map`}>
              <TrackAnimal />
            </Route>
            <Route exact path={`${path}/report/otherReports`}>
              <OtherReports />
            </Route>
            <Route exact path={`${path}/report/ceresTagReport`}>
              <CeresTagReport />
            </Route>
            <Route exact path={`${path}/report/:type`}>
              <ManagementReport />
            </Route>
            <Route exact path={`${path}/healthSupport`}>
              <HealthSupport />
            </Route>
            <Route exact path={`${path}/assets`}>
              {isAnimals ? <AssetLanding /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions`}>
              {isAnimals ? <PromotionLibrary /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions/new`}>
              {isAnimals ? (
                isAngus ? (
                  <CreateAssetPromotion />
                ) : (
                  <Redirect to={`${path}/assets/promotions/`} />
                )
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            <Route exact path={`${path}/assets/promotions/edit/:id`}>
              {isAnimals ? <CreateAssetPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions/view/:id`}>
              {isAnimals ? <ViewAssetPromotion /> : <Redirect to={path} />}
            </Route>
            <Route exact path={`${path}/assets/promotions/archived`}>
              {isAnimals ? (
                <ArchivedPromotionLibrary />
              ) : (
                <Redirect to={path} />
              )}
            </Route>
            {/* Hidden path for testing */}
            <Route exact path={`${path}/dev-test/report/activities`}>
              <ActivitiesReport />
            </Route>
            <Route exact path={`${path}/add/cerestag`}>
              <AddCeresTag />
            </Route>
            <Redirect to={path} />
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, isLoggedIn, ...rest}) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(initWalletConnect(history)).catch((e) => {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Logout',
          CTAHandler: () => dispatch(appThunkLogout()),
        }),
      );
    });
  }, [dispatch, history]);

  return (
    <Route
      {...rest}
      render={() => (isLoggedIn ? children : <Redirect to="/" />)}
    />
  );
}
