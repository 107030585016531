import React, {useEffect, useReducer, useCallback, useRef} from 'react';
import {useHistory} from 'react-router';
import withHeader from '../../presentation/withHeader';
import {Box, Grid, Typography, makeStyles} from '@material-ui/core';
import {useAppDispatch} from '../../utils/hooks';
import {activatePlugin, getPlugins} from '../../store/profile/actions';
import MyButton from '../../presentation/button';
import { Line } from '../auth/styles';
import { HyperLink } from '../../presentation/word';
import COLOR from '../../styled/colors';
import DialogMessage from '../../presentation/DialogMessage';
import { PluginModel } from '@aglive/data-model';
import { toggleModal, toggleModalOff } from '../../store/modal/actions';
import { Buttons } from '../../presentation/ButtonsGroup';
import {PluginRefObj, PluginForm} from '@aglive/web-core';

const plugins: Array<PluginModel.Plugin> = []

const initialState = {
  plugins: plugins,
  showForm: false,
  selectedPlugin: {} as PluginModel.Plugin,
  credentialsState: {},
  canSubmit: false
};

type Action =
  | {type: 'set/plugins', plugins: Array<PluginModel.Plugin>}
  | {type: 'set/pluginContent', selectedPlugin: PluginModel.Plugin}
  | {type: 'show/pluginForm', showForm: boolean}
  | {type: 'update/credentialsState', credentialsState: {[key: string]: string}}
  | {type: 'set/canSubmit', canSubmit: boolean}

const reducer = (
  prevState: typeof initialState,
  action: Action,
): typeof initialState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    case 'set/plugins':
    case 'set/pluginContent':
    case 'show/pluginForm':
    case 'update/credentialsState':
    case 'set/canSubmit':
      return {...prevState, ...actionData};
  }
};

const useStyles = makeStyles((_) => ({
  primaryHeader: {
    borderBottom: '2px solid',
    borderBottomColor: COLOR.BLACK,
    paddingBottom: 15,
  },
  secondaryHeader: {
    borderBottom: '1px solid',
    borderBottomColor: COLOR.GRAY_BORDER,
    paddingBottom: 10
  },
  bold: {fontWeight: 'bold'},
  fullWidth: {width: '100%'}
}));

const PluginsLibrary: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const showPlugin = (pluginJson) => {
    localDispatch({type: 'set/pluginContent', selectedPlugin: pluginJson});
    localDispatch({type: 'show/pluginForm', showForm: true});
  }
  const pluginFormRef = useRef<PluginRefObj>(null);
  const closePluginForm = useCallback(() => {
    localDispatch({type: 'show/pluginForm', showForm: false});
  }, []);
  const showPluginForm = useCallback(() => {
    localDispatch({type: 'show/pluginForm', showForm: true});
  }, []);
  const submittable = useCallback((flag: boolean) => {
    localDispatch({type: 'set/canSubmit', canSubmit: flag});
  }, []);
  
  const confirmSubmitPlugin = () => {
    const credentials = pluginFormRef.current.getCredentials();

    localDispatch({type: "update/credentialsState", credentialsState: credentials})
    let formdetails: {uuid: string, credentials?: Object, schedule?: string} = {uuid: state.selectedPlugin.uuid, credentials: credentials ?? {}};
    closePluginForm();
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Activate Now?',
        renderButton: (
          <Buttons
            leftButtonTitle="No"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
              showPluginForm();
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              submitPluginForm(formdetails);
            }}
          />
        ),
      }),
    );
  }
  const submitPluginForm = useCallback((formdetails) => {
    dispatch(activatePlugin('activatePlugin', formdetails, () => {
      history.goBack();
    }, showPluginForm));
  }, []);

  useEffect(() => {
    dispatch(getPlugins()).then((resp: Array<PluginModel.Plugin>) => {
      localDispatch({type: 'set/plugins', plugins: resp.filter((plugin) => {
        return plugin.name && plugin.description && plugin.imageUrl
      })});
    })
  }, []);

  useEffect(() => {
    localDispatch({type: "update/credentialsState", credentialsState: {}}) // clear credentialsState when switch plugin
  }, [state.selectedPlugin])

  return (
    <>
      <Box mt={5}>
        <Grid container spacing={5}>
          {state.plugins.map((plugin) => <Grid key={`plugin-${plugin.uuid}`} item container xs={4}>
            <Box style={{border: `1px solid ${COLOR.GRAY_BORDER}`, fontSize: 0, display:'flex', flexDirection:'column', width: '100%'}}>
              <Box style={{display:'flex', flexDirection:'column',  flex: 1}}>
                <Box style={{position: 'relative', paddingTop:'66%'}}>
                  <Box style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0}}>
                    <img alt={plugin.name} src={plugin.imageUrl} width='100%' height='100%' style={{objectFit: 'contain'}} />
                  </Box>
                </Box>
                <Line mt={3} style={{width: '100%', borderBottom: 0, margin:0}} />
                <Box p={2} mt={1}>
                  <Typography variant="h3" paragraph>{plugin.name}</Typography>
                  <Typography variant="body1">{plugin.description}</Typography>
                </Box>
              </Box>
              <Box style={{display:'flex', flexWrap:'wrap', flexDirection: 'column' }}>
                <Box p={2}>
                  <HyperLink target="_blank" href={plugin.pluginRoute}>More Info</HyperLink>
                </Box>
                <Box mb={2} style={{paddingLeft: 15, paddingRight: 15}}>
                  <MyButton style={{width: '100%'}} text='Activate Now' variant='contained' onClick={() => showPlugin(plugin)} />
                </Box>
              </Box>
            </Box>
          </Grid>)}
        </Grid>
      </Box>
      <DialogMessage open={state.showForm} title="Activate Plugin" handleClose={closePluginForm} size="sm"  marginBottom={50}>
         <PluginForm
              ref={pluginFormRef}
              jsonForm={state.selectedPlugin}
              credentialsState={state.credentialsState}
              submittable={submittable}
              style={{marginTop: 30}}
            />
          <Grid container style={{position: 'absolute', bottom: 20, left: 180}}>
            <MyButton text='Connect' width={300} disabled={!state.canSubmit} variant='contained' onClick={confirmSubmitPlugin}/>
          </Grid>
      </DialogMessage>
    </>
  );
};

export default withHeader(
  {
    title: 'Add New Plugin',
    margin: 60,
    back: true,
  },
  PluginsLibrary,
);
