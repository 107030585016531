import React from 'react';
import {useHistory} from 'react-router-dom';

import withHeader from '../../../presentation/withHeader';
import PromotionLanding from '../PromotionLanding';
import SampleCattlePromotion from '../../../img/promotion/SampleCattlePromotion.svg';
import {promotionLandingContent} from '../PromotionLanding/promotionLandingContent';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import {toggleModal, toggleModalOff} from '../../../store/modal/actions';
import {Buttons} from '../../../presentation/ButtonsGroup';

const AssetLanding: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );

  return (
    <PromotionLanding
      promotionImage={SampleCattlePromotion}
      promotionLandingContent={promotionLandingContent('cattlePromotion')}
      button={{
        label: 'Create',
        onClick: () => {
          if (isAngus) {
            history.push('/private/assets/promotions/new');
          } else {
            dispatch(
              toggleModal({
                status: 'warning',
                title: 'Angus Verified Member Feature',
                subtitle:
                  'Sign up with Angus Verified to use these cattle promotion tools',
                renderButton: (
                  <Buttons
                    leftButtonTitle="Close"
                    rightButtonTitle="Join Angus Verified"
                    leftButtonOnClick={() => dispatch(toggleModalOff())}
                    rightButtonOnClick={() =>
                      window.open(
                        'https://www.angusaustralia.com.au/',
                        '_blank',
                      )
                    }
                  />
                ),
              }),
            );
          }
        },
      }}
    />
  );
};

export default withHeader(
  {
    title: 'Assets',
  },
  AssetLanding,
);
