import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  Divider,
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';

import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import COLOR from '../../styled/colors';
import {
  EditingState,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
  Column,
} from '@devexpress/dx-react-grid';
import {
  Table,
  TableHeaderRow,
  Grid as TableGrid,
  PagingPanel,
  TableEditRow,
  TableEditColumn,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {PagingState, CustomPaging} from '@devexpress/dx-react-grid';
import {Getter} from '@devexpress/dx-react-core';
import {styled} from '@mui/material/styles';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {connectProps} from '@devexpress/dx-react-core';
import {
  MultipleSelect,
  MultipleSelectOptionsType,
} from '../../presentation/MultupleSelect';
import DatePicker from '../../presentation/DatePicker';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {Buttons} from '../../presentation/ButtonsGroup';
import moment from 'moment';
import {
  addOrRemoveTag,
  fetchCeresTags,
  replaceTag,
} from '../../store/add/actions';

const INITIAL_FIELDCONDITION = {
  picId: {name: 'picId', field: 'pic_id', display: true},
  uniqueId: {name: 'uniqueId', field: 'rfid', display: true},
  visualTag: {name: 'visualTag', field: 'visual_tag', display: true},
  dateOfBirth: {
    name: 'dateOfBirth',
    field: 'date_of_birth',
    display: true,
    dateField: true,
  },
  vid: {name: 'vid', field: 'ceres_vid', display: true},
  agliveToken: {name: 'agliveToken', field: 'agliveToken', display: true},
};

const ERROR_MSG = {
  enterVid: 'Enter VID',
  inUse: 'This VID already assigned to an animal',
};

const initialState = {
  animalList: [],
  currentPage: 0,
  pageSize: 10,
  status: {},
  showFilters: false,
  editingVid: '',
  fieldCondition: INITIAL_FIELDCONDITION as {[key: string]: any}, //for filters
  picList: [],
  selectedFilters: {} as {[key: string]: Array<MultipleSelectOptionsType>},
  errorState: {
    dates: {
      status: true,
      message: '',
    },
    vid: {
      rowId: undefined,
      status: false,
      message: '',
    },
  } as {[key: string]: {status: boolean; message: string; rowId?: number}},
};

type Status = 'SAVE' | 'EDIT' | 'DEFAULT';
type InitialSelectedFilters = typeof initialState['selectedFilters'];

type Action =
  | {type: 'update/animalList'; animalList: Array<any>}
  | {type: 'change/page'; currentPage: number}
  | {type: 'change/pageSize'; pageSize: number}
  | {type: 'set/editingRowIds'; editingRowIds: Array<number>}
  | {type: 'change/showFiltes'; showFilters: boolean}
  | {
      type: 'update/status';
      status: {[key: number]: Status};
    }
  | {
      type: 'update/editingVid';
      editingVid: string;
    }
  | {
      type: 'update/fieldCondition';
      fieldCondition: {[key: string]: any};
    }
  | {type: 'set/error'; errorState: typeof initialState['errorState']}
  | {type: 'update/picList'; picList: Array<any>}
  | {type: 'change/selectedFilters'; selectedFilters: InitialSelectedFilters};

const reducer = (
  prevState: typeof initialState,
  action: Action,
): typeof initialState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    default:
      return {...prevState, ...actionData};
  }
};

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  formContainer: {
    marginTop: 50,
  },
  formTitleContainer: {
    justifyContent: 'space-between',
    marginTop: 55,
  },
  dividerStyle: {
    width: '100%',
    marginTop: 10,
  },
  cell: {
    width: '100%',
  },
  filterButton: {
    flexDirection: 'row-reverse',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  textBox: {
    background: '#FFFFFF',
    justifyContent: 'center',
  },
  icon: {
    color: COLOR.BLACK_BG,
    cursor: 'pointer',
  },
}));

const StyledHeader = styled(TableHeaderRow.Content)(({theme}) => ({
  fontWeight: 700,
}));
const StyledTable = styled(Table.Table)(({theme}) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({theme}) => ({
  backgroundColor: COLOR.WHITE,
  alignSelf: 'center',
  marginTop: 20,
}));
const StyledHeaderCell = styled(TableHeaderRow.Cell)(({theme}) => ({
  borderBottom: 'none ',
}));
const StyledEditHeader = styled(TableEditColumn.HeaderCell)(({theme}) => ({
  borderBottom: 'none ',
}));

const HeaderComponentBase = ({classes, ...props}) => (
  <Table.TableHead {...props} style={{backgroundColor: COLOR.GRAY_SOLID}} />
);

const TableComponent = (props) => <StyledTable {...props} />;
const PagingComponent = (props) => <StyledPaging {...props} />;
const HeaderComponent = (props) => <StyledHeader {...props} />;
const HeaderCellComponent = (props) => <StyledHeaderCell {...props} />;
const EditTableHeaderComponent = (props) => <StyledEditHeader {...props} />;

const columns = [
  {name: 'picId', title: 'PIC', type: 'multiSelect'},
  {name: 'uniqueId', title: 'Unique ID', type: 'text'},
  {name: 'visualTag', title: 'Visual Tag', type: 'text'},
  {name: 'dateOfBirth', title: 'Date of Birth', type: 'date'},
  {name: 'vid', title: 'VID'},
];

const AddCeresTag: React.FC = () => {
  const [state, localDispatch] = useReducer(reducer, initialState);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const locations = businessProfile.location;
  const {totalRow, cerestag} = useAppSelector(
    (state) => state.add.cerestagData,
  );
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const currentTimer = useRef<undefined | ReturnType<typeof setTimeout>>();

  const changePage = useCallback((pageNum: number) => {
    localDispatch({type: 'change/page', currentPage: pageNum});
  }, []);

  const changeShowFilter = useCallback(() => {
    localDispatch({
      type: 'change/showFiltes',
      showFilters: !state.showFilters,
    });
  }, [state.showFilters]);

  const changePageSize = useCallback((size: number) => {
    localDispatch({type: 'change/pageSize', pageSize: size});
    localDispatch({type: 'change/page', currentPage: 0});
  }, []);

  const itemPerPageArray = useCallback((totalRow: number, max: number) => {
    if (totalRow <= 10 && totalRow <= max) {
      return [10];
    } else if (totalRow <= 20 && totalRow <= max) {
      return [10, 20];
    } else if (totalRow <= 50 && totalRow <= max) {
      return [10, 20, 50];
    } else if (totalRow <= max) {
      return [10, 20, 50, 100];
    }
  }, []);

  const [rows, setRows] = useState([]);
  const [editingStateColumnExtensions] = useState([
    {columnName: 'picId', editingEnabled: false},
    {columnName: 'uniqueId', editingEnabled: false},
    {columnName: 'visualTag', editingEnabled: false},
    {columnName: 'dateOfBirth', editingEnabled: false},
    {columnName: 'vid', editingEnabled: true},
  ]);

  const [editingRowIds, setEditingRowIds] = useState([]);

  const IconCellBase = ({
    style,
    expanded,
    classes,
    onToggle,
    tableColumn,
    tableRow,
    row,
    className,
    status,
    ...restProps
  }) => {
    const handleClick = (mode: 'EDIT' | 'SAVE' | 'DELETE') => {
      if (tableRow.rowId !== state.errorState.vid.rowId) {
        setErrorState(false, '', 'vid', undefined);
      }
      setEditingRowIds([tableRow.rowId]);
      switch (mode) {
        case 'SAVE':
          if (!state.editingVid.length) {
            setErrorState(true, ERROR_MSG.enterVid, 'vid', tableRow.rowId);
            return;
          }
          saveVid(tableRow.rowId, row.vid, status);
          break;
        case 'DELETE':
          setEditingRowIds([]);
          localDispatch({
            type: 'update/status',
            status: {...status, [tableRow.rowId]: 'EDIT'},
          });
          dispatch(
            toggleModal({
              status: 'warning',
              title: 'Delete VID?',
              subtitle: 'This action cannot be undone',
              renderButton: (
                <Buttons
                  leftButtonTitle="Cancel"
                  rightButtonTitle="Delete"
                  leftButtonOnClick={() => {
                    dispatch(toggleModalOff());
                  }}
                  rightButtonOnClick={() => {
                    dispatch(toggleModalOff());
                    deleteVid(tableRow.rowId, row.vid);
                  }}
                />
              ),
            }),
          );
          break;
        case 'EDIT':
          for (const key of Object.keys(status)) {
            if (status[key] !== 'EDIT') status[key] = 'EDIT';
          }
          localDispatch({
            type: 'update/status',
            status: {...status, [tableRow.rowId]: 'SAVE'},
          });
          localDispatch({
            type: 'update/editingVid',
            editingVid: row.vid ?? '',
          });
          break;
      }
    };
    return (
      <TableCell className={className} style={style} {...restProps}>
        {status[tableRow.rowId] === 'EDIT' ? (
          <EditIcon
            className={classes.icon}
            onClick={() => handleClick('EDIT')}
          />
        ) : (
          <SaveIcon
            className={classes.icon}
            onClick={() => handleClick('SAVE')}
          />
        )}
        {tableRow.row.vid?.length > 0 &&
          !(
            state.errorState.vid.status === true &&
            state.errorState.vid.rowId === tableRow.rowId
          ) && (
            <DeleteIcon
              className={classes.icon}
              onClick={() => handleClick('DELETE')}
            />
          )}
      </TableCell>
    );
  };

  const IconCell = connectProps(IconCellBase, () => ({classes}));

  const IconEditCell = (props) => {
    return <IconCell {...props} status={state.status} />; // custom for pass in functions
  };

  const updateEditingVid = useCallback((vid: string) => {
    localDispatch({
      type: 'update/editingVid',
      editingVid: vid,
    });
  }, []);

  const EditRowCellBase = useCallback(
    ({
      style,
      status,
      classes,
      tableColumn,
      tableRow,
      row,
      className,
      ...restProps
    }) => {
      switch (status[tableRow.rowId]) {
        case 'SAVE':
          return (
            <TableCell
              key={`cell-key-edit-${tableRow.rowId}`}
              className={classes.cell}>
              <TextField
                autoFocus
                key={`cell-key-edit-textfield`}
                variant="outlined"
                type={'text'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  updateEditingVid(e.target.value);
                }}
                value={state.editingVid}
                placeholder={`Enter VID`}
                inputProps={{
                  style: {
                    textAlign: 'left',
                    height: 'inherit',
                    fontSize: '16px',
                  },
                }}
                helperText={
                  state.errorState.vid.rowId === tableRow.rowId
                    ? state.errorState.vid.message
                    : ''
                }
                error={
                  state.errorState.vid.status &&
                  state.errorState.vid.rowId === tableRow.rowId
                }
              />
            </TableCell>
          );
        default:
          return null;
      }
    },
    [
      state.editingVid,
      state.errorState.vid.message,
      state.errorState.vid.rowId,
      state.errorState.vid.status,
      updateEditingVid,
    ],
  );

  const CustomeEditRowCell = connectProps(EditRowCellBase, () => ({classes}));

  const EditRowCell = (props) => {
    const {column} = props;

    if (
      !editingStateColumnExtensions.find(
        (col) => col.columnName === column.name,
      )?.editingEnabled
    ) {
      return <Table.Cell {...props} />;
    } else {
      return <CustomeEditRowCell {...props} status={state.status} />;
    }
  };

  const commitChanges = ({added, changed}) => {
    let changedRows;
    if (added) {
      const startingAddedId =
        rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      changedRows = rows.map((row) =>
        changed[row.id] ? {...row, ...changed[row.id]} : row,
      );
    }
    setRows(changedRows);
  };

  const updateFieldConditions = useCallback(
    (
      item: string,
      value?: number | string | MultipleSelectOptionsType[],
      type?: string,
    ) => {
      localDispatch({type: 'change/page', currentPage: 0});
      localDispatch({
        type: 'update/fieldCondition',
        fieldCondition: {
          ...state.fieldCondition,
          [item]: {
            ...state.fieldCondition[item],
            filterValue: value,
            type: type,
          },
        },
      });
    },
    [state.fieldCondition],
  );

  const setErrorState = useCallback(
    (
      errorStatus: boolean,
      errorMessage: string,
      fieldName: string,
      rowId?: number,
    ) => {
      localDispatch({
        type: 'set/error',
        errorState: {
          ...state.errorState,
          [fieldName]: {
            status: errorStatus,
            message: errorMessage,
            rowId: rowId,
          },
        },
      });
    },
    [state.errorState],
  );

  const changeSelectedFilters = useCallback(
    (selectedOptions: MultipleSelectOptionsType[], columnName: string) => {
      localDispatch({
        type: 'change/selectedFilters',
        selectedFilters: {
          ...state.selectedFilters,
          [columnName]: selectedOptions,
        },
      });
    },
    [state.selectedFilters],
  );

  const handleMultiSelectChange = useCallback(
    (columnName: string, selectedData: MultipleSelectOptionsType[]) => {
      changeSelectedFilters(selectedData, columnName);
      if (
        !selectedData?.length ||
        (selectedData?.length > 1 &&
          selectedData?.length === state.picList?.length)
      ) {
        // default to all if unselect all or select all (if only one option, select all default to this one option)
        updateFieldConditions(columnName);
      } else {
        updateFieldConditions(
          columnName,
          selectedData?.map((data) =>
            typeof data === 'string' ? data : data.value,
          ),
        );
      }
    },
    [state.selectedFilters, state.fieldCondition],
  );

  //Filter according type
  const FilterCellBase = ({column, onFilter}) => {
    const columnTitle = columns.find(
      (item) => item.name === column.name,
    )?.title;

    // handle filter of number or text
    const handleTextfieldChange = useCallback(
      (
        e: React.ChangeEvent<HTMLInputElement>,
        column: Column & {type: string},
      ) => {
        clearTimeout(currentTimer?.current);
        currentTimer.current = setTimeout(() => {
          if (!e.target.value) {
            // if clear filter value should show all records
            updateFieldConditions(column.name);
          } else {
            updateFieldConditions(column.name, e.target.value, 'partial');
          }
        }, 2000);
      },
      [state.fieldCondition],
    );

    // filter according with the date range
    const handleDateRangeChange = useCallback(
      (columnName: string, date: Array<Date>) => {
        if (
          date === null ||
          !moment(date[0]).isValid() ||
          !moment(date[1]).isValid()
        ) {
          // remove filtering if date is cleared or invalid
          updateFieldConditions(columnName);
          if (
            date !== null &&
            (!moment(date[0]).isValid() || !moment(date[1]).isValid())
          ) {
            setErrorState(true, 'Invalid date', columnName);
          }
        } else {
          updateFieldConditions(columnName, [
            moment(date[0]).format('yyyy-MM-DD'),
            moment(date[1]).format('yyyy-MM-DD'),
          ]);
          setErrorState(false, '', columnName);
        }
      },
      [state.fieldCondition],
    );

    switch (column.type) {
      case 'text':
        return (
          <TableCell className={classes.cell}>
            <TextField
              key={`cell-${column.name}-${
                state.fieldCondition[column.name]?.filterValue ?? ''
              }`}
              type={column.type}
              variant="outlined"
              defaultValue={
                state.fieldCondition[column.name]?.filterValue ?? null
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextfieldChange(e, column)
              }
              placeholder={`Enter ${columnTitle}`}
              className={classes.textBox}
              inputProps={{
                style: {
                  textAlign: 'left',
                  height: 'inherit',
                  fontSize: '16px',
                },
              }}
            />
          </TableCell>
        );
      case 'date':
        return (
          <TableCell>
            <DatePicker
              label=""
              range={true}
              dateValue={state.fieldCondition[column.name]?.filterValue ?? null}
              handleChange={(date) => handleDateRangeChange(column.name, date)}
              fontSize="15px"
              withBorder={true}
              format={'dd-MM-yyyy'}
              placeholder={'dd-mm-yyyy'}
              textAlign={'left'}
              emptyLabel={''}
              errorStatus={state.errorState[column.name]?.status}
              errorMessage={state.errorState[column.name]?.message}
            />
          </TableCell>
        );
      case 'multiSelect':
        return (
          <TableCell>
            <MultipleSelect
              dataSet={state.picList}
              value={state.selectedFilters[column.name]}
              selectedData={state.selectedFilters[column.name]}
              setSelectedData={(selectedData) =>
                handleMultiSelectChange(column.name, selectedData)
              }
              variant="outlined"
              placeholder="Select"
              dropdownHeight={300}
            />
          </TableCell>
        );
      default:
        return <TableCell></TableCell>;
    }
  };
  const FilterCell = (props) => {
    const {column} = props;
    if (column.name) {
      return <FilterCellBase {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
  };

  const resetEditStatus = useCallback(
    (rowId: number, status: {[key: number]: Status}) => {
      localDispatch({
        type: 'update/editingVid',
        editingVid: '',
      });
      setEditingRowIds([]);
      localDispatch({
        type: 'update/status',
        status: {...status, [rowId]: 'EDIT'},
      });
      setErrorState(false, '', 'vid', rowId);
    },
    [setErrorState],
  );

  const saveVid = useCallback(
    (rowId: number, originalVid: string, status: {[key: number]: Status}) => {
      if (state.editingVid === originalVid) {
        resetEditStatus(rowId, status);
        return;
      }
      if (originalVid?.length > 0) {
        dispatch(
          replaceTag(
            rowId,
            cerestag[rowId].agliveToken,
            state.editingVid,
            originalVid,
          ),
        )
          .then(() => {
            resetEditStatus(rowId, status);
          })
          .catch((e) => {
            setErrorState(true, ERROR_MSG.inUse, 'vid', rowId);
          });
      } else {
        dispatch(
          addOrRemoveTag(
            rowId,
            cerestag[rowId].agliveToken,
            state.editingVid,
            'add_tag',
          ),
        )
          .then(() => {
            resetEditStatus(rowId, status);
          })
          .catch((e) => {
            setErrorState(true, ERROR_MSG.inUse, 'vid', rowId);
          });
      }
    },
    [cerestag, dispatch, resetEditStatus, setErrorState, state.editingVid],
  );

  const deleteVid = useCallback(
    (rowId: number, originalVid: string) => {
      dispatch(
        addOrRemoveTag(
          rowId,
          cerestag[rowId].agliveToken,
          originalVid,
          'remove_tag',
        ),
      );
    },
    [cerestag, dispatch],
  );

  useEffect(() => {
    if (locations) {
      localDispatch({
        type: 'update/picList',
        picList: locations.map((res) => res.PICAddress),
      });
    }
    dispatch(
      fetchCeresTags(state.currentPage, state.pageSize, state.fieldCondition),
    );
  }, [
    dispatch,
    locations,
    state.currentPage,
    state.fieldCondition,
    state.pageSize,
  ]);

  useEffect(() => {
    localDispatch({
      type: 'update/animalList',
      animalList: cerestag,
    });
  }, [cerestag, fetchCeresTags]);

  useEffect(() => {
    let newStatus = JSON.parse(JSON.stringify(state.status));
    state.animalList?.forEach((animal, index) => {
      newStatus[index] = 'EDIT';
    });
    localDispatch({
      type: 'update/status',
      status: newStatus,
    });
  }, [state.animalList]);

  // reformat the date with DD-MM-YYYY when display in table
  const DateFormatter = ({value}) =>
    value ? moment(value).format('DD-MM-YYYY') : '';

  const DateTypeProvider = (props) => (
    <DataTypeProvider
      formatterComponent={DateFormatter}
      editorComponent={DateFormatter}
      {...props}
    />
  );

  const [dateColumns] = useState(['dateOfBirth']);

  return (
    <Grid>
      <Grid container item xs={12} className={classes.formTitleContainer}>
        <Typography
          variant="h3"
          role="label"
          style={{
            color: COLOR.BLACK_BG,
          }}>
          Animals
        </Typography>
        <Divider className={classes.dividerStyle} />
      </Grid>
      <div className={classes.filterButton}>
        <Button onClick={changeShowFilter}>
          <span
            style={{
              color: COLOR.GREENT_TEXT,
              textTransform: 'capitalize',
            }}>
            <u>{!state.showFilters ? 'On Filter' : 'Hide Filter'}</u>
          </span>
        </Button>
      </div>
      <TableGrid rows={state.animalList} columns={columns}>
        <DateTypeProvider for={dateColumns} />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <EditingState
          editingRowIds={editingRowIds}
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />
        <PagingState
          currentPage={state.currentPage}
          onCurrentPageChange={changePage}
          pageSize={state.pageSize}
          onPageSizeChange={changePageSize}
        />
        <CustomPaging totalCount={totalRow} />
        <Table
          tableComponent={TableComponent}
          headComponent={HeaderComponentBase}
        />
        <TableHeaderRow
          cellComponent={HeaderCellComponent}
          contentComponent={HeaderComponent}
        />
        {state.showFilters && <TableFilterRow cellComponent={FilterCell} />}

        <TableEditRow cellComponent={EditRowCell} />
        <TableEditColumn
          headerCellComponent={EditTableHeaderComponent}
          cellComponent={IconEditCell}
        />
        <PagingPanel
          pageSizes={itemPerPageArray(totalRow, 50)}
          containerComponent={PagingComponent}
        />
        {/* change edit column to back */}
        <Getter
          name="tableColumns"
          computed={({tableColumns}) => {
            const result = [
              ...tableColumns.filter(
                (c) => c.type !== TableEditColumn.COLUMN_TYPE,
              ),
              {
                key: 'editCommand',
                type: TableEditColumn.COLUMN_TYPE,
                width: 140,
              },
            ];
            return result;
          }}
        />
      </TableGrid>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Add Ceres Tag',
  },
  AddCeresTag,
);
