import { PluginModel } from '@aglive/data-model';
import moment from 'moment';
import API from '../../config/api';
import { MultipleSelectOptionsType } from '../../presentation/MultupleSelect';


export const siteColumns = ['current_site', 'breeder_site', 'site_moved_from', 'site_moved_to'];
export const subPages = ['management', 'weightHistory', 'weightGain', 'averageDailyGain', 'movement', 'deadSoldExported', 'treatment', 'calfInformation'];
export const payloadType = {
  management: null,
  weightHistory: 'updateWeight',
  weightGain: 'updateWeight',
  averageDailyGain: 'updateWeight',
  movement: 'addMovement',
  deadSoldExported: 'updateStatus',
  treatment: 'addTreatment',
  conception: 'updatePregnancyStatus',
}

export const initialColumns = {
  ceres: [
    {name: 'ceres_tag_esn', title: 'ESN'},
    {name: 'ceres_tag_vid', title: 'VID'},
    {name: 'pic_name', title: 'Location'},
    {name: 'sex', title: 'Sex'},
    {name: 'observationDateUTC', title: 'Time'},
    {name: 'latitude', title: 'Latitude'},
    {name: 'longitude', title: 'Longitude'},
    {name: 'activityLevel', title: 'Activity Level'},
    {name: 'temperature', title: 'Temperature'},
  ],
  management: {
    management: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {name: 'visual_tag', title: 'Visual Tag', customFilter: true, type: 'text'},
      {name: 'projected_weight', title: 'Projected Weight', customFilter: false},
      {name: 'date_of_projected_weight', title: 'Date of Projected Weight', customFilter: false},
      {name: 'breed', title: 'Breed', customFilter: true, type: 'multiSelect'},
      {name: 'status', title: 'Status', customFilter: true, type: 'multiSelect'},
      {name: 'current_site', title: 'Current Site', customFilter: true, type: 'multiSelect'},
      {name: 'breeder_site', title: 'Breeder Site', customFilter: true, type: 'multiSelect'},
      {name: 'conception_status', title: 'Conception Status', customFilter: true, type: 'multiSelect'},
      {name: 'conception_method', title: 'Conception Method', customFilter: true, type: 'multiSelect'},
      {name: 'due_date', title: 'Due Date', customFilter: true, type: 'dateRange'},
      {name: 'due_season', title: 'Due Season', customFilter: true, type: 'multiSelect'},
      {name: 'birth_year', title: 'Birth Year', customFilter: true, type: 'multiSelect'},
      {name: 'birth_season', title: 'Birth Season', customFilter: true, type: 'multiSelect'},
      {name: 'property_type', title: 'Property Type', customFilter: true, type: 'multiSelect'},
      {name: 'region', title: 'Region', customFilter: true, type: 'multiSelect'},
    ],

    weightHistory: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {name: 'visual_tag', title: 'Visual Tag', customFilter: true, type: 'text'},
      {name: 'current_site', title: 'Current Site', customFilter: true, type: 'multiSelect'},
      {name: 'breeder_site', title: 'Breeder Site', customFilter: true, type: 'multiSelect'},
      {name: 'property_type', title: 'Property Type', customFilter: true, type: 'multiSelect'},
      {name: 'region', title: 'Region', customFilter: true, type: 'multiSelect'},
    ],
    weightGain: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {name: 'visual_tag', title: 'Visual Tag', customFilter: true, type: 'text'},
      {name: 'current_site', title: 'Current Site', customFilter: true, type: 'multiSelect'},
      {name: 'breeder_site', title: 'Breeder Site', customFilter: true, type: 'multiSelect'},
      {name: 'current_weight_date', title: 'Current Weight Date', customFilter: false},
      {name: 'current_weight', title: 'Current Weight', customFilter: false},
      {name: 'prior_weight_date', title: 'Prior Weight Date', customFilter: false},
      {name: 'prior_weight', title: 'Prior Weight', customFilter: false},
      {name: 'average_daily_gain', title: 'Average Daily Gain', customFilter: false},
      {name: 'total_gain', title: 'Total Gain', customFilter: false},
      {name: 'property_type', title: 'Property Type', customFilter: true, type: 'multiSelect'},
      {name: 'region', title: 'Region', customFilter: true, type: 'multiSelect'},
    ],
    averageDailyGain: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {name: 'visual_tag', title: 'Visual Tag', customFilter: true, type: 'text'},
      {name: 'current_site', title: 'Current Site', customFilter: true, type: 'multiSelect'},
      {name: 'breeder_site', title: 'Breeder Site', customFilter: true, type: 'multiSelect'},
      {name: 'birth_season', title: 'Birth Season', customFilter: true, type: 'multiSelect'},
      {name: 'current_weight', title: 'Current Weight', customFilter: false},
      {name: 'average_daily_gain', title: 'Average Daily Gain', customFilter: false},
      {name: 'days_difference', title: 'Days Difference', customFilter: false},
      {name: 'date_range', title: 'Date Range', customFilter: false},
      {name: 'property_type', title: 'Property Type', customFilter: true, type: 'multiSelect'},
      {name: 'region', title: 'Region', customFilter: true, type: 'multiSelect'},
    ],
    movement: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {name: 'visual_tag', title: 'Visual Tag', customFilter: true, type: 'text'},
      {name: 'move_date', title: 'Move Date', customFilter: true, type: 'dateRange'},
      {name: 'site_moved_from', title: 'Site Moved From', customFilter: true, type: 'multiSelect'},
      {name: 'site_moved_to', title: 'Site Moved To', customFilter: true, type: 'multiSelect'},
      {name: 'projected_weight', title: 'Projected Weight', customFilter: false},
      {name: 'date_of_projected_weight', title: 'Date of Projected Weight', customFilter: false},
      {name: 'breed', title: 'Breed', customFilter: true, type: 'multiSelect'},
      {name: 'status', title: 'Status', customFilter: true, type: 'multiSelect'},
      {name: 'breeder_site', title: 'Breeder Site', customFilter: true, type: 'multiSelect'},
      {name: 'conception_status', title: 'Conception Status', customFilter: true, type: 'multiSelect'},
      {name: 'conception_method', title: 'Conception Method', customFilter: true, type: 'multiSelect'},
      {name: 'due_date', title: 'Due Date', customFilter: true, type: 'dateRange'},
      {name: 'due_season', title: 'Due Season', customFilter: true, type: 'multiSelect'},
      {name: 'property_type', title: 'Property Type', customFilter: true, type: 'multiSelect'},
      {name: 'region', title: 'Region', customFilter: true, type: 'multiSelect'},
    ],
    deadSoldExported: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {name: 'visual_tag', title: 'Visual Tag', customFilter: true, type: 'text'},
      {name: 'projected_weight', title: 'Projected Weight', customFilter: false},
      {name: 'date_of_projected_weight', title: 'Date of Projected Weight', customFilter: false},
      {name: 'breed', title: 'Breed', customFilter: true, type: 'multiSelect'},
      {name: 'status', title: 'Status', customFilter: true, type: 'multiSelect'},
      {name: 'current_site', title: 'Current Site', customFilter: true, type: 'multiSelect'},
      {name: 'breeder_site', title: 'Breeder Site', customFilter: true, type: 'multiSelect'},
      {name: 'conception_status', title: 'Conception Status', customFilter: true, type: 'multiSelect'},
      {name: 'conception_method', title: 'Conception Method', customFilter: true, type: 'multiSelect'},
      {name: 'due_date', title: 'Due Date', customFilter: true, type: 'dateRange'},
      {name: 'due_season', title: 'Due Season', customFilter: true, type: 'multiSelect'},
      {name: 'reason', title: 'Reason', customFilter: true, type: 'text'},
      {name: 'sold_to', title: 'Sold To', customFilter: true, type: 'text'},
      {name: 'status_date', title: 'Status Date', customFilter: true, type: 'dateRange'},
      {name: 'property_type', title: 'Property Type', customFilter: true, type: 'multiSelect'},
      {name: 'region', title: 'Region', customFilter: true, type: 'multiSelect'},
    ],
    treatment: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {
        name: 'visual_tag',
        title: 'Visual Tag',
        customFilter: true,
        type: 'text',
      },
      {
        name: 'medication_name',
        title: 'Medication Name',
        customFilter: true,
        type: 'multiSelect',
        numberDisplayed: 1,
      },
      {
        name: 'treatment_date',
        title: 'Treatment Date',
        customFilter: false,
        type: 'dateRange',
      },
      {name: 'batch_number', title: 'Batch Number', customFilter: false},
      {
        name: 'batch_expiry_date',
        title: 'Batch Expiry Date',
        customFilter: true,
        type: 'dateRange',
      },
      {name: 'dose', title: 'Dose', customFilter: false},
      {name: 'dose_unit', title: 'Dose Unit', customFilter: false},
      {
        name: 'treated_by',
        title: 'Treated By',
        customFilter: true,
        type: 'text',
      },
      {
        name: 'reason_for_treatment',
        title: 'Reason for Treatment',
        customFilter: false,
      },
      {
        name: 'breeder_site',
        title: 'Breeder Site',
        customFilter: true,
        type: 'multiSelect',
      },
      {
        name: 'current_site',
        title: 'Current Site',
        customFilter: true,
        type: 'multiSelect',
      },
      {
        name: 'conception_status',
        title: 'Conception Status',
        customFilter: true,
        type: 'multiSelect',
      },
      {
        name: 'birth_season',
        title: 'Birth Season',
        customFilter: true,
        type: 'multiSelect',
      },
      {
        name: 'birth_year',
        title: 'Birth Year',
        customFilter: true,
        type: 'multiSelect',
      },
      {
        name: 'status',
        title: 'Status',
        customFilter: true,
        type: 'multiSelect',
      },
      {
        name: 'property_type', 
        title: 'Property Type', 
        customFilter: true, 
        type: 'multiSelect'
      },
      {
        name: 'region', 
        title: 'Region', 
        customFilter: true, 
        type: 'multiSelect'
      },
    ],
    calfInformation: [
      {name: 'rfid', title: 'RFID', customFilter: true, type: 'text'},
      {
        name: 'visual_tag',
        title: 'Visual Tag',
        customFilter: true,
        type: 'text',
      },
      {
        name: 'date_of_birth',
        title: 'Date of Birth',
        customFilter: true,
        type: 'dateRange',
      },
      {name: 'genetic_sire', title: 'Genetic Sire', customFilter: false, type: 'text'},
      {name: 'genetic_dam', title: 'Genetic Dam', customFilter: false, type: 'text'},
      {name: 'breeder_site', title: 'Breeder Site', customFilter: true, type: 'multiSelect'},
      {name: 'current_site', title: 'Current Site', customFilter: true, type: 'multiSelect'},
      {
        name: 'property_type', 
        title: 'Property Type', 
        customFilter: true, 
        type: 'multiSelect'
      },
      {
        name: 'region', 
        title: 'Region', 
        customFilter: true, 
        type: 'multiSelect'
      },
    ]
  },
};

export type FilteringStateColumn = {columnName: string, filteringEnabled: boolean}
export type SortingStateColumn = {columnName: string, sortingEnabled: boolean}
const disabledFilterColumnExtensions = {
  management: {
    management: [
      {columnName: 'projected_weight', filteringEnabled: false},
      {columnName: 'date_of_projected_weight', filteringEnabled: false},
    ],
    weightHistory: [],
    weightGain: [
      {columnName: 'current_weight_date', filteringEnabled: false},
      {columnName: 'current_weight', filteringEnabled: false},
      {columnName: 'prior_weight_date', filteringEnabled: false},
      {columnName: 'prior_weight', filteringEnabled: false},
      {columnName: 'average_daily_gain', filteringEnabled: false},
      {columnName: 'total_gain', filteringEnabled: false},
    ],
    averageDailyGain: [
      {columnName: 'average_daily_gain', filteringEnabled: false},
      {columnName: 'current_weight', filteringEnabled: false},
      {columnName: 'days_difference', filteringEnabled: false},
      {columnName: 'date_range', filteringEnabled: false},
    ],
    movement: [
      {columnName: 'projected_weight', filteringEnabled: false},
      {columnName: 'date_of_projected_weight', filteringEnabled: false},
    ],
    deadSoldExported: [
      {columnName: 'projected_weight', filteringEnabled: false},
      {columnName: 'date_of_projected_weight', filteringEnabled: false},
    ],
    treatment: [
      {columnName: 'treatment_date', filteringEnabled: false},
      {columnName: 'batch_number', filteringEnabled: false},
      {columnName: 'batch_expiry_date', filteringEnabled: false},
      {columnName: 'dose', filteringEnabled: false},
      {columnName: 'dose_unit', filteringEnabled: false},
      {columnName: 'treated_by', filteringEnabled: false},
      {columnName: 'reason_for_treatment', filteringEnabled: false},
    ],
    calfInformation: [
      {columnName: 'genetic_sire', filteringEnabled: false},
      {columnName: 'genetic_dam', filteringEnabled: false},
    ],
  }
}

const disabledSortingColumnExtensions = {
  management: {
    management: [
      {columnName: 'projected_weight', sortingEnabled: false},
      {columnName: 'date_of_projected_weight', sortingEnabled: false},
    ],
    weightHistory: initialColumns.management.weightHistory.map((column) => ({columnName: column.name, sortingEnabled: false})),
    weightGain: initialColumns.management.weightGain.map((column) => ({columnName: column.name, sortingEnabled: false})),
    averageDailyGain: initialColumns.management.averageDailyGain.map((column) => ({columnName: column.name, sortingEnabled: false})),
    movement: initialColumns.management.movement.map((column) => ({columnName: column.name, sortingEnabled: false})),
    deadSoldExported: [
      {columnName: 'projected_weight', sortingEnabled: false},
      {columnName: 'date_of_projected_weight', sortingEnabled: false},
    ],
    treatment: initialColumns.management.treatment.map((column) => ({
      columnName: column.name,
      sortingEnabled: false,
    })),
    calfInformation: initialColumns.management.calfInformation.map((column) => ({
      columnName: column.name,
      sortingEnabled: false,
    })),
  }
}

type TableAlignType = 'left' | 'center' | 'right';

const defaultRowFields: {
  ceres: Record<string, any>;
  management: Record<typeof subPages[number], any>;
} = {
  ceres: {
    ceres_tag_esn: '',
    ceres_tag_vid: '',
    pic_name: '',
    sex: '',
    observationDateUTC: '',
    latitude: 0,
    longitude: 0,
    activityLevel: 0,
    temperature: 0,
  },
  management: {
    management: {
      rfid: '',
      visual_tag: '',
      projected_weight: '' as number | string,
      date_of_projected_weight: '',
      breed: '',
      status: '',
      current_site: '',
      breeder_site: '',
      conception_status: '',
      conception_method: '',
      due_date: '',
      due_season: '',
      birth_year: '',
      birth_season: '',
      property_type: '',
      region: '',
    },
    weightHistory: {
      rfid: '',
      visual_tag: '',
      current_site: '',
      breeder_site: '',
      property_type: '',
      region: '',
    },
    weightGain: {
      rfid: '',
      visual_tag: '',
      current_site: '',
      breeder_site: '',
      current_weight_date: '',
      current_weight: '',
      prior_weight_date: '',
      prior_weight: '',
      average_daily_gain: '',
      total_gain: '',
      property_type: '',
      region: '',
    },
    averageDailyGain: {
      rfid: '',
      visual_tag: '',
      current_site: '',
      breeder_site: '',
      birth_season: '',
      current_weight: '',
      average_daily_gain: '',
      days_difference: '',
      date_range: '',
      property_type: '',
      region: '',
    },
    movement: {
      rfid: '',
      visual_tag: '',
      move_date: '',
      site_moved_from: '',
      site_moved_to: '',
      projected_weight: '' as number | string,
      date_of_projected_weight: '',
      breed: '',
      status: '',
      breeder_site: '',
      conception_status: '',
      conception_method: '',
      due_date: '',
      due_season: '',
      property_type: '',
      region: '',
    },
    deadSoldExported: {
      rfid: '',
      visual_tag: '',
      projected_weight: '' as number | string,
      date_of_projected_weight: '',
      breed: '',
      status: '',
      current_site: '',
      breeder_site: '',
      conception_status: '',
      conception_method: '',
      due_date: '',
      due_season: '',
      reason: '',
      sold_to: '',
      status_date: '',
      property_type: '',
      region: '',
    },
    treatment: {
      rfid: '',
      visual_tag: '',
      medication_name: '',
      treatment_date: '',
      batch_number: '',
      batch_expiry_date: '',
      dose: '',
      dose_unit: '',
      treated_by: '',
      reason_for_treatment: '',
      breeder_site: '',
      current_site: '',
      conception_status: '',
      birth_season: '',
      birth_year: '',
      status: '',
      property_type: '',
      region: '',
    },
    calfInformation: {
      rfid: '',
      visual_tag: '',
      date_of_birth: '',
      genetic_sire: '',
      genetic_dam: '',
      current_site: '',
      breeder_site: '',
      property_type: '',
      region: '',
    },
  },
};
export type RowFields = typeof defaultRowFields;

const weightUnits = ['LBS', 'KG'];
export type WeightUnits = typeof weightUnits;
export const sex = ['Male', 'Female'];
export const sexList = sex.map((sex) => ({label: sex, value: sex}))
type sexListType = typeof sexList;
const species = ['Beef', 'Goat', 'Cattle'];
const speciesType = ['Angus', 'Meat', 'Dairy Breeds', 'Beef'];
const breedsList = [
  'angus',
  'Holstein / Friesian',
  'Anvus',
  'Billy',
  'Angus',
  'Friesian - Jersey',
  'Avv',
  'Bos Taurus',
  'Unknown',
  'Friesian',
  'Holstein',
  'JERSEY',
  'Holstein / Jersey',
];
const statusList = ['Dead', 'Sold', 'Exported'];
const birthSeasonList = ['Spring', 'Autumn'];
export type Species = typeof species;
export type SpeciesType = typeof speciesType;
export type BreedsList = typeof breedsList;
export type StatusList = typeof statusList;
export type BirthSeasonList = typeof birthSeasonList;
export interface FormMapT {
  key?: string;
  label: string;
  value: string;
  subfields?: Array<Subfield>;
  outerFilterFields?: Array<OuterFilterField>;
  hide?: boolean;
}
export type Subfield = {
  label: string;
  sublabel?: string;
  sublabel2?: string;
  key: string;
  key2?: string;
  type: 'number' | 'date' | 'select' | 'multiselect' | 'text';
  required: boolean;
  options?: Array<string>;
};

export type OuterFilterField = {
  label?: string;
  // sublabel?: string;
  // sublabel2?: string;
  key: string;
  key2?: string;
  type: 'number' | 'text' | 'dateRange' | 'multiselect'  | 'date';
  variant?: 'standard' | 'outlined';
  required: boolean;
  inputProps?: {min: number, step: number};
  diablePast?: boolean;
  options?: Array<{label: string, value: string}>; // for multiselect options
  customUpdate?: boolean; // update inner filter when outer filter changes, e.g. site_moved_to
  customOther?: boolean;
};

export type Lists = {
  unit?: WeightUnits;
  sex?: typeof sex;
  species?: Species;
  species_type?: SpeciesType;
  breed?: BreedsList;
  breed_type?: Array<string>;
};

 // // TODO: may add Subfield for plant
// const forecastDailyGain = (req: boolean): Subfield => ({
//   label: 'Forecasted Daily Gain',
//   key: 'forecast_daily_gain',
//   type: 'number',
//   required: req,
// });
// const forecastUnit = (req: boolean, list?: WeightUnits): Subfield => ({
//   label: 'Forecast Unit',
//   key: 'forecast_unit',
//   type: 'multiselect',
//   options: list ?? weightUnits,
//   required: req,
// });
// const forecastDate = (req: boolean): Subfield => ({
//   label: 'Forecast Date',
//   key: 'forecast_date',
//   type: 'date', // YYYY-MM-DD
//   required: req,
// });
// const speciesField = (req: boolean, list?: Species): Subfield => ({
//   label: 'Species',
//   key: 'species',
//   type: 'multiselect',
//   options: list ?? species,
//   required: req,
// });
// const speciesTypeField = (req: boolean, list?: BreedsList): Subfield => ({
//   label: 'Species Types',
//   key: 'species_type',
//   type: 'multiselect',
//   options: list ?? speciesType,
//   required: req,
// });
// const breedField = (req: boolean, list?: BreedsList): Subfield => ({
//   label: 'Breed',
//   key: 'breed',
//   type: 'multiselect',
//   options: list ?? breedsList,
//   required: req,
// });

// ceres
const startDate = (): OuterFilterField => ({
  key: 'start_date',
  key2: 'dates',
  label: 'Start Date',
  type: 'date',
  required: true,
});

const endDate = (): OuterFilterField => ({
  key: 'end_date',
  key2: 'dates',
  label: 'End Date',
  type: 'date',
  required: true,
});

const locations = (): OuterFilterField => ({
  key: 'locations',
  key2: 'locationPicAddr',
  type: 'multiselect',
  required: true,
  customOther: true,
});

// managment
const projectedWeightDate = (): OuterFilterField => ({
  key: 'projectedWeightDate',
  key2: 'projectedWeightDate',
  label: 'Date of Projected Weight',
  type: 'date',
  required: true,
});

const averageDailyGain = (): OuterFilterField => ({
  key: 'averageDailyGain',
  label: 'Average Daily Gain',
  type: 'number',
  required: true,
  inputProps: {min: 0, step: 0.1},
});

const sexField = (): OuterFilterField => ({
  key: 'selectedSex',
  label: 'Sex',
  type: 'multiselect',
  required: true,
  options: sexList,
});

// weightHistory
const numberOfMonths = (): OuterFilterField => ({
  key: 'numberOfMonths',
  label: 'Number of Months',
  type: 'number',
  required: true,
  inputProps: {min: 1, step: 1},
});

// averageDailyGain
const weightDateRangeFrom = (): OuterFilterField => ({
  key: 'start_date',
  key2: 'dates',
  label: 'Weight Date Range (From)',
  type: 'date',
  required: true,
});

const weightDateRangeTo = (): OuterFilterField => ({
  key: 'end_date',
  key2: 'dates',
  label: 'Weight Date Range (To)',
  type: 'date',
  required: true,
});

// movement
const siteMovedTo = (): OuterFilterField => ({
  key: 'site_moved_to',
  key2: 'sites',
  label: 'Site Moved To',
  type: 'multiselect',
  required: true,
  customUpdate: true,
});

// treatment
const treatmentDateRangeFrom = (): OuterFilterField => ({
  key: 'start_date',
  key2: 'dates',
  label: 'Treatment Date Range (From)',
  type: 'date',
  required: true,
});

const treatmentDateRangeTo = (): OuterFilterField => ({
  key: 'end_date',
  key2: 'dates',
  label: 'Treatment Date Range (To)',
  type: 'date',
  required: true,
});

export const getFormMap = (page: string, isPlants: boolean, profileOverride?: Array<PluginModel.PluginReport>, includeDefaults?: boolean) => {
  if (page === 'ceres') {
    return [
      {
        label: 'Ceres Tag Report',
        key: 'ceres',
        value: API.POST.getFilteredToken,
        outerFilterFields: [
          startDate(),
          endDate(),
          locations(),
        ]
      },
    ];
  } else if (page === 'management') {
    let list = [
      {
        label: 'Management Report',
        key: 'management',
        value: API.POST.getFilteredToken,
        outerFilterFields: [
          projectedWeightDate(),
          averageDailyGain(),
          sexField(),
        ]
      },
      {
        label: 'Weight History Report',
        key: 'weightHistory',
        value: API.POST.getFilteredToken,
        outerFilterFields: [
          numberOfMonths(),
        ]
      },
      {
        label: 'Weight Gain Report',
        key: 'weightGain',
        value: API.POST.getFilteredToken,
      },
      {
        label: 'Average Daily Gain Report',
        key: 'averageDailyGain',
        value: API.POST.getFilteredToken,
        outerFilterFields: [
          weightDateRangeFrom(),
          weightDateRangeTo(),
        ]
      },
      {
        label: 'Movement Report',
        key: 'movement',
        value: API.POST.getFilteredToken,
        outerFilterFields: [
          siteMovedTo(),
        ]
      },
      {
        label: 'Dead or Sold or Exported Report',
        key: 'deadSoldExported',
        value: API.POST.getFilteredToken,
      },
      {
        label: 'Treatment Report',
        key: 'treatment',
        value: API.POST.getFilteredToken,
        outerFilterFields: [
          treatmentDateRangeFrom(),
          treatmentDateRangeTo(),
        ]
      },
      {
        label: 'Calf Information Report',
        key: 'calfInformation',
        value: API.POST.getFilteredToken,
      },
    ];
    if (profileOverride?.length) {
      const add = profileOverride.map((pf) => {
        return {
          label: pf.type as string,
          key: pf.type.replace(/\s+/g, '_').toLowerCase(),
          value: API.POST.getFilteredToken,
          outerFilterFields: pf.outerFilters,
          hide: true
        }
      });
      if (includeDefaults) {
        list = [...list, ...add];
      } else {
        return add;
      }
    }
    return list;
  }
  // // TODO: may add reports for plant
  // const initialFormMap: Array<FormMapT> = [
  //   {
  //     label: 'Transfer Report',
  //     value: API.GET.getTransferReport,
  //   },
  //   {
  //     label: 'Treatment Report',
  //     value: API.GET.getTreatmentReport,
  //   },
  // ];

  // if (isPlants) {
  //   initialFormMap.push(
  //     {
  //       label: 'Movement Report',
  //       value: '',
  //     },
  //     {
  //       label: 'Inventory per Location Report',
  //       value: '',
  //     },
  //   );
  // } 

  // return initialFormMap.sort((a, b) => {
  //   if (a.label < b.label) return -1;
  //   if (a.label > b.label) return 1;
  //   return 0;
  // });
};

type FieldConditionMetadata = {
  name: string;
  field?: string;
  display?: boolean;
  dateField?: boolean;
  source?: string;
  getList?: boolean;
  getListFromActivity?: boolean;
  filterValue?: Array<string>;
};

// getList - if get options (for multiselect) in response for this column, defualt to false
const initialFieldConditions: {
  ceres: Record<string, FieldConditionMetadata>;
  management: Record<
    typeof subPages[number],
    Record<string, FieldConditionMetadata>
  >;
} = {
  ceres: {
    esn: {name: "ceres_tag_esn", field: "ceres_esn", display: true},
    vid: {name: "ceres_tag_vid", field: "ceres_vid", display: true},
    location: {name: "pic_name", display: true},
    pic: {name: "pic_id"},
    sex: {name: "Sex", display: true},
    time: {name: "observationDateUTC", field: "observationDateUTC", display: true, dateField: true, source: "conditions"},
    latitude: {name: "Latitude", display: true, source: "conditions"},
    longitude: {name: "Longitude", display: true, source: "conditions"},
    activity_level: {name: "activityLevel", display: true, field: "activityLevel", source: "conditions"},
    temperature: {name: "Temperature", display: true, source: "conditions"},
  },
  management: {
    management: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      projected_weight: {name: 'Projected Weight',display: true},
      date_of_projected_weight: {name: 'Date of Projected Weight', display: true},
      breed: {name: 'Breed', display: true, getList: true}, 
      status: {name: 'Status', display: true, getList: true},
      current_site: {name: 'Current Site', display: true},
      breeder_site: {name: 'Breeder Site', display: true},
      conception_status: {name: 'Conception Status', display: true, getList: true},
      conception_method: {name: 'Conception Method', display: true, getList: true},
      due_date: {name: 'Due Date', display: true},
      due_season: {name: 'Due Season', display: true, getList: true},
      sex: {name: 'Sex', display: false, filterValue: ['Female']},
      birth_year: {name: 'Birth Year', display: true, getList: true},
      birth_season: {name: 'Birth Season', display: true, getList: true},
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},
    },
    weightHistory: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      current_site: {name: 'Current Site', display: true},
      breeder_site: {name: 'Breeder Site', display: true},
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},   
    },
    weightGain: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      current_site: {name: 'Current Site', display: true},
      breeder_site: {name: 'Breeder Site', display: true},
      current_weight_date: {name: 'Current Weight Date', display: true}, 
      current_weight: {name: 'Current Weight', display: true}, 
      prior_weight_date: {name: 'Prior Weight Date', display: true}, 
      prior_weight: {name: 'Prior Weight', display: true}, 
      average_daily_gain: {name: 'Average Daily Gain', display: true}, 
      total_gain: {name: 'Total Gain', display: true}, 
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},
    },
    averageDailyGain: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      current_site: {name: 'Current Site', display: true},
      breeder_site: {name: 'Breeder Site', display: true},
      birth_season: {name: 'Birth Season', display: true, getList: true},
      current_weight: {name: 'Current Weight', display: true}, 
      average_daily_gain: {name: 'Average Daily Gain', display: true}, 
      days_difference: {name: 'Days Difference', display: true}, 
      date_range: {name: 'Date Range', display: true}, 
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},
    },
    movement: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      move_date: {name: 'Move Date', display: true, source: 'activities'},
      site_moved_from: {name: 'Site Moved From', display: true, source: 'activities'},
      site_moved_to: {name: 'Site Moved To', display: true, source: 'activities'},
      projected_weight: {name: 'Projected Weight',display: true},
      date_of_projected_weight: {name: 'Date of Projected Weight', display: true},
      breed: {name: 'Breed', display: true, getList: true},
      status: {name: 'Status', display: true, getList: true},
      breeder_site: {name: 'Breeder Site', display: true},
      conception_status: {name: 'Conception Status', display: true, getList: true},
      conception_method: {name: 'Conception Method', display: true, getList: true},
      due_date: {name: 'Due Date', display: true},
      due_season: {name: 'Due Season', display: true, getList: true},
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},
    },
    deadSoldExported: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      projected_weight: {name: 'Projected Weight',display: true},
      date_of_projected_weight: {name: 'Date of Projected Weight', display: true},
      breed: {name: 'Breed', display: true, getList: true},
      status: {name: 'Status', display: true, getList: true},
      current_site: {name: 'Current Site', display: true},
      breeder_site: {name: 'Breeder Site', display: true},
      conception_status: {name: 'Conception Status', display: true, getList: true},
      conception_method: {name: 'Conception Method', display: true, getList: true},
      due_date: {name: 'Due Date', display: true},
      due_season: {name: 'Due Season', display: true, getList: true},
      reason: {name: 'Reason', display: true, source: 'activities'},
      sold_to: {name: 'Sold To', display: true, source: 'activities'},
      status_date: {name: 'Status Date', display: true, source: 'activities'},
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},
    },
    treatment: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      medication_name: {name: 'Medication Name', display: true, getListFromActivity: true, source: 'activities'},
      treatment_date: {name: 'Treatment Date', display: true, source: 'activities'},
      batch_number: {name: 'Batch Number', display: true, source: 'activities'},
      batch_expiry_date: {name: 'Batch Expiry Date', display: true, source: 'activities'},
      dose: {name: 'Dose', display: true, source: 'activities'},
      dose_unit: {name: 'Dose Unit', display: true, source: 'activities'},
      treated_by: {name: 'Treated By', display: true, source: 'activities'},
      reason_for_treatment: {name: 'Reason for Treatment', display: true, source: 'activities'},
      breeder_site: {name: 'Breeder Site', display: true},
      current_site: {name: 'Current Site', display: true},
      conception_status: {
        name: 'Conception Status',
        display: true,
        getList: true,
      },
      birth_season: {name: 'Birth Season', display: true, getList: true},
      birth_year: {name: 'Birth Year', display: true, getList: true},
      status: {name: 'Status', display: true, getList: true},
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},
    },
    calfInformation: {
      rfid: {name: 'RFID', display: true},
      visual_tag: {name: 'Visual Tag', display: true},
      date_of_birth: {name: 'Date of Birth', display: true},
      genetic_sire: {name: 'Genetic Sire', display: true},
      genetic_dam: {name: 'Genetic Dam', display: true},
      current_site: {name: 'Current Site', display: true},
      breeder_site: {name: 'Breeder Site', display: true},
      property_type: {name: 'Property Type', display: true, getList: true},
      region: {name: 'Region', display: true, getList: true},
    },
  },
};

const initialOuterFilter: {
  ceres: Record<string, any>;
  management: Record<typeof subPages[number], any>;
} = {
  ceres: {
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    locations: [],
  },
  management: {
    management: {
      projectedWeightDate: moment().format('YYYY-MM-DD'),
      averageDailyGain: 0.7,
      selectedSex: [{label: 'Female', value: 'Female'}] as Array<MultipleSelectOptionsType>,
    },
    weightHistory: {
      numberOfMonths: 24,
    },
    weightGain: {
    },
    averageDailyGain: {
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    movement: {
      site_moved_to: [] as Array<MultipleSelectOptionsType>, // movement -> site_moved_to
    },
    deadSoldExported: {},
    treatment: {
      start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    calfInformation: {},
  },
};

const initialSelectedFilters: {
  management: Record<typeof subPages[number], any>;
} = {
  management: {
    management: {
      breed: [] as Array<MultipleSelectOptionsType>,
      conception_method: [] as Array<MultipleSelectOptionsType>,
      conception_status: [] as Array<MultipleSelectOptionsType>,
      due_season: [] as Array<MultipleSelectOptionsType>,
      status: [] as Array<MultipleSelectOptionsType>,
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      current_site: [] as Array<MultipleSelectOptionsType>,
      birth_year: [] as Array<MultipleSelectOptionsType>,
      birth_season: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
    weightHistory: {
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      current_site: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
    weightGain: {
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      current_site: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
    averageDailyGain: {
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      current_site: [] as Array<MultipleSelectOptionsType>,
      birth_season: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
    movement: {
      site_moved_from: [] as Array<MultipleSelectOptionsType>,
      site_moved_to: [] as Array<MultipleSelectOptionsType>,
      breed: [] as Array<MultipleSelectOptionsType>,
      conception_method: [] as Array<MultipleSelectOptionsType>,
      conception_status: [] as Array<MultipleSelectOptionsType>,
      due_season: [] as Array<MultipleSelectOptionsType>,
      status: [] as Array<MultipleSelectOptionsType>,
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
    deadSoldExported: {
      breed: [] as Array<MultipleSelectOptionsType>,
      conception_method: [] as Array<MultipleSelectOptionsType>,
      conception_status: [] as Array<MultipleSelectOptionsType>,
      due_season: [] as Array<MultipleSelectOptionsType>,
      status: [] as Array<MultipleSelectOptionsType>,
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      current_site: [] as Array<MultipleSelectOptionsType>,
      reason: [] as Array<MultipleSelectOptionsType>,
      sold_to: [] as Array<MultipleSelectOptionsType>,
      status_date: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
    treatment: {
      medication_name: [] as Array<MultipleSelectOptionsType>,
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      current_site: [] as Array<MultipleSelectOptionsType>,
      conception_status: [] as Array<MultipleSelectOptionsType>,
      birth_season: [] as Array<MultipleSelectOptionsType>,
      birth_year: [] as Array<MultipleSelectOptionsType>,
      status: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
    calfInformation: {
      breeder_site: [] as Array<MultipleSelectOptionsType>,
      current_site: [] as Array<MultipleSelectOptionsType>,
      property_type: [] as Array<MultipleSelectOptionsType>,
      region: [] as Array<MultipleSelectOptionsType>,
    },
  },
};

type ColumnStyleMetadata = Array<{
  columnName: string;
  width?: string; // accept % or px
  align?: TableAlignType;
}>;

const initialColumnExtensions: {
  ceres: ColumnStyleMetadata;
  management: Record<typeof subPages[number], ColumnStyleMetadata>;
} = {
  ceres: [
    {columnName: 'ceres_tag_vid', width: '170px'},
    {columnName: 'observationDateUTC', width: '250px'},
    {columnName: 'latitude', width: '200px'},
    {columnName: 'longitude', width: '200px'},
    {columnName: 'activityLevel', align: 'center' as TableAlignType},
    {columnName: 'temperature', align: 'center' as TableAlignType},
  ],
  management: {
    management: [
      {columnName: 'rfid', width: '20%', align: 'center' as TableAlignType},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'projected_weight',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'date_of_projected_weight',
        width: '22%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breed',
        width: '15%',
        align: 'center' as TableAlignType,

      },
      {
        columnName: 'status',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'conception_status',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'conception_method',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'due_date',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'due_season',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'birth_year',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'birth_season',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      },
    ],
    weightHistory: [
      {columnName: 'rfid', width: '20%', align: 'center' as TableAlignType},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      },
    ],
    weightGain: [
      {columnName: 'rfid', width: '20%', align: 'center' as TableAlignType},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_weight_date',
        width: '25%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_weight',
        width: '20%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'prior_weight_date',
        width: '20%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'prior_weight',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'average_daily_gain',
        width: '22%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'total_gain',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      },
    ],
    averageDailyGain: [
      {columnName: 'rfid', width: '20%', align: 'center' as TableAlignType},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'birth_season',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_weight',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'average_daily_gain',
        width: '22%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'days_difference',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'date_range',
        width: '28%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      },
    ],
    movement: [
      {columnName: 'rfid', width: '20%', align: 'center' as TableAlignType},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'move_date',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'site_moved_from',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'site_moved_to',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'projected_weight',
        width: '20%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'date_of_projected_weight',
        width: '22%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breed',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'status',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'conception_status',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'conception_method',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'due_date',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'due_season',
        width: '12%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      },
    ],
    deadSoldExported: [
      {columnName: 'rfid', width: '20%', align: 'center' as TableAlignType},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'projected_weight',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'date_of_projected_weight',
        width: '20%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breed',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'status',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'current_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'conception_status',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'conception_method',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'due_date',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'due_season',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'reason',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'sold_to',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'status_date',
        width: '18%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      },
    ],
    treatment: [
      {columnName: 'rfid', width: '20%', align: 'center'},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center',
      },
      {
        columnName: 'medication_name',
        width: '22%',
        align: 'center',
      },
      {
        columnName: 'treatment_date',
        width: '20%',
        align: 'center',
      },
      {
        columnName: 'batch_number',
        width: '18%',
        align: 'center',
      },
      {
        columnName: 'batch_expiry_date',
        width: '20%',
        align: 'center',
      },
      {
        columnName: 'dose',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'dose_unit',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'treated_by',
        width: '20%',
        align: 'center',
      },
      {
        columnName: 'reason_for_treatment',
        width: '22%',
        align: 'center',
      },
      {
        columnName: 'current_site',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'conception_status',
        width: '18%',
        align: 'center',
      },
      {
        columnName: 'birth_season',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'birth_year',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'status',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      },
    ],
    calfInformation: [
      {columnName: 'rfid', width: '20%', align: 'center'},
      {
        columnName: 'visual_tag',
        width: '18%',
        align: 'center',
      },
      {
        columnName: 'date_of_birth',
        width: '18%',
        align: 'center',
      },
      {
        columnName: 'genetic_sire',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'genetic_dam',
        width: '15%',
        align: 'center',
      },
      {
        columnName: 'current_site',
        width: '20%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'breeder_site',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'property_type',
        width: '15%',
        align: 'center' as TableAlignType,
      },
      {
        columnName: 'region',
        width: '20%',
        align: 'center' as TableAlignType,
      }
    ],
  },
};

const convertFieldNames = (nm: string, colName?: string) => {
  return colName ? colName : nm.replace(/\W/g, '_').toLowerCase();
}

export const initialFieldsGenerator = (reports?: Array<PluginModel.PluginReport>, includeDefaults?: boolean) => {
  let list = {
    initialColumns,
    initialColumnExtensions,
    initialFieldConditions,
    initialOuterFilter,
    initialSelectedFilters,
    defaultRowFields,
    disabledFilterColumnExtensions,
    disabledSortingColumnExtensions,
    payloadType
  };
  if (reports?.length) {
    const management = {
      columns: {},
      columnExt: {},
      fieldCond: {},
      outerFilters: {},
      selectedFilters: {},
      defaultRowFields: {},
      activityType: {},
      disabledFilters: {},
      disabledSorting: {}
    };
    reports.forEach((rpt) => {
      management['columns'][rpt.type] = rpt.columns.map((col) => {
        return {
          name: convertFieldNames(col.name, col.column),
          title: col.name,
          customFilter: !col['disableInnerFilter'],
          type: col.type ?? (col.getList ? 'multiSelect' : (col.name.toLowerCase().includes('date') ? 'dateRange' : 'text'))
        }
      })
      management['columnExt'][rpt.type] = rpt.columns.map((col) => {
        return {
          columnName: convertFieldNames(col.name, col.column),
          width: col.name === 'Digital Tag' ? '25%' 
                : col.columnWidth ?? `${Math.max(Math.floor(100/rpt.columns.length), 15)}%`,
          align: 'center' as TableAlignType
        }
      })
      management['outerFilters'][rpt.type] = {};
      rpt.outerFilters.forEach((otf) => {
        management['outerFilters'][rpt.type][otf.key] = otf.type === 'date' 
                                                      ? moment().format('YYYY-MM-DD') 
                                                      : (otf.type === 'multiselect')
                                                        ? []
                                                        : ''
      });
      management['selectedFilters'][rpt.type] = {};
      management['fieldCond'][rpt.type] = {};
      management['defaultRowFields'][rpt.type] = {};
      management['disabledFilters'][rpt.type] = [];
      management['disabledSorting'][rpt.type] = [];
      rpt.columns.forEach((cl) => {
        const fld = convertFieldNames(cl.name, cl.column);
        management['fieldCond'][rpt.type][fld] = cl;
        management['defaultRowFields'][rpt.type][fld] = '';
        if (cl.getList) {
          management['selectedFilters'][rpt.type][fld] = [] as Array<MultipleSelectOptionsType>;
        }
        if (cl.disableInnerFilter) {
          management['disabledFilters'][rpt.type].push(
            {columnName: fld, filteringEnabled: false}
          )
        }
        if (cl.disableSorting) {
          management['disabledSorting'][rpt.type].push(
            {columnName: fld, sortingEnabled: false}
          )
        }
      });
      management['activityType'][rpt.type] = rpt.activityType;
    });
    const add = {
      initialColumns: {management: management['columns']},
      initialColumnExtensions: {management: management['columnExt']},
      initialFieldConditions: {management: management['fieldCond']},
      initialOuterFilter: {management: management['outerFilters']},
      initialSelectedFilters: {management: management['selectedFilters']},
      defaultRowFields: {management: management['defaultRowFields']},
      disabledFilterColumnExtensions: {management: management['disabledFilters']},
      disabledSortingColumnExtensions: {management: management['disabledSorting']},
      payloadType: management['activityType'],
    };
    if (!includeDefaults) {
      return add;
    } else {
      for (const key in list) {
        if (list[key].management) {
          list[key] = {...list[key], management: {...list[key].management, ...add[key].management}}
        } else {
          list[key] = {...list[key], ...add[key]}
        }
      }
    }
  }
  return list;
}
