import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useHistory, useRouteMatch } from 'react-router';
import withHeader from '../../presentation/withHeader';
import {Grid, makeStyles, Box, Button, Typography} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import MyTable from '../../presentation/Table';
import {RouterLink} from '../../presentation/word';
import MyButton from '../../presentation/button';
import SearchBar from '../../presentation/SearchBar';
import {Buttons} from '../../presentation/ButtonsGroup';
import moment from 'moment';
import { activatePlugin, getBusinessProfile, getPlugins } from '../../store/profile/actions';
import { PluginModel, TokenService } from '@aglive/data-model';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {PluginRefObj, PluginForm} from '@aglive/web-core';
import EditIcon from '@material-ui/icons/Edit';
import COLOR from '../../styled/colors';

const useStyles = makeStyles((_) => ({
  button: {padding: 12},
  buttonLabel: {
    textTransform: 'none',
    color: COLOR.GREENT_TEXT
  }
}));

const Plugins: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();
  const inactive = path.includes('inactive');
  const headers = ['Plugin Name', inactive ? 'Deactivated On' : 'Activated On', 'Action'];
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = useState('');
  const businessPlugin = useAppSelector(state => state.user.plugins) ?? [];
  const pluginCheck = inactive ? ['deactivated','obsolete'] : ['activated'];
  const deactivatePlugin = (pluginId, reactivate) => {
    dispatch(
      toggleModal({
        status: 'warning',
        title: reactivate ? 'Reactivate Plugin' : 'Deactivate Plugin',
        subtitle:
          `Are you sure you want to ${reactivate ? 'r' : 'd'}eactivate this plugin?`,
        renderButton: (
          <Buttons
            leftButtonTitle="No"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(toggleModalOff());
              dispatch(activatePlugin(`${reactivate ? '' : 'de'}activatePlugin`, {uuid: pluginId})).then(() => {
                fetchPlugins();
              });
            }}
          />
        ),
      }),
    );
  }

  const fetchPlugins = useCallback(() => {
      dispatch(getBusinessProfile())
    }, [dispatch]);

  useEffect(() => {
    fetchPlugins();
  }, [fetchPlugins]);

  return (
    <>
      {!inactive && <Box mb={5}>
        <Grid alignItems="center" container>
          <Grid item xs={8}>
            <SearchBar
              query={query}
              setQuery={setQuery}
              label="Search"
            />
          </Grid>
          <Grid item xs={4} style={{textAlign: 'right'}}>
            <MyButton
              text={'Add New'}
              variant="contained"
              buttonClass={classes.button}
              onClick={() => history.push(`${path}/add`)}
            />
          </Grid>
        </Grid>
      </Box>}
      <Box>
        <MyTable
          heads={headers}
          rows={businessPlugin
            ?.filter((row) => row.name.toLowerCase().includes(query.toLowerCase()) && pluginCheck.includes(row.status))
            ?.map((rowData) => [
              rowData.status === 'obsolete' 
              ? <Typography style={{fontSize: 16}}>{rowData.name} (Obsolete)</Typography>
              : <RouterLink to={`${path.replace('/inactive','')}/view/${rowData.name}`}>{rowData.name}</RouterLink>,
              moment(rowData[`${inactive ? 'de' : ''}activationDate`]).format('DD/MM/YYYY'),
              <Box>
                {rowData.status !== 'obsolete' && 
                  <Button className={classes.buttonLabel} onClick={() => {
                    deactivatePlugin(rowData.pluginId, !!inactive);
                  }}>
                    <u>{`${inactive ? 'R' : 'D'}eactivate`}</u>
                  </Button>
                }
                {!inactive && 
                  <Button onClick={() => history.push(`${path}/edit/${rowData.name}`)}>
                    <EditIcon/>
                  </Button>
                }
              </Box>
            ])}
        />
      </Box>
      {!inactive && 
      <Box mt={5}>
        <RouterLink to={`${path}/inactive`}>Deactivated Plugins</RouterLink>
      </Box>}
    </>
  );
};

const SinglePlugin: React.FC<{}> = () => {
  const history = useHistory();
  const route = useRouteMatch();
  const dispatch = useAppDispatch();
  const [pluginSetting, setPluginSetting] = useState<PluginModel.Plugin>();
  const existingCredentials = useAppSelector(state => state.user.plugins)?.find((plugin => {
    return plugin['status'] !== 'obsolete' && plugin.name === route.params['name'];
  }));
  const pluginFormRef = useRef<PluginRefObj>(null);

  const submitPluginForm = () => {
    let formdetails: {uuid: string, credentials?: Object, schedule?: string, status: TokenService.BusinessPlugin['status']} = {
      uuid: existingCredentials.pluginId, 
      credentials: pluginFormRef.current.getCredentials(),
      status: existingCredentials.status
    };
    dispatch(activatePlugin('editPlugin', formdetails, () => {
      history.goBack();
      dispatch(getBusinessProfile())
    }));
  }

  useEffect(() => {
    dispatch(getPlugins(existingCredentials.pluginId)).then((resp: Array<PluginModel.Plugin>) => {
      setPluginSetting(resp[0]);
    })
  }, []);
  return (
    <>
    {pluginSetting && <PluginForm viewMode={!route.path.includes('/edit')} ref={pluginFormRef} jsonForm={pluginSetting} credentialsState={existingCredentials?.credential} />}
      <Box mt={5}>
        <Grid container justifyContent="flex-end">
          {route.path.includes('/edit') ?
            <MyButton variant="contained" text="Save" onClick={submitPluginForm} />
            : <MyButton variant="contained" text="Edit" onClick={() => history.push(`${route.url.replace('view','edit')}`)} />
          }
        </Grid>
      </Box>
    </>
  );
}

export const PluginActive = withHeader(
  {
    title: 'Plugins',
    margin: 60,
    back: false,
  },
  Plugins,
);

export const PluginInactive = withHeader(
  {
    title: 'Deactivated Plugins',
    margin: 60,
    back: true,
  },
  Plugins,
);

export const ViewPlugin = withHeader(
  {
    title: 'View Plugin',
    margin: 60,
    back: true,
  },
  SinglePlugin,
);

export const EditPlugin = withHeader(
  {
    title: 'Edit Plugin',
    margin: 60,
    back: true,
  },
  SinglePlugin,
);
