import React, {useRef, useState, useEffect} from 'react';
import {useReactToPrint} from 'react-to-print';
import Qrcode from 'qrcode.react';
import {useAppSelector} from '../../utils/hooks';
import {useHistory, useParams, Redirect} from 'react-router-dom';

import MyButton from '../../presentation/button';
import {makeStyles} from '@material-ui/core/styles';

import {
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import withHeader from '../../presentation/withHeader';
import {SiteTokenResponse, SiteQRCode} from '../../store/site/types';
import CenteredTree from './SiteCenteredTree';
import {flattenTree} from '../../store/site/actions';
import MyTable from '../../presentation/Table';
import {HyperLink} from '../../presentation/word';
import QrCodeIcon from '../../presentation/QrCodeIcon';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import COLOR from '../../styled/colors';
import './ViewSite.css';
import PowerBy from '../../presentation/PowerBy';
import CONSTANT from '../../config/constant';
import {CASL} from '@aglive/frontend-core';

const TABLE_HEADER = ['Layer Number', 'Layer Name', 'Site ID'];

const useStyles = makeStyles((theme) => ({
  printButton: {
    color: COLOR.GREEN,
    borderColor: COLOR.GREEN,
    width: 200,
  },
  button: {
    width: 200,
  },
  modalTextContainer: {
    margin: '10px 0px',
  },
  modalTitleContainer: {
    fontWeight: 600,
  },
  modalTextMarginContainer: {
    marginTop: 30,
  },
  printFont: {
    fontSize: 24,
  },
  dropdownContainer: {
    minWidth: 200,
  },
  editBtn: {
    marginTop: 20,
  },
  fieldTextColor: {
    '& .MuiInputBase-root': {
      color: 'black',
    },
  },
}));

const INITIAL_SITE_CODE_STATE = {
  show: false,
  tokenSize: 1,
  qrcode: {
    id: '',
    siteName: '',
    layerName: '',
    location: '',
  } as SiteQRCode,
};

const ViewSite: React.FC<{siteState: SiteTokenResponse}> = ({siteState}) => {
  const history = useHistory();
  const componentRef = useRef();
  const classes = useStyles();
  const EDIT_PATH = `/private/register/site/edit/${siteState.externalIds[0].agliveToken}`;
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isPlants = useAppSelector(
    (state) => state.user.businessProfileData.industryType === 'PLANTS',
  );
  const isAustralia =
    businessProfile.businessCountry === 'Australia' ||
    !businessProfile.businessCountry;
  // custom modal
  const [modal, setModal] = useState(INITIAL_SITE_CODE_STATE);

  // printing content
  const [printContent, setPrintContent] = useState([] as Array<SiteQRCode>);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Make a copy to prevent mutation, need deep copy
  const treeCopy = JSON.parse(JSON.stringify(siteState.details.layers));
  // full page content
  const fullPageContent = flattenTree(treeCopy)
    .slice(1)
    .map(
      (node) =>
        ({
          id: `${siteState.externalIds[0].agliveToken}_${node.id}`,
          siteName: siteState.details.siteName,
          layerName: node.layerName,
          location: siteState.details.location,
        } as SiteQRCode),
    );

  useEffect(() => {
    if (printContent.length) {
      handlePrint();
    }
  }, [printContent]);

  return (
    <>
      <Grid alignItems="center" container style={{marginBottom: 20}}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginTop: 10,
            }}>
            {`Select Location ${
              isPlants ? '' : isAustralia ? 'e.g. PIC' : 'e.g. PID'
            }`}
          </Typography>
          <TextField
            style={{
              marginTop: 8,
              width: '100%',
            }}
            value={siteState.details.location}
            margin="normal"
            InputLabelProps={{
              shrink: false,
            }}
            disabled
            className={classes.fieldTextColor}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginTop: 10,
            }}>
            {'Site Name'}
          </Typography>
          <TextField
            style={{
              marginTop: 8,
              width: '100%',
            }}
            value={siteState.details.siteName}
            margin="normal"
            InputLabelProps={{
              shrink: false,
            }}
            disabled
            className={classes.fieldTextColor}
          />
        </Grid>
      </Grid>

      <Grid alignItems="center" container>
        <Grid item xs={6} style={{paddingBottom: 40}}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginTop: 10,
            }}>
            Region
          </Typography>
          <TextField
            style={{
              marginTop: 8,
              width: '100%',
            }}
            value={siteState.details.region || '-'}
            margin="normal"
            InputLabelProps={{
              shrink: false,
            }}
            disabled
            className={classes.fieldTextColor}
          />
        </Grid>
        <Grid item xs={6} style={{paddingBottom: 40}}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginTop: 10,
            }}>
            Property Type
          </Typography>
          <TextField
            style={{
              marginTop: 8,
              width: '100%',
            }}
            value={siteState.details.propertyType || '-'}
            margin="normal"
            InputLabelProps={{
              shrink: false,
            }}
            disabled
            className={classes.fieldTextColor}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          container
          item
          xs={6}
          direction="column"
          style={{paddingRight: 60}}>
          <Grid item style={{paddingBottom: 10}}>
            <Typography variant="h2">Site Layers</Typography>
          </Grid>
          <Grid item container justify="flex-end" style={{paddingBottom: 10}}>
            <CASL.Can I="update" a="site">
              <HyperLink
                href="#"
                onClick={() => {
                  setModal((prevState: typeof INITIAL_SITE_CODE_STATE) => {
                    let nextState = JSON.parse(JSON.stringify(prevState));
                    nextState.tokenSize = 3;
                    return nextState;
                  });
                  setPrintContent([...fullPageContent]);
                }}
                // this part will shallow-clone the qrcode and force the printContent to update and trigger the useEffect hook
                // otherwise the useEffect will not work when you press print -> cancel -> print because there is no state update
                // please do update if there is room for optimization
              >
                Print All IDs
              </HyperLink>
            </CASL.Can>
            <div style={{display: 'none'}}>
              <ComponentToPrint
                tokenSize={modal.tokenSize}
                qrcode={printContent.map((qrcode) => JSON.stringify(qrcode))}
                ref={componentRef}
                description={printContent.map((content) => [
                  {
                    label: 'Site Name',
                    value: content.siteName,
                  },
                  {
                    label: 'Layer Name',
                    value: `${content.id.split('_')[1]} - ${content.layerName}`,
                  },
                ])}
              />
            </div>
          </Grid>

          <MyTable
            heads={TABLE_HEADER}
            rows={fullPageContent.map((node) => [
              node.id.split('_')[1],
              node.layerName,
              <QrCodeIcon
                onClick={() =>
                  setModal({
                    show: true,
                    tokenSize: 1,
                    qrcode: node,
                  })
                }
              />,
            ])}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h2" style={{paddingBottom: 40}}>
            Site Map Preview
          </Typography>
          <CenteredTree tree={siteState.details.layers} />
        </Grid>
      </Grid>

      <CASL.Can I="update" a="site">
        <Grid item container justify="flex-end">
          <MyButton
            text="Edit"
            variant="contained"
            buttonClass={classes.editBtn}
            onClick={() => history.push(EDIT_PATH)}
          />
        </Grid>
      </CASL.Can>

      {modal.show && (
        <CustomSiteModal
          modal={modal}
          setModal={setModal}
          setPrintContent={setPrintContent}
        />
      )}
    </>
  );
};

export class ComponentToPrint extends React.Component<{
  qrcode: Array<string>;
  description: Array<Array<{label: string; value: string}>>; // typeof INITIAL_SITE_CODE_STATE.qrcode | Array<typeof INITIAL_SITE_CODE_STATE.qrcode>;
  tokenSize: number;
}> {
  render() {
    if (this.props.qrcode) {
      const printContent = this.props.qrcode;

      return (
        <div className="print-container" style={{margin: '0', padding: '0'}}>
          {printContent.map((qrcode, index) => (
            <>
              <div className="page-break" />
              <div className="print-content">
                <div className="print-qrcode">
                  <Qrcode
                    value={qrcode}
                    style={{
                      width: `${this.props.tokenSize}in`,
                      height: `${this.props.tokenSize}in`,
                    }}
                  />
                </div>
                <div className="print-watermark">
                  <PowerBy />
                </div>
                <div className="print-text-container">
                  <PrintContentTypography
                    description={this.props.description[index]}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}

type CustomSiteModalT = {
  modal: typeof INITIAL_SITE_CODE_STATE;
  setModal: any; // (arg: typeof INITIAL_SITE_CODE_STATE) => void;
  setPrintContent: (arg: Array<typeof INITIAL_SITE_CODE_STATE.qrcode>) => void;
};

const CustomSiteModal: React.FC<CustomSiteModalT> = ({
  modal,
  setModal,
  setPrintContent,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={modal.show}
      onClose={() => setModal(INITIAL_SITE_CODE_STATE)}
      aria-labelledby="qr-code-icon"
      aria-describedby="qr-code-print-modal"
      // PaperProps={{style: {"overflowY": 'visible',width: 480, height: 350}}}
      maxWidth="md"
      fullWidth={true}>
      <DialogTitle disableTypography>
        <Typography
          variant="h3"
          style={{
            paddingTop: 20,
            paddingBottom: 10,
            borderBottom: `${COLOR.GREENT_TEXT} 4px solid`,
          }}>
          Display Site ID
        </Typography>
      </DialogTitle>
      <DialogContent style={{height: 400}}>
        <Grid container style={{paddingTop: 50}}>
          <Grid item container xs={6} justify="center">
            <Qrcode
              value={JSON.stringify(modal.qrcode)}
              style={{width: 241, height: 241, paddingTop: 10}}
            />
          </Grid>
          <Grid item container direction="column" xs={6}>
            <Typography
              variant="h6"
              className={`${classes.modalTextContainer} ${classes.modalTitleContainer}`}>
              Site Name
            </Typography>
            <Typography variant="h6" className={classes.modalTextContainer}>
              {modal.qrcode.siteName}
            </Typography>
            <Typography
              variant="h6"
              className={`${classes.modalTextContainer} ${classes.modalTitleContainer} ${classes.modalTextMarginContainer}`}>
              Layer Name
            </Typography>
            <Typography variant="h6" className={classes.modalTextContainer}>{`${
              modal.qrcode.id.split('_')[1]
            } - ${modal.qrcode.layerName}`}</Typography>

            <CASL.Can I="update" a="site">
              <Grid item>
                <Typography
                  variant="h6"
                  style={{fontWeight: 600, padding: '20px 0 10px 0'}}>
                  Token Size ( 1- 8 )
                </Typography>
                <FormControl variant="outlined">
                  <InputLabel id="token-size-select">
                    {'Please select'}
                  </InputLabel>
                  <Select
                    labelId="token-size-select"
                    label="Please select"
                    value={modal.tokenSize}
                    onChange={(e) =>
                      setModal((prevState: typeof INITIAL_SITE_CODE_STATE) => {
                        let nextState = JSON.parse(JSON.stringify(prevState));
                        nextState.tokenSize = e.target.value as number;
                        return nextState;
                      })
                    }
                    className={classes.dropdownContainer}>
                    {Array(8)
                      .fill(null)
                      .map((_, index) => (
                        <MenuItem value={index + 1} key={index + 1}>
                          Size {index + 1}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Typography variant="h6" className={classes.modalTextContainer}>
                  Actual Print Size = {modal.tokenSize}" x {modal.tokenSize}"
                </Typography>
              </Grid>
            </CASL.Can>
          </Grid>
        </Grid>
      </DialogContent>
      <CASL.Can I="update" a="site">
        <DialogActions
          style={{display: 'flex', justifyContent: 'center', marginBottom: 30}}>
          <MyButton
            text="Cancel"
            variant="outlined"
            onClick={() => setModal(INITIAL_SITE_CODE_STATE)}
            buttonClass={`${classes.printButton} ${classes.button}`}
          />
          <MyButton
            text="Print ID"
            variant="contained"
            buttonClass={classes.button}
            onClick={() => setPrintContent([{...modal.qrcode}])}
            // this part will shallow-clone the qrcode and force the printContent to update and trigger the useEffect hook
          />
        </DialogActions>
      </CASL.Can>
    </Dialog>
  );
};

const PrintContentTypography: React.FC<{
  description: Array<{
    label: string;
    value: any;
  }>;
}> = ({description}) => {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item style={{paddingRight: 100}}>
        <Typography
          variant="h6"
          className={`${classes.modalTextContainer} ${classes.modalTitleContainer} ${classes.printFont}`}>
          {description[0].label}
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.modalTextContainer} ${classes.printFont}`}>
          {description[0].value}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="h6"
          className={`${classes.modalTextContainer} ${classes.modalTitleContainer} ${classes.printFont}`}>
          {description[1].label}
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.modalTextContainer} ${classes.printFont}`}>
          {description[1].value}
        </Typography>
      </Grid>
    </Grid>
  );
};

// if the data is not in store, redirect user back to library
const ViewSiteGuard = () => {
  const {agliveToken} = useParams<{agliveToken: string}>();
  const siteState = useAppSelector((state) =>
    state.site.find(
      (token) => token.externalIds[0].agliveToken === agliveToken,
    ),
  );

  if (siteState) {
    return <ViewSite siteState={siteState} />;
  } else {
    return <Redirect to="/private/register/site" />;
  }
};

export default withHeader(
  {
    title: 'View Site Map',
    back: true,
  },
  ViewSiteGuard,
);
