export const ENV = 'UAT';
export const VERSION = '1.6.1';
const HTTP = 'http';
const HTTPS = 'https';

export const tokenService = `${HTTPS}://dev.api.aglive.com`;
export const envdService = `${HTTPS}://dev.api.aglive.com`;
export const csvService = `${HTTPS}://dev.api.aglive.com`;
export const blockchainService = `${HTTP}://52.62.73.61:8500`;
export const blockchainContractAddr =
  '0x42A44d4024bD016E5AdEBb2A9277D19f930d261b';

// ENVD Credentials
export const AUTH_TOKEN =
  'ATYJ+cFIWzhQPyPJix7AM/epyaU+XPR6UVyBV7StRYCyk8uPHqj8GF1PdPm304yTKl6hrg9mn1GA1LTjRNSNnQ==';
