import {ENV} from '../env';
import * as Sentry from '@sentry/react';

// ERROR MESSAGES MAP
export const WEB_ERROR = {
  // Token related errors
  CREATE_TOKEN: {
    title: 'Unsuccessful token creation',
    details: 'Token were not created successfully',
  },

  // Asset related errors
  JSON_VALIDATION_ERROR: {
    title: 'Invalid CSV',
    details: 'There is something wrong with the CSV',
  },

  // Code related errors
  INVALID_ERROR: {
    title: 'Invalid Product',
    details: 'There is something wrong with the selected product',
  },

  // Document related errors

  // Network related errors
  NETWORK_ERROR: {
    title: 'Network Error',
    details: 'Something went wrong! Please try again later.',
  },

  REQUEST_ERROR: {
    title: 'Request Error',
    details: 'The request was made but no response was received.',
  },

  WALLETCONNECT_ERROR: {
    title: 'Connection Error',
    details: 'The connection between the web and mobile has been lost.',
  },

  QR_EXPIRE: {
    title: 'QR code has expired',
    details: 'Please refresh the page to get a new QR code',
  },

  OFFLINE_ERROR: {
    title: 'Internet Error',
    details: 'Your connection to the portal was lost.',
  },

  // MISC
  INVALID_INPUT: {
    title: 'Invalid Input',
    details: 'Please make sure you have filled up everything!',
  },
  UNKNOWN_ERROR: {
    title: 'Unknown Error',
    details: 'Something went wrong! Please try again later.',
  },
} as const;

// WEB ERROR - THE base error type that all error handler must handle
export type WebErrorType<T = any> = {
  code: string;
  name: string;
  title: string;
  message: string;
  error?: T;
};

// Common parent of all the error classes
class ErrorBase implements WebErrorType {
  // cannot extend Error because the super(message) is not working
  code: string;
  message: string;
  name: string;
  title: string;
  error?: any; // anything will be stringified and sent to Sentry

  constructor(
    code: string,
    message: string,
    name: string,
    title: string,
    error?: any,
  ) {
    this.message = message;
    this.code = code;
    this.name = name;
    this.title = title;
    this.error = error;
    // this.sendErrortoSentry();
  }

  sendErrortoSentry() {
    if (process.env.NODE_ENV === 'development') {
      console.error('Error', this);
      // console.error(JSON.stringify(this, undefined, 10));
    } else {
      // send error report to sentry
      const scope = new Sentry.Scope();
      scope.setTag('module', 'API');
      scope.setContext(this.code, this.error || this.message);
      Sentry.captureException(this.error || this.message, scope);
    }
  }

}
export class WebError extends ErrorBase {
  constructor(code: keyof typeof WEB_ERROR, error?: any) {
    if (error && error instanceof WebError) {
      super(error.code, error.message, error.name, error.title, error.error);
    } else {
      super(
        code,
        typeof error === 'string' ? error : WEB_ERROR[code].details,
        'WebError',
        WEB_ERROR[code].title,
        error,
      );
    }
  }
}

// NETWORK ERROR
// https://docs.google.com/document/d/1BJDZxduRXVwNJP5glMAwmgJL2rLF0aEn/edit
// All error responses look like this
type NetworkErrorType = typeof DEFAULT_ERROR_TEMPLATE &
  Partial<typeof OPTIONAL_ERROR_ATTR_TEMPLATE>;

const DEFAULT_ERROR_TEMPLATE = {
  code: '',
  status: '',
  title: '',
  details: '',
};
const OPTIONAL_ERROR_ATTR_TEMPLATE = {
  error: null as any,
  source: null as object,
  solutions: '',
};

// Will transform all error responses to NetworkError
export class NetworkError extends ErrorBase {
  constructor(error: any) {
    if (error instanceof NetworkError || error instanceof WebError) {
      // If error got re-thrown down the exception chain
      super(error.code, error.message, error.name, error.title, error.error);
      console.error('This error got re-thrown and will log multiple times');
    } else if (error instanceof Error) {
      // When any API response got thrown
      const errorObj = error as any;
      if (errorObj.response) {
        const APIResponse = errorObj.response;
        const APIPayload = APIResponse.data as unknown;

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (isNetworkError(APIPayload)) {
          super(
            APIPayload.code,
            messageGenerator(
              APIPayload.details,
              APIPayload.solutions,
              // APIPayload.source,
            ),
            'NetworkError',
            APIPayload.title,
            APIPayload,
          );
          console.error(
            `Solution: ${JSON.stringify(APIPayload?.source, undefined, 10)}`,
          );
        } else {
          // The API response payload thrown does not fit the API specification
          if (Array.isArray(APIPayload) && isNetworkError(APIPayload[0])) {
            // The errors are in an array
            // Will only throw the first error
            // Handle all the error with error property
            super(
              APIPayload[0].code,
              messageGenerator(
                APIPayload[0].details,
                APIPayload[0].solutions,
                // APIPayload[0].source,
              ),
              'NetworkError',
              APIPayload[0].title,
              APIPayload,
            );
            console.error(
              'The error in is an array. Please refer to error.error for full error details.',
            );
          } else if (Array.isArray(APIPayload)) {
            // For backward compatibiltiy
            super(
              APIResponse.status,
              WEB_ERROR.NETWORK_ERROR.details,
              'NetworkError',
              WEB_ERROR.NETWORK_ERROR.title,
              APIResponse,
            );
            console.error(
              'Outdated error format. Please inform Back End to update error format',
            );
          } else {
            // Other API Error formats
            super(
              APIResponse.status,
              WEB_ERROR.NETWORK_ERROR.details,
              'NetworkError',
              WEB_ERROR.NETWORK_ERROR.title,
              APIResponse,
            );
            console.error(
              'Unrecogized error. Refer to error.ts for more information.',
            );
          }
        }
      } else if (errorObj.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        super(
          'REQUEST_ERROR',
          WEB_ERROR.REQUEST_ERROR.details,
          'NetworkError',
          WEB_ERROR.REQUEST_ERROR.title,
          errorObj.request,
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        // or a native Error object
        super(error.name, error.message, error.name, error.name, errorObj);
        console.error(
          'Unrecogized error. Refer to error.ts for more information.',
        );
      }
    } else if (Object.getPrototypeOf(error) === Object.prototype) {
      super(
        'UNKNOWN_ERROR',
        WEB_ERROR.UNKNOWN_ERROR.details,
        'UnknownError',
        WEB_ERROR.UNKNOWN_ERROR.title,
        error,
      );
      console.error(
        'Unrecogized error. Refer to error.ts for more information.',
      );
    } else {
      // If any other type was thrown this serves as a safety net
      super(
        'UNKNOWN_ERROR',
        WEB_ERROR.UNKNOWN_ERROR.details,
        'UnknownError',
        WEB_ERROR.UNKNOWN_ERROR.title,
        error,
      );
      console.error(
        'Unrecogized error. Refer to error.ts for more information.',
      );
    }
  }
}

function messageGenerator(
  errorMessage: string,
  errorSolution?: string,
  // errorSource?: {[key: string]: any},
): string {
  errorMessage = errorMessage.trim();
  const isFullStop = errorMessage[errorMessage.length - 1] === '.';
  // const sourceString =
  //   'Source: ' +
  //   Object.entries(errorSource).map(
  //     ([key, value]) => `${key}: ${JSON.stringify(value)}\n`,
  //   );
  if (errorSolution)
    return `${errorMessage}${isFullStop ? '' : '.'} ${errorSolution}`;
  return `${errorMessage}${isFullStop ? '' : '.'} `;
}

// a function to determine if the error is an API error
// This is not meant to verify NetworkError, use instanceof NetworkError for locally thrown NetworkErrors
export function isNetworkError(arg: unknown): arg is NetworkErrorType {
  // Ensure the error format is identical to what we expect
  if (typeof arg === 'object') {
    for (const key of Object.keys(DEFAULT_ERROR_TEMPLATE)) {
      if (!(key in arg)) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

export type ValidationErrorType<T = any> = {type: string} & WebErrorType<T>;

// Special error class to accomodate validation error for now
export class ValidationError extends ErrorBase {
  type: string;
  constructor(message: string, type: string, error: any) {
    super(
      type,
      message.length ? message : type,
      'ValidationError',
      WEB_ERROR.JSON_VALIDATION_ERROR.title,
      error,
    );
    this.type = type;
  }
}

export function handleUploadErrors(
  error: any,
  csvLength: number,
  uploadType: string,
): any {
  const isTable =
    Array.isArray(error.error) && error.error[0].source?.data?.length;

  const isDup = error.code === 'E_DUPLICATE_FOUND';
  const isDupRows =
    Array.isArray(error.error) &&
    error.error[0].source?.data?.failRows &&
    error.error[0].source?.data?.failRows[0].rows?.length &&
    error.error[0].source?.data?.failRows[0].result ===
      'Duplicate exists in database';

  const rowError =
    Array.isArray(error.error) &&
    error.error[0].source?.data?.failRows &&
    error.error[0].source?.data?.failRows[0].rows?.length;

  if (isTable) {
    const errorList = [];
    let data = error.error[0].source.data;
    // This for loop is here because foreach doesn't want to work.  I don't know why
    for (let i = 0; i < data.length; i++) {
      const errors = data[i];
      const e = {
        row: errors.row,
        message: errors.errors ? errors.errors[0] : errors.error[0],
      };
      errorList.push(e);
    }
    return {
      status: 'failed',
      title: error.title,
      subtitle: '',
      errorInfo: errorList,
    };
  } else if (rowError) {
    let errorType = 'failed';
    const errorList = [];
    let data = error.error[0].source?.data?.failRows[0].rows;
    let errorMessage = error.error[0].source?.data?.failRows[0].result;
    if(errorMessage.message){
      errorMessage = errorMessage.message;
    }
    let last = data[0],
      first = data[0];
    // This for loop is here because foreach doesn't want to work.  I don't know why
    for (let i = 0; i < data.length; i++) {
      if (last + 1 === data[i] || first === last) {
        last = data[i];
      } else {
        const e = {
          row: first.toString() + '-' + last.toString(),
          message: errorMessage,
        };
        errorList.push(e);
      }
    }
    if (errorList.length === 0) {
      const e = {
        row: first.toString() + '-' + last.toString(),
        message: errorMessage,
      };
      errorList.push(e);
    }
    if(error.error[0].source?.data?.successRows.length > 0){
      errorType = 'warning';
      const successData = error.error[0].source?.data?.successRows;
      let _last = successData[0],
        _first = successData[0];
      for (let i = 0; i < successData.length; i++) {
        if (_last + 1 === successData[i] || _first === _last) {
          _last = successData[i];
        } else {
          const e = {
            row: _first.toString() + '-' + _last.toString(),
            message: 'rows added to the database successfully',
          };
          errorList.push(e);
        }
      }
      if (errorList.length == 1) {
        const e = {
          row: _first.toString() + '-' + _last.toString(),
          message: 'rows added to the database successfully',
        };
        errorList.push(e);
      }
    }
    return {
      status: errorType,
      title: error.error[0].details,
      subtitle: '',
      errorInfo: errorList,
    };
  } else if (isDupRows) {
    const errorList = [];
    let data = error.error[0].source?.data?.failRows[0].rows;
    let last = data[0],
      first = data[0];
    // This for loop is here because foreach doesn't want to work.  I don't know why
    for (let i = 0; i < data.length; i++) {
      const errors = data[i];
      if (last + 1 == data[i] || first == last) {
        last = data[i];
      } else {
        const e = {
          row: first.toString() + '-' + last.toString(),
          message: 'These rows are already in the database',
        };
        errorList.push(e);
      }
    }
    if (errorList.length == 0) {
      const e = {
        row: first.toString() + '-' + last.toString(),
        message: 'These rows are already in the database',
      };
      errorList.push(e);
    }
    const allRows = data.length == csvLength,
      uploadedRows = csvLength - data.length;

    return {
      status: 'warning',
      title: allRows
        ? 'All ' + uploadType + ' already in the database'
        : 'Some ' + uploadType + ' already in database',
      subtitle: allRows
        ? 'No data uploaded'
        : uploadedRows.toString() + ' ' + uploadType + ' uploaded',
      errorInfo: errorList,
    };
  } else if (isDup) {
    const rows = error.error[0].source?.rows;
    const errorList = [];
    if (rows) {
      rows.map(function (x) {
        errorList.push({row: x, message: error.error[0].details});
      });
    }
    return {
      status: 'warning',
      title: error.title,
      subtitle: error.message,
    };
  } else {
    console.error('Error.error undefined. Modal will display fallback message');
    return {
      status: 'failed',
      title: error.title,
      subtitle: error.message,
    };
  }
}
