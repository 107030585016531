import Paper from '@material-ui/core/Paper';
import React from 'react';
import {
  Grid as GridTable,
  DragDropProvider,
  Table,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  CustomPaging,
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import withHeader from '../../presentation/withHeader';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import MyButton from '../../presentation/button';
import COLOR from '../../styled/colors';
import {MultipleSelect} from '../../presentation/MultupleSelect';
import * as PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';
import {SubPageType, useReport} from './ReportBusinessObject';
import {subPages} from './ReportsFields';
import DatePicker from '../../presentation/DatePicker';
import {connectProps} from '@devexpress/dx-react-core';
import {OuterFilterFields} from './OuterFilterFields';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1E7B3E',
  },
  formContainer: {
    marginBottom: 40,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 170,
  },
  button: {
    marginRight: 20,
  },
  picDropdown: {
    marginTop: 25,
    paddingRight: '20px',
  },
  cell: {
    width: '100%',
  },
  tableRoot: {
    '& > div > div:nth-child(2)': {
      minHeight: 450,
    },
  },
}));

const CustomFilterCellBase = ({column, classes, report}) => {
  const columnTitle = report.initialColumns['management'][
    report.state.subPage ?? subPages[0]
  ].find((item) => item.name === column.name)?.title;
  switch (column.type) {
    case 'text':
    case 'number':
      return (
        <TableCell
          key={`cell-${column.name}-${
            report.state.fieldConditions[column.name]?.filterValue ?? ''
          }`}
          className={classes.cell}>
          <TextField
            key={column.name}
            type={column.type}
            defaultValue={
              report.state.fieldConditions[column.name]?.filterValue ?? null
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              report.handleTextfieldChange(e, column)
            }
            placeholder={`Enter ${columnTitle}`}
            inputProps={{
              style: {textAlign: 'left', height: 'inherit', fontSize: '15px'},
            }}
          />
        </TableCell>
      );
    case 'multiSelect':
      return (
        <TableCell className={classes.cell}>
          <MultipleSelect
            dataSet={report.state.multiSelectOptions[column.name]}
            value={report.state.selectedFilters[column.name]}
            selectedData={report.state.selectedFilters[column.name]}
            setSelectedData={(selectedData) =>
              report.handleMultiSelectChange(column.name, selectedData)
            }
            variant="outlined"
            placeholder="Select"
            dropdownHeight={300}
            numberDisplayed={column.numberDisplayed}
          />
        </TableCell>
      );
    case 'dateRange':
      return (
        <TableCell className={classes.cell}>
          <DatePicker
            label=""
            range={true}
            dateValue={
              report.state.fieldConditions[column.name]?.filterValue ?? null
            }
            handleChange={(date) =>
              report.handleDateRangeChange(column.name, date)
            }
            fontSize="16px"
            withBorder={false}
            format={'dd-MM-yyyy'}
            placeholder={'dd-mm-yyyy'}
            textAlign={'left'}
            emptyLabel={''}
            errorStatus={report.state.errorState[column.name]?.status}
            errorMessage={report.state.errorState[column.name]?.message}
          />
        </TableCell>
      );
  }
};
const styles = (theme: {spacing: (arg0: number) => any}) => ({
  itemText: {
    paddingLeft: theme.spacing(1),
  },
});
const CustomFilterCell = withStyles(styles, {name: 'FilterCell'})(
  CustomFilterCellBase,
);
const OriginalFilterCell = (props) => {
  const {column, report} = props;
  const cols =
    report.initialColumns['management'][report.state.subPage ?? subPages[0]];
  // get array of columns need custom filter
  const customFilterColumns = cols.map((col) => {
    if (col.customFilter) return col.name;
  });
  if (customFilterColumns.includes(column.name)) {
    return <CustomFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};

const ManagementReport = () => {
  const classes = useStyles();
  const {type} = useParams<{type: string}>();
  const subPage = (
    type === 'Management-Report' ? '' : type?.replace(/-/g, ' ')
  ) as SubPageType;
  const report = useReport('management', subPage);
  const reportTypeSelected = !!report.state.report || report.state.report === 0;
  const FilterCell = connectProps(OriginalFilterCell, () => ({report: report}));

  return (
    <Grid container>
      <Grid container justifyContent="space-between">
        {!subPage && (
          <Grid
            container
            className={classes.formContainer}
            item
            xs={4}
            justifyContent="space-between">
            {/* <Grid item xs={6} className={classes.picDropdown}>
              <Typography variant="h6" role="label" style={{fontWeight: 600}}>
                {report.localizedPIC}
              </Typography>
              <Grid item>
                <MultipleSelect
                  dataSet={report.locationPicAddr}
                  selectedData={report.state.locations}
                  setSelectedData={report.updateLocation}
                />
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  role="label"
                  style={{fontWeight: 600, marginBottom: 10}}>
                  Report Type
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="report-type-select">
                    {'Please select'}
                  </InputLabel>
                  <Select
                    labelId="report-type-select"
                    label="Please select"
                    name="report"
                    defaultValue=""
                    onChange={report.handleSubPageChange}>
                    {report.formMap
                      .filter((item) => !item.hide)
                      .map(({label}, index) => (
                        <MenuItem value={index} key={index}>
                          {label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        {report.state.subPage &&
          report.state.subPage.toLowerCase().replace(/\s/g, '_') ===
            report.formMap[report.state.report]?.key.toLowerCase() && (
            <Grid
              container
              justifyContent="flex-start"
              className={classes.formContainer}
              spacing={4}>
              {report.formMap[report.state.report].outerFilterFields?.map(
                (field) => {
                  return (
                    <OuterFilterFields
                      state={report.state.outerFilter}
                      optionalState={
                        field.key === 'locations' ? report : report.state
                      } // for multiselect dataSet use => report.state.sites
                      setState={
                        field.customUpdate
                          ? report.updateOuterInnerFilters
                          : report.updateOuterFilter
                      }
                      errorState={report.state.errorState}
                      field={field}
                      key={field.key}
                      customItem={
                        field.key === 'locations'
                          ? report.localizedPIC
                          : undefined
                      }
                    />
                  );
                },
              )}
            </Grid>
          )}
        <Grid container>
          <Grid item xs={4}>
            <MyButton
              text={'Generate'}
              variant="contained"
              width={280}
              disabled={!reportTypeSelected || !report.allowGenerate}
              onClick={report.handleClickGenerate}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3} style={{maxWidth: '100%'}}>
        {report.state.tableGenerated && (
          <>
            <Paper style={{marginTop: 30}} className={classes.tableRoot}>
              <GridTable
                rows={report.state.rows}
                columns={report.state.columns}>
                <Table columnExtensions={report.columnExtensions} />
                <DragDropProvider />
                <PagingState
                  currentPage={report.state.currentPage}
                  onCurrentPageChange={report.changePage}
                  pageSize={report.state.pageSize}
                  onPageSizeChange={report.changePageSize}
                />
                <CustomPaging totalCount={report.state.totalData} />
                <FilteringState
                  defaultFilters={[]}
                  columnExtensions={report.state.columnFilter}
                />
                <IntegratedFiltering />
                <SortingState
                  sorting={report.state.sorting}
                  onSortingChange={report.changeSorting}
                  columnExtensions={report.state.columnSort}
                />
                <IntegratedSorting />
                {/* <Table columnExtensions={report.columnExtensions}/> */}
                <TableColumnReordering
                  defaultOrder={Object.keys(
                    report.defaultRowFields['management'],
                  )}
                  onOrderChange={report.changeColumnOrder}
                />
                <TableHeaderRow showSortingControls />
                <TableFilterRow cellComponent={FilterCell} />

                <TableColumnVisibility
                  hiddenColumnNames={report.state.hiddenColumns}
                  onHiddenColumnNamesChange={report.changeHiddenColumn}
                  columnExtensions={report.state.columnVisibility}
                />
                <Toolbar />
                <ColumnChooser
                  //@ts-ignore
                  itemComponent={Item}
                />
                <PagingPanel pageSizes={[5, 10, 15, 20]} />
              </GridTable>
            </Paper>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonGrid}>
              <MyButton
                text={'Download as CSV'}
                variant="contained"
                disabled={!reportTypeSelected || !report.state.rows.length}
                onClick={report.fetchReport({download: true, sorting: true})}
              />
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Management Report',
    margin: 40,
    useSlug: 'type',
  },
  ManagementReport,
);

const StyledCheckbox = withStyles({
  root: {
    padding: 0,
    color: COLOR.GREEN_BUTTON,
    '&$checked': {
      color: COLOR.GREEN_BUTTON,
    },
  },
  checked: {},
})(Checkbox);

const ItemBase = ({
  item: {column, hidden},
  disabled,
  onToggle,
  classes,
  ...restProps
}: {
  item: {column: any; hidden: boolean};
  disabled: boolean;
  onToggle: any;
  classes: any;
}) => (
  <ListItem
    key={column.name}
    component="li"
    disabled={disabled}
    onClick={!disabled ? onToggle : null}
    {...restProps}>
    <StyledCheckbox
      checked={!hidden}
      tabIndex={-1}
      disableRipple
      disabled={disabled}
    />
    <ListItemText
      className={classes.itemText}
      primary={column.title || column.name}
    />
  </ListItem>
);

ItemBase.propTypes = {
  item: PropTypes.shape({
    column: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
    }),
    hidden: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

ItemBase.defaultProps = {
  onToggle: () => {},
  disabled: false,
};
//@ts-ignore
const Item = withStyles(styles, {name: 'Item'})(ItemBase);
