import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {
  getBusinessProfile,
  validateCredential,
  updateBusinessProfile,
} from '../../store/profile/actions';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import withHeader from '../../presentation/withHeader';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  ListItem,
  Collapse,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Switch,
  InputLabel,
  Input,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import COLOR from '../../styled/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import MyButton from '../../presentation/button';
import {FormHelperText} from '@material-ui/core';
import moment from 'moment';
import CONSTANT from '../../config/constant';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {withStyles} from '@material-ui/core/styles';
import {Buttons} from '../../presentation/ButtonsGroup';
import {businessProfileObject, initialStateType} from '@aglive/frontend-core';
import {BusinessMisc, TokenService} from '@aglive/data-model';
import {PageHeader} from '../../presentation/withHeader';
import InputMask from 'react-input-mask';

const PERMITS_TEMPLATE = {
  permitNumber: '',
  permitExpiryDate: '',
};

const COUNTRY_LIST = Object.keys(BusinessMisc.SupportedCountries); //['Australia', 'Canada', 'Argentina'];
const STATE_OPTIONS = BusinessMisc.SupportedCountries;

const INTERVAL_OPTIONS = ['1 Day', '7 Days', '30 Days'];

const INITIAL_COLAPSE_STATE = {
  location: true,
  LPA: false,
  NLIS: false,
};

const useStyle = makeStyles(() => ({
  imageStyle: {
    width: '100%',
    height: '100%',
    maxWidth: 312,
    maxHeight: 212,
  },
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
    marginTop: 40,
  },
}));

const EditBusinessProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const businessProfileDoc = useAppSelector(
    (state) =>
      state.user.businessProfileData as TokenService.BusinessToken['details'],
  );
  const userProfile = useAppSelector((state) => state.user.userProfileData);

  const [editBusiness, setEditBusiness] = useState<
    TokenService.BusinessToken['details']
  >(JSON.parse(JSON.stringify(businessProfileDoc)));
  const businessProfile = useMemo(
    () =>
      businessProfileObject(
        editBusiness as
          | BusinessMisc.AnimalBusinessInterface
          | BusinessMisc.PlantBusinessInterface,
      ),
    [editBusiness],
  );
  const [useOptiweigh, setUseOptiweigh] = useState(
    businessProfile.isOptiweigh(),
  );
  // multiple location
  const [collapse, setCollapse] = useState(
    Array.from({length: businessProfile.numLocations()}, (_) => ({
      ...INITIAL_COLAPSE_STATE,
    })),
  );
  const [validationState, setValidationState] = useState({
    ...businessProfile.initialValidationState(),
  });
  const [deleteLocation, setDeleteLocation] = useState(false);
  const [deletePermit, setDeletePermits] = useState(false);
  const [permitsCollapse, SetPermitsCollapse] = useState(
    Array(
      editBusiness.industryType === 'PLANTS' ? editBusiness.permits?.length : 0,
    ).fill(true),
  );
  const [businessLanguage, setBusinessLanguage] = useState(CONSTANT.EN_LABELS);
  const defaultLocationCountry =
    editBusiness.industryType === 'PLANTS'
      ? 'Australia'
      : editBusiness.businessCountry;

  const isLivestock = businessProfile.isLivestock();
  /**Handle Onchange TextField*/
  const handleEditState =
    (editName: string, subKey?: string) =>
    (e: React.ChangeEvent<{name?: string; value: any}>) => {
      e.persist();

      setEditBusiness((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));
        if (editName === 'licenseExpiryDate') {
          newState[editName] = new Date(e.target.value);
        } else if (subKey) {
          if (!newState[subKey]) {
            newState[subKey] = {};
          }
          newState[subKey][editName] = e.target.value;
        } else {
          newState[editName] = e.target.value;
        }
        return newState;
      });
    };

  const handleDate = (date: any, fieldName: string) => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[fieldName] = new Date(date);
      return newState;
    });
  };

  const handleEditLocation =
    (editName: string, index: number) =>
    (e: React.ChangeEvent<{name?: string; value: any}>) => {
      e.persist();

      if (editName === 'country') {
        setEditBusiness((prevState) => {
          let newState = JSON.parse(JSON.stringify(prevState));
          newState.location[index][editName] = e.target.value;
          newState.location[index]['state'] = '';
          return newState;
        });
      } else {
        setEditBusiness((prevState) => {
          let newState = JSON.parse(JSON.stringify(prevState));
          newState.location[index][editName] = e.target.value;
          return newState;
        });
      }
    };

  const handlePermits =
    (editName: string, index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      console.log('index', index);
      setEditBusiness((prevState) => {
        let newState = JSON.parse(JSON.stringify(prevState));
        if (editName === 'permitExpiryDate')
          newState.permits[index][editName] = new Date(e.target.value);
        else newState.permits[index][editName] = e.target.value;
        return newState;
      });
    };

  const handlePermitDate = (date: any, editName: string, index: number) => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.permits[index][editName] = new Date(date);
      return newState;
    });
  };

  // multiple location
  const handleCollapse =
    (collapseName: 'location' | 'NLIS' | 'LPA', index: number) => () => {
      let updateState = [...collapse];
      updateState[index][collapseName] = !updateState[index][collapseName];
      setCollapse(updateState);
    };

  // Add new Location
  const handleAddLocation = () => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.location.push({
        ...businessProfile.locationTemplate(),
        country: defaultLocationCountry,
      });
      return newState;
    });

    setCollapse((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.push(INITIAL_COLAPSE_STATE);
      return newState;
    });

    setValidationState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.location.push({...businessProfile.locationTemplate()});
      return newState;
    });
  };

  const handleEditInterval = (
    e: React.ChangeEvent<{name?: string; value: any}>,
  ) => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      if (!newState['optiweigh']) {
        newState['optiweigh'] = {};
      }
      newState['optiweigh']['interval'] = e.target.value;
      return newState;
    });
  };

  //Add Permits
  const handleAddPermit = () => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.permits.push({...PERMITS_TEMPLATE});
      return newState;
    });

    SetPermitsCollapse((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.push(true);
      return newState;
    });

    setValidationState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.permits.push({...PERMITS_TEMPLATE});
      return newState;
    });
  };

  // Remove Location
  const handleRemoveLocation = (index: number) => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.location.splice(index, 1);
      return newState;
    });

    setCollapse((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(index, 1);
      return newState;
    });

    setValidationState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.location.splice(index, 1);
      return newState;
    });

    setDeleteLocation(true);
  };
  //Remove Permits
  const handleRemovePermit = (index: number) => {
    setEditBusiness((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.permits.splice(index, 1);
      return newState;
    });

    SetPermitsCollapse((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.splice(index, 1);
      return newState;
    });

    setValidationState((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState.permits.splice(index, 1);
      return newState;
    });
    setDeletePermits(true);
  };

  const handlePermitCollapse = (index: number) => () => {
    let tempCollapse = [...permitsCollapse];
    tempCollapse[index] = !tempCollapse[index];
    SetPermitsCollapse(tempCollapse);
  };

  useEffect(() => {
    dispatch(getBusinessProfile());
  }, []);

  useEffect(() => {
    let businessProfileCopy = JSON.parse(JSON.stringify(businessProfileDoc));
    businessProfileCopy.location = businessProfileCopy.location?.map((loc) => {
      return {...loc, country: loc.country ?? 'Australia'};
    });
    setEditBusiness(businessProfileCopy);
  }, [businessProfileDoc]);

  const handleSaveModifiedData = async () => {
    try {
      if (
        businessProfile.isLivestock() &&
        !editBusiness.hasOwnProperty('businessCountry')
      ) {
        editBusiness['businessCountry'] = 'Australia';
      }
      const validatedData = validateBusinessData(
        editBusiness,
        {...businessProfile.initialValidationState()},
        useOptiweigh,
      );
      setValidationState(validatedData);
      if (isAllowedtoSubmit(validatedData)) {
        const location = editBusiness?.location;

        if (location) {
          await validateCredential(location, dispatch);
        }
        let updateOptiweigh:
          | undefined
          | BusinessMisc.AnimalBusinessInterface['optiweigh'] = undefined;
        if (
          businessProfile.checkOptiweighModified(
            businessProfileDoc as BusinessMisc.AnimalBusinessInterface,
            useOptiweigh,
          ) &&
          editBusiness.industryType === 'ANIMALS'
        ) {
          updateOptiweigh = {
            ...editBusiness.optiweigh,
            interval: editBusiness.optiweigh?.interval.replace(/\D+/, '') ?? '',
          };
        }
        dispatch(
          updateBusinessProfile(
            businessProfile.isLivestock(),
            editBusiness,
            deleteLocation,
            businessProfileDoc,
            deletePermit,
            {
              ...updateOptiweigh,
              delete: businessProfile.isOptiweigh() && !useOptiweigh,
            },
          ),
        );
      }
    } catch (error) {
      if (Array.isArray(error)) {
        const subtitleString: string[] = [];
        error.map((error) => {
          const {details, index} = error;

          if (details) {
            const errorString = `Location ${index + 1}: ${details.join(',')}`;
            subtitleString.push(errorString);
          } else {
            const errorString = `Location ${index + 1}`;
            subtitleString.push(errorString);
          }
        });
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Location Validation Failed',
            subtitle: subtitleString.join(' \n'),
          }),
        );
      } else {
        console.error(error);
        dispatch(
          toggleModal({
            status: 'failed',
            title: 'Location Validation Failed',
            subtitle: 'Something went wrong!',
          }),
        );
      }
    }
  };

  const classes = useStyle();

  // reset validationState and warnings onKeyDown
  useEffect(() => {
    const resetValidationState = () =>
      setValidationState((prevState) => clearWarning(prevState));
    window.addEventListener('keydown', resetValidationState);

    return () => {
      window.removeEventListener('keydown', resetValidationState);
    };
  }, []);

  useEffect(() => {
    switch (editBusiness.businessCountry) {
      case 'Argentina':
        setBusinessLanguage((pre) => ({...pre, ...CONSTANT.ES_LABELS}));
        break;
      case 'Canada':
      case 'Australia':
      default:
        setBusinessLanguage((pre) => ({...pre, ...CONSTANT.EN_LABELS}));
        break;
    }
  }, [editBusiness.businessCountry]);

  return (
    <PageHeader
      config={{
        title: businessLanguage.editBusinessProfile,
        margin: 60,
        back: true,
      }}>
      <Grid container spacing={3}>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {businessLanguage.companyName}
          </Typography>
          <TextField
            variant="outlined"
            value={editBusiness.companyName}
            onChange={handleEditState('companyName')}
            error={!!validationState.companyName}
            helperText={validationState.companyName}
          />
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {editBusiness.businessCountry === 'Canada'
              ? 'Business Number'
              : businessLanguage.companyNumber}
          </Typography>
          {businessProfile.isArgentina() ? (
            <InputMask
              mask={'99-99999999/9'}
              maskChar={null}
              value={editBusiness.companyNumber}
              onChange={handleEditState('companyNumber')}
              placeholder={'99-99999999/9'}>
              {(props) => (
                <TextField
                  {...props}
                  fullWidth
                  variant="outlined"
                  helperText={validationState.companyNumber}
                  error={!!validationState.companyNumber}
                />
              )}
            </InputMask>
          ) : (
            <TextField
              variant="outlined"
              value={editBusiness.companyNumber}
              onChange={handleEditState('companyNumber')}
              error={!!validationState.companyNumber}
              helperText={validationState.companyNumber}
            />
          )}
        </Grid>
        {/* hide the country field from everyone except Canada */}
        {isLivestock && (
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessLanguage.country}
            </Typography>
            <FormControl
              variant="outlined"
              error={!!validationState.businessCountry}>
              <InputLabel id={`business-select-country`}>
                {businessLanguage.pleaseSelect}
              </InputLabel>
              <Select
                value={editBusiness.businessCountry ?? 'Australia'}
                label="Please select"
                onChange={handleEditState('businessCountry')}>
                {COUNTRY_LIST.map((cty) => (
                  <MenuItem value={cty} key={cty}>
                    {cty}
                  </MenuItem>
                ))}
              </Select>
              {!!validationState.businessCountry && (
                <FormHelperText>
                  {validationState.businessCountry}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        {businessProfile.isArgentina() && (
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessLanguage.CUIG}
            </Typography>
            <TextField
              variant="outlined"
              value={editBusiness.cuig}
              onChange={handleEditState('cuig')}
              error={!!validationState.cuig}
              helperText={validationState.cuig}
              placeholder="AB000"
            />
          </Grid>
        )}

        {editBusiness.industryType === 'PLANTS' && (
          <Grid container spacing={3} style={{marginTop: 64}}>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" style={{fontWeight: 600}}>
                {businessLanguage.licenseNumber}
              </Typography>
              <TextField
                variant="outlined"
                value={editBusiness.licenseNumber}
                onChange={handleEditState('licenseNumber')}
                error={!!validationState.licenseNumber}
                helperText={validationState.licenseNumber}
              />
            </Grid>
            <Grid item container xs={6} direction="column">
              <Typography variant="h6" style={{fontWeight: 600}}>
                {businessLanguage.licenseExpiryDate}
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  style={
                    !!validationState.licenseExpiryDate
                      ? {
                          margin: 0,
                          border: 'solid 1px red',
                          padding: '11px 14px',
                          borderRadius: '5px',
                        }
                      : {
                          margin: 0,
                          border: 'solid 1px lightgray',
                          padding: '11px 14px',
                          borderRadius: '5px',
                        }
                  }
                  emptyLabel={'dd/mm/yyyy'}
                  value={moment(editBusiness.licenseExpiryDate) || null}
                  onChange={(date) => {
                    handleDate(date, 'licenseExpiryDate');
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  keyboardIcon={<DateRangeIcon style={{fill: '#373935'}} />}
                  disablePast
                />
                {!!validationState.licenseExpiryDate && (
                  <FormHelperText style={{color: 'red', margin: '3px 14px 0'}}>
                    {validationState.licenseExpiryDate}
                  </FormHelperText>
                )}
              </MuiPickersUtilsProvider>
            </Grid>

            {/* <PermitForm /> */}
            <Grid container item>
              <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
                {businessLanguage.permits}
              </Typography>
            </Grid>
            {!!editBusiness?.permits ? (
              editBusiness?.permits?.map((permit, index: number) => (
                <Grid item xs={12} key={'permit' + index}>
                  <div className={classes.titleContainer}>
                    <ListItem
                      style={{paddingLeft: 0}}
                      button
                      onClick={handlePermitCollapse(index)}>
                      <Grid container justify="space-between">
                        <Typography variant="h3" role="label">
                          {`${businessLanguage.permit} ${index + 1}`}
                        </Typography>
                        {permitsCollapse[index] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </Grid>
                    </ListItem>
                  </div>
                  <Collapse in={permitsCollapse[index]}>
                    <Grid container spacing={3}>
                      <Grid item container xs={6} direction="column">
                        <Typography
                          variant="h6"
                          style={{fontWeight: 600, marginTop: 20}}>
                          {businessLanguage.permitNumber}
                        </Typography>
                        <TextField
                          variant="outlined"
                          value={permit.permitNumber}
                          onChange={handlePermits('permitNumber', index)}
                          error={!!validationState.permits[index].permitNumber}
                          helperText={
                            validationState.permits[index].permitNumber
                          }
                        />
                      </Grid>
                      <Grid item container xs={6} direction="column">
                        <Typography
                          variant="h6"
                          style={{fontWeight: 600, marginTop: 20}}>
                          {businessLanguage.permitExpiryDate}
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            style={
                              !!validationState.permits[index].permitExpiryDate
                                ? {
                                    margin: 0,
                                    border: 'solid 1px red',
                                    padding: '11px 14px',
                                    borderRadius: '5px',
                                  }
                                : {
                                    margin: 0,
                                    border: 'solid 1px lightgray',
                                    padding: '11px 14px',
                                    borderRadius: '5px',
                                  }
                            }
                            emptyLabel={'dd/mm/yyyy'}
                            value={
                              permit.permitExpiryDate
                                ? moment(permit.permitExpiryDate)
                                : null
                            }
                            onChange={(date) => {
                              handlePermitDate(date, 'permitExpiryDate', index);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            keyboardIcon={
                              <DateRangeIcon style={{fill: '#373935'}} />
                            }
                            disablePast
                          />
                          {!!validationState.permits[index]
                            .permitExpiryDate && (
                            <FormHelperText
                              style={{color: 'red', margin: '3px 14px 0'}}>
                              {validationState.permits[index].permitExpiryDate}
                            </FormHelperText>
                          )}
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              ))
            ) : (
              <></>
            )}
            <Grid container item style={{marginTop: 64}}>
              <MyButton
                text="Add Permit"
                variant="outlined"
                onClick={() => handleAddPermit()}
              />
            </Grid>
          </Grid>
        )}
        <Grid container item>
          <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
            {businessLanguage.myLocation}{' '}
            {
              businessProfile.isLivestock() &&
                !businessProfile.isArgentina() && <>e.g. PIC/PID</>
              // businessProfile.isAustralia() ? (
              //   <>e.g. PIC</>
              // ) : (
              //   <>e.g. PID</>
              // )
            }
          </Typography>
        </Grid>
        {!!editBusiness?.location.length &&
          editBusiness?.location.map((location, index) => (
            <Grid item xs={12} key={'location' + index}>
              <div className={classes.titleContainer}>
                <ListItem
                  style={{paddingLeft: 0}}
                  button
                  onClick={handleCollapse('location', index)}>
                  <Grid container justify="space-between">
                    <Typography variant="h3" role="label">
                      {location.locationNickname}
                    </Typography>
                    {collapse[index]?.location ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </Grid>
                </ListItem>
              </div>
              <Collapse in={collapse[index]?.location}>
                <Grid container direction="column">
                  <Grid container item xs={12} justify="flex-end">
                    <IconButton
                      onClick={() =>
                        dispatch(
                          toggleModal({
                            status: 'warning',
                            title: `${businessLanguage.delete}?`,
                            subtitle: businessLanguage.cannptUndone,
                            renderButton: (
                              <Buttons
                                leftButtonTitle={businessLanguage.cancel}
                                rightButtonTitle={businessLanguage.delete}
                                leftButtonOnClick={() => {
                                  dispatch(toggleModalOff());
                                }}
                                rightButtonOnClick={() => {
                                  dispatch(toggleModalOff());
                                  handleRemoveLocation(index);
                                }}
                              />
                            ),
                          }),
                        )
                      }>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  {/* 1 */}
                  <Grid container style={{marginTop: 24}} spacing={3}>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessLanguage.locationNickname}
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={location.locationNickname}
                        onChange={handleEditLocation('locationNickname', index)}
                        error={
                          !!validationState.location[index].locationNickname
                        }
                        helperText={
                          validationState.location[index].locationNickname
                        }
                      />
                    </Grid>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {location.country === 'Argentina'
                          ? 'RENSPA'
                          : 'Location ID'}{' '}
                        {businessProfile.isLivestock() &&
                          location.country !== 'Argentina' && <>e.g. PIC/PID</>}
                      </Typography>
                      {location.country === 'Argentina' ? (
                        <InputMask
                          mask={'99.999.9.99999/99'}
                          maskChar={null}
                          value={
                            businessProfile.isLivestock()
                              ? location.PICAddress
                              : location.locationId
                          }
                          onChange={handleEditLocation(
                            businessProfile.isLivestock()
                              ? 'PICAddress'
                              : 'locationId',
                            index,
                          )}
                          placeholder={'99.999.9.99999/99'}>
                          {(props) => (
                            <TextField
                              {...props}
                              fullWidth
                              variant="outlined"
                              error={
                                businessProfile.isLivestock()
                                  ? !!validationState.location[index].PICAddress
                                  : !!validationState.location[index].locationId
                              }
                              helperText={
                                businessProfile.isLivestock()
                                  ? validationState.location[index].PICAddress
                                  : validationState.location[index].locationId
                              }
                            />
                          )}
                        </InputMask>
                      ) : (
                        <TextField
                          variant="outlined"
                          value={
                            businessProfile.isLivestock()
                              ? location.PICAddress
                              : location.locationId
                          }
                          onChange={handleEditLocation(
                            businessProfile.isLivestock()
                              ? 'PICAddress'
                              : 'locationId',
                            index,
                          )}
                          error={
                            businessProfile.isLivestock()
                              ? !!validationState.location[index].PICAddress
                              : !!validationState.location[index].locationId
                          }
                          helperText={
                            businessProfile.isLivestock()
                              ? validationState.location[index].PICAddress
                              : validationState.location[index].locationId
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* 2 */}
                  <Grid container style={{marginTop: 32}} spacing={3}>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessLanguage.country}
                      </Typography>
                      <FormControl
                        variant="outlined"
                        error={!!validationState.location[index].country}>
                        <InputLabel id={`business-select-location-${index}`}>
                          {businessLanguage.pleaseSelect}
                        </InputLabel>
                        <Select
                          value={location.country || defaultLocationCountry}
                          labelId={`business-select-location-${index}`}
                          label={businessLanguage.pleaseSelect}
                          onChange={handleEditLocation('country', index)}>
                          {COUNTRY_LIST.map((cty) => (
                            <MenuItem value={cty} key={cty}>
                              {cty}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!validationState.location[index].country && (
                          <FormHelperText>
                            {validationState.location[index].country}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessLanguage.state}
                      </Typography>
                      <FormControl
                        variant="outlined"
                        error={!!validationState.location[index].state}>
                        <InputLabel id={`business-select-state-${index}`}>
                          {businessLanguage.pleaseSelect}
                        </InputLabel>
                        <Select
                          labelId={`business-select-state-${index}`}
                          label={businessLanguage.pleaseSelect}
                          value={location.state}
                          onChange={handleEditLocation('state', index)}>
                          {STATE_OPTIONS[location.country ?? 'Australia']?.map(
                            (state) => (
                              <MenuItem value={state} key={state}>
                                {state}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        {!!validationState.location[index].state && (
                          <FormHelperText>
                            {validationState.location[index].state}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* 3 */}
                  <Grid container style={{marginTop: 32}} spacing={3}>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessLanguage.suburb}
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={location.town}
                        onChange={handleEditLocation('town', index)}
                        error={!!validationState.location[index].town}
                        helperText={validationState.location[index].town}
                      />
                    </Grid>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessLanguage.postcode}
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={location.postcode}
                        onChange={handleEditLocation('postcode', index)}
                        error={!!validationState.location[index].postcode}
                        helperText={validationState.location[index].postcode}
                        placeholder={
                          location.country === 'Argentina' ? 'P1650' : ''
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* 4 */}
                  <Grid container style={{marginTop: 32}} spacing={3}>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessLanguage.address}
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={location.address}
                        onChange={handleEditLocation('address', index)}
                        multiline={true}
                        error={!!validationState.location[index].address}
                        helperText={validationState.location[index].address}
                        rows={3}
                      />
                    </Grid>
                  </Grid>
                  {businessProfile.isLivestock() &&
                    businessProfile.isAustralia() && (
                      <>
                        {/* 5 */}
                        <Grid container style={{marginTop: 32}} spacing={3}>
                          <Grid item container xs={6} direction="column">
                            <Typography variant="h6" style={{fontWeight: 600}}>
                              {businessLanguage.lpaUser}
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={location.LPAUserID}
                              onChange={handleEditLocation('LPAUserID', index)}
                              error={
                                !!validationState.location[index].LPAUserID
                              }
                              helperText={
                                validationState.location[index].LPAUserID
                              }
                            />
                          </Grid>
                          <Grid item container xs={6} direction="column">
                            <Typography variant="h6" style={{fontWeight: 600}}>
                              {businessLanguage.lpaPassword}
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={location.LPAPassword}
                              onChange={handleEditLocation(
                                'LPAPassword',
                                index,
                              )}
                              error={
                                !!validationState.location[index].LPAPassword
                              }
                              helperText={
                                validationState.location[index].LPAPassword
                              }
                            />
                          </Grid>
                        </Grid>
                        {/* 6 */}
                        <Grid container style={{marginTop: 32}} spacing={3}>
                          <Grid item container xs={6} direction="column">
                            <Typography variant="h6" style={{fontWeight: 600}}>
                              {businessLanguage.nlisUser}
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={location.NLISUserID}
                              onChange={handleEditLocation('NLISUserID', index)}
                              error={
                                !!validationState.location[index].NLISUserID
                              }
                              helperText={
                                validationState.location[index].NLISUserID
                              }
                            />
                          </Grid>
                          <Grid item container xs={6} direction="column">
                            <Typography variant="h6" style={{fontWeight: 600}}>
                              {businessLanguage.nlisPassword}
                            </Typography>
                            <TextField
                              variant="outlined"
                              value={location.NLISPassword}
                              onChange={handleEditLocation(
                                'NLISPassword',
                                index,
                              )}
                              error={
                                !!validationState.location[index].NLISPassword
                              }
                              helperText={
                                validationState.location[index].NLISPassword
                              }
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                </Grid>
              </Collapse>
            </Grid>
          ))}
        <Grid container item style={{marginTop: 64}}>
          <MyButton
            text={businessLanguage.addLocation}
            variant="outlined"
            onClick={() => handleAddLocation()}
          />
        </Grid>
        {businessProfile.isLivestock() && (
          <>
            <Grid container item>
              <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
                {businessLanguage.CattleIdentification}
              </Typography>
            </Grid>
            <Grid container style={{marginTop: 48}} spacing={3}>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessLanguage.brandDescription}
                </Typography>
                <TextField
                  variant="outlined"
                  value={editBusiness.brandDescription}
                  onChange={handleEditState('brandDescription')}
                  error={!!validationState.brandDescription}
                  helperText={validationState.brandDescription}
                />
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessLanguage.cattleBrandSymbol}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  <img
                    style={{maxWidth: 223, maxHeight: 110}}
                    src={businessProfileDoc.imageUri}
                    alt=""
                  />
                </Typography>
              </Grid>
            </Grid>
            {!businessProfile.isArgentina() && (
              <Box mt={5}>
                <Grid container spacing={3}>
                  <Grid container item>
                    <Typography
                      variant="h2"
                      role="label"
                      style={{marginTop: 80}}>
                      Optiweigh Settings
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} style={{marginTop: 30}}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={useOptiweigh}
                          onChange={() => {
                            setUseOptiweigh(!useOptiweigh);
                          }}
                          name="useOptiweigh"
                          color="primary"
                        />
                      }
                      label="Use Optiweigh"
                    />
                  </Grid>
                </Grid>
                {useOptiweigh && editBusiness.industryType === 'ANIMALS' && (
                  <Grid container spacing={3}>
                    <Grid item container xs={4} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Optiweigh Client ID
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={editBusiness.optiweigh?.clientId ?? ''}
                        onChange={handleEditState('clientId', 'optiweigh')}
                        error={!!validationState.optiweigh?.clientId}
                        helperText={validationState.optiweigh?.clientId}
                      />
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Optiweigh API Key
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={editBusiness.optiweigh?.apiKey ?? ''}
                        onChange={handleEditState('apiKey', 'optiweigh')}
                        error={!!validationState.optiweigh?.apiKey}
                        helperText={validationState.optiweigh?.apiKey}
                      />
                    </Grid>
                    <Grid item container xs={4} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Update every
                      </Typography>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        error={!!validationState.optiweigh?.interval}>
                        <Select
                          value={editBusiness.optiweigh?.interval ?? ''}
                          onChange={handleEditInterval}>
                          {INTERVAL_OPTIONS.map((ivl) => (
                            <MenuItem value={ivl} key={ivl}>
                              {ivl}
                            </MenuItem>
                          ))}
                        </Select>
                        {validationState.optiweigh?.interval && (
                          <FormHelperText>
                            This field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Aglive API Key
                      </Typography>
                      <TextField
                        variant="outlined"
                        value={editBusiness.optiweigh?.agliveKey ?? ''}
                        onChange={handleEditState('agliveKey', 'optiweigh')}
                        error={!!validationState.optiweigh?.agliveKey}
                        helperText={validationState.optiweigh?.agliveKey}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </>
        )}
        <Grid item container justify="flex-end" style={{marginTop: 200}}>
          <MyButton
            text={businessLanguage.save}
            variant="contained"
            onClick={handleSaveModifiedData}
          />
        </Grid>
      </Grid>
    </PageHeader>
  );
};

function validateBusinessData(
  business: TokenService.BusinessToken['details'],
  validationState: initialStateType,
  useOptiweigh?: boolean,
) {
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const isLivestock = business.industryType === 'ANIMALS';
  const isArgentina = business.businessCountry === 'Argentina';
  const requiredField =
    business.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS.requiredField
      : CONSTANT.EN_LABELS.requiredField;
  const displayLanguage =
    business.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;
  // Validate company name
  if (business.companyName.trim() === '')
    validationState.companyName = requiredField;
  // else if (!/^[a-zA-Z0-9 ]+$/.test(business.companyName))
  //   validationState.companyName = 'Must contains letters or digits only';
  // Validate company number
  if (String(business.companyNumber).trim() === '') {
    validationState.companyNumber = requiredField;
  } else if (
    !isArgentina &&
    !CONSTANT.COMPANY_NUMBER_REGEX['Australia'].test(
      String(business.companyNumber),
    )
  ) {
    validationState.companyNumber = 'Must be only digits';
  } else if (
    isArgentina &&
    !CONSTANT.COMPANY_NUMBER_REGEX['Argentina'].test(
      String(business.companyNumber),
    )
  ) {
    validationState.companyNumber = displayLanguage.validCUIT;
  }
  /**Validate CUIG */
  if (isArgentina && String(business.cuig).trim() === '') {
    validationState.cuig = requiredField;
  } else if (isArgentina && !CONSTANT.CUIG_REGEX.test(String(business.cuig))) {
    validationState.cuig = displayLanguage.validCUIG;
  }
  // Validate business country

  if (isLivestock) {
    // Validation for livestock fields
    // Validate brand description
    if (!isArgentina && business.brandDescription?.trim() === '') {
      validationState.brandDescription = requiredField;
    }
    if (useOptiweigh && business.industryType === 'ANIMALS') {
      ['clientId', 'apiKey', 'agliveKey', 'interval'].forEach((key) => {
        validationState.optiweigh[key as keyof initialStateType['optiweigh']] =
          !business.optiweigh?.[key as keyof initialStateType['optiweigh']] ||
          business.optiweigh?.[
            key as keyof initialStateType['optiweigh']
          ].trim() === ''
            ? requiredField
            : '';
      });
    }
    if (business.businessCountry.trim() === '') {
      validationState.businessCountry = requiredField;
    }
  } else if (
    business.industryType === 'PLANTS' &&
    business.licenseNumber &&
    business.permits
  ) {
    // Validation for non-livestock fields
    // Validate license number
    if (business.licenseNumber.trim() === '')
      validationState.licenseNumber = requiredField;
    else if (!/^[a-zA-Z0-9 ]+$/.test(business.licenseNumber))
      validationState.licenseNumber = 'Must contains letters or digits only';
    // Validate license expiry date
    if (!business.licenseExpiryDate)
      validationState.licenseExpiryDate = requiredField;
    else if (
      yesterday.valueOf() - new Date(business.licenseExpiryDate).valueOf() >
      0
    ) {
      validationState.licenseExpiryDate = 'Invalid date';
    }

    //Validate permits
    if (business.permits.length > 0) {
      business.permits.forEach((permit, index) => {
        if (permit.permitNumber.trim() === '')
          validationState.permits[index].permitNumber = requiredField;
        if (!permit.permitExpiryDate)
          validationState.permits[index].permitExpiryDate = requiredField;
        else if (
          yesterday.valueOf() - new Date(permit.permitExpiryDate).valueOf() >
          0
        )
          validationState.permits[index].permitExpiryDate = 'Invalid date';
      });
    }
  }
  // Validate location
  if (business.location.length > 0) {
    business.location.forEach((location, index) => {
      // Validate location nickname
      if (!isArgentina) {
        if (location.locationNickname.trim() === '')
          validationState.location[index].locationNickname = requiredField;
      }

      // Validate country
      if (location.country?.trim() === '' || !location.country)
        validationState.location[index].country = requiredField;

      if (isLivestock) {
        // Validation for livestock location fields
        // Validate state
        if (location.state.trim() === '')
          validationState.location[index].state = requiredField;
        // Validate location postcode
        if (!location.postcode.trim()) {
          if (!isArgentina) {
            validationState.location[index].postcode = requiredField;
          }
        } else if (
          !CONSTANT.POSTCODE_REGEX[location.country as string]?.test(
            location.postcode,
          )
        ) {
          validationState.location[index].postcode =
            CONSTANT.POSTCODE_REGEX_WARNING[location.country as string];
        }
        // Validate location town
        if (location.town.trim() === '')
          validationState.location[index].town = requiredField;

        // Validate location town
        if (location.address.trim() === '')
          validationState.location[index].address = requiredField;

        // Validate picaddress
        if (!isArgentina && location.PICAddress?.trim() === '') {
          validationState.location[index].PICAddress = requiredField;
        } else if (
          location.country === 'Argentina' &&
          !CONSTANT.RENSPA_REGEX.test(location.PICAddress)
        ) {
          validationState.location[index].PICAddress =
            displayLanguage.validRenspa;
        } else if (location.PICAddress && index != business.location.findIndex(o => o.PICAddress == location.PICAddress?.trim())) {
            validationState.location[index].PICAddress = displayLanguage.duplicatePIC
        }
      } else {
        // Validation for non-livestock location fields
        // Validate location id
        if (
          location.locationId &&
          location.locationId.trim() !== '' &&
          !/^[a-zA-Z0-9 ]+$/.test(location.locationId)
        ) {
          validationState.location[index].locationId =
            'Must contains letters or digits only';
        }
        if (location.locationId && index != business.location.findIndex(o => o.locationId == location.locationId?.trim())) {
          validationState.location[index].locationId = "This location ID already exists";
        }
        // Validate picaddress
        // if (location.PICAddress.trim() === '')
        //   validationState.location[index].PICAddress = 'This field is required';

        // Validate location postcode
        if (
          location.postcode.trim() !== '' &&
          !/^[0-9]+$/.test(location.postcode)
        )
          validationState.location[index].postcode = 'Must be only digits';
      }
    });
  }
  return validationState;
}

// if any of the entries is true we know that an error is triggered
function isAllowedtoSubmit(business: initialStateType) {
  return !Object.entries(business).some(([key, value]) => {
    if (key === 'location') {
      return business.location.some((location) =>
        Object.values(location).some((value) => value),
      );
    } else if (key === 'permits') {
      return business.permits.some((permit) =>
        Object.values(permit).some((value) => value),
      );
    } else if (typeof value === 'object') {
      return Object.entries(business.optiweigh).some(([key, value]) => value);
    } else {
      return value;
    }
  });
}

function clearWarning(obj: {[key: string]: any}): initialStateType {
  let newObj = {};
  for (const key in obj) {
    if (typeof obj[key as string] === 'string') {
      newObj[key] = '';
    } else if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map((item: {[key: string]: any}) =>
        clearWarning(item),
      );
    }
  }

  return newObj as initialStateType;
}
export default EditBusinessProfile;
